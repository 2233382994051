import React, { Component } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import styled from '@emotion/styled';
import { WhatHappensNextContainer, TextContent } from './style';
import TitleUnderlined from '../../components/TitleUnderlined';
import WhnBg from './whnBg';
import Card from '../../components/Card';
import convertHtml from '../../utils/convertHtml';

const containerCss = {
  display: 'flex',
  fontFamily: "'Montserrat', sans-serif",
  maxWidth: '1600px',
  boxSizing: 'border-box',
  justifyContent: 'center',
  flexWrap: 'wrap',
  width: '100%',
};

const titleUnderlined1Css = {
  fontSize: '30px',
  tabletFontSize: '26px',
  tableLineHeight: '28px',
  mobileFontSize: '18px',
  mobileLineHeight: '22px',
  lineHeight: '40px',
  margin: '0 auto',
};

const titleUnderlined2Css = {
  underlineColor: '#FF22EE',
  fontSize: '30px',
  tabletFontSize: '28px',
  tableLineHeight: '28px',
  mobileFontSize: '25px',
  mobileLineHeight: '25px',
  lineHeight: '40px',
  margin: '0 auto',
};

const cardCss = {
  display: 'flex',
  flexDirection: 'column',
  background: '#FAFAFA',
  padding: '1.5rem',
  margin: '0 1.5rem',
  textAlign: 'center',
  minHeight: '300px',
  width: '300px',
  marginBottom: '1.5rem',
};

const tabletCardCss = {
  flex: '0 0 100%',
  maxWidth: '100%',
  position: 'relative',
  width: '100%',
  minHeight: '1px',
  boxSizing: 'border-box',
  border: '5px solid #fff',
  margin: '0px',
};

const mobileCardCss = {
  flex: '0 0 100%',
  maxWidth: '100%',
};

const contentCss = {
  fontWeight: '500',
  fontSize: '14px',
  lineHeight: '24px',
  color: '#ffffff',
  marginTop: '1rem',
};

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export class WhatHappensNext extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };
  render() {
    const { items, title_text, subtitle } = this.props;
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <WhatHappensNextContainer>
          <TitleContainer>
            <TitleUnderlined
              titleText={'<span>' + this.props.title.text + '</span>'}
              underlineColor="#FF22EE"
              fontSize="58px"
              x
              tabletFontSize="46px"
              tableLineHeight="46px"
              mobileFontSize="36px"
              mobileLineHeight="36px"
              lineHeight="40px"
              h2Mode={true}
            />
          </TitleContainer>
          <TextContent
            dangerouslySetInnerHTML={convertHtml(this.props.subtitle.text)}
          />
          <Card
            data={items}
            containerCss={containerCss}
            inlineIndex={false}
            titleUnderlined1Css={titleUnderlined1Css}
            titleUnderlined2Css={titleUnderlined2Css}
            cardCss={cardCss}
            contentCss={contentCss}
            tabletCardCss={tabletCardCss}
            mobileCardCss={mobileCardCss}
            displayHappen
          />
          {/* <div>Title: {this.props.title.text}</div>
          <div>Subtitle: {this.props.subtitle.text}</div>
          <div>
            {this.props.items.map(item => (
              <div>
                <div> Card Title: {item.card_title.text}</div>
                <div> Card Text: {item.card_text.text}</div>
              </div>
            ))}
          </div> */}
        </WhatHappensNextContainer>
      </VisibilitySensor>
    );
  }
}

export default WhatHappensNext;

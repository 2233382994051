import React, { Component } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';

//Import Components
import Label from '../components/Label';
import BlankSpace from '../components/blankSpace';
import ButtonUnderlined from '../components/ButtonUnderlined';
import LazyImage from '../components/lazy-image';
import convertHtml from '../utils/convertHtml';

const TextContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  overflow: hidden;
`;

const TextContainerInner = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1440px;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 30px;
  font-family: 'Montserrat', sans-serif;
  @media (max-width: 735px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
`;

const LeftAligned = styled(motion.div)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  @media (max-width: 735px) {
    width: 100%;
  }
`;

const ParagraphTextContainer = styled.div`
  @media (max-width: 735px) {
    margin-left: 5%;
    margin-right: 5%;
  }
`;

const RightAligned = styled(motion.div)`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 735px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 50px;
    display: flex;
    justify-content: center;
  }
`;

const ParagraphTitle = styled.h2`
  font-weight: 800;
  font-size: 40px;
  color: #ffffff;
  max-width: 500px;
  font-weight: 800;
  font-style: normal;
  font-family: Montserrat;
  @media (max-width: 800px) {
    font-size: 26px;
    line-height: 30px;
  }
`;

const ParagraphText = styled.div`
  color: #ffffff;
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  letter-spacing: 0.01em;
  max-width: 550px;
  font-family: Montserrat;

  ul {
    list-style: outside;
    padding-left: 20px;
    list-style-type: square;
  }

  strong {
    font-weight: bold;
  }

  @media (max-width: 800px) {
    font-size: 18px;
    line-height: 36px;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-right: 90px;
  width: 430px;
  height: 530px;
  @media (max-width: 735px) {
    margin-right: 0px;
    height: 300px;
  }
  @media (max-width: 455px) {
    width: 330px;
    height: 300px;
  }
`;

const ImageContainerInner = styled.div`
  width: 375px;
  height: 470px;
  @media (max-width: 960px) {
    width: 300px;
    height: 300px;
  }
  @media (max-width: 565px) {
    width: 100%;
    height: 300px;
  }
`;

const rightAnim = {
  hidden: { opacity: 0, translateX: 50 },
  visible: {
    opacity: 1,
    translateX: 0,
  },
};

const leftAnim = {
  hidden: { opacity: 0, translateX: -50 },
  visible: {
    opacity: 1,
    translateX: 0,
    transition: {
      delay: 0.15,
    },
  },
};

export class rightImageLeftTextPrismic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={350}
      >
        <TextContainerOuter>
          <TextContainerInner>
            <TextContainer>
              <RightAligned
                variants={leftAnim}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                <ImageContainer>
                  <ImageContainerInner>
                    {this.props.left_aligned_image && (
                      <LazyImage {...this.props.left_aligned_image} />
                    )}
                  </ImageContainerInner>
                </ImageContainer>
              </RightAligned>
              <LeftAligned
                variants={rightAnim}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                <ParagraphTextContainer>
                  {this.props.label_text && (
                    <Label
                      text={this.props.label_text}
                      color={this.props.label_colour}
                    />
                  )}
                  <BlankSpace height="30px" />
                  <ParagraphTitle>{this.props.title_text}</ParagraphTitle>
                  <BlankSpace height="30px" />
                  <ParagraphText
                    dangerouslySetInnerHTML={convertHtml(
                      this.props.paragraph_text
                    )}
                  ></ParagraphText>
                  <BlankSpace height="30px" />
                </ParagraphTextContainer>
              </LeftAligned>
            </TextContainer>
          </TextContainerInner>
        </TextContainerOuter>
      </VisibilitySensor>
    );
  }
}

export default rightImageLeftTextPrismic;

import React from 'react';
import { graphql } from 'gatsby';

import FullWidthImageHeader from '../../blocks/fullWidthImageHeader';

function FullWidthImageHeaderPrismic({
  slice_type,
  title_text,
  subtitle_text,
  show_overlay,
  image,
  video,
}) {
  return (
    <>
      <FullWidthImageHeader
        title_text={title_text.text}
        subtitle_text={subtitle_text.text}
        show_overlay={show_overlay}
        image={image}
        video={video}
      />
    </>
  );
}

export const query = graphql`
  fragment FullWidthImageHeader on PrismicPageBodyFullWidthImageHeader {
    id
    slice_type
    primary {
      title_text {
        text
      }
      subtitle_text {
        text
      }
      show_overlay
      video {
        url
      }
      image {
        url
      }
    }
  }
`;

export default FullWidthImageHeader;

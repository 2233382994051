import React from 'react';
import { graphql } from 'gatsby';
import StandardText from '../../blocks/StandardText';

function StandardTextPrismic({ slice_type, title_text, text_content }) {
  return (
    <>
      <StandardText title_text={title_text.text} text_content={text_content} />
    </>
  );
}

export const query = graphql`
  fragment StandardText on PrismicPageBodyStandardText {
    id
    slice_type
    primary {
      title_text {
        text
      }
      text_content {
        html
      }
    }
  }
`;

export default StandardText;

import React, { Component } from 'react';
import axios from 'axios';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import VisibilitySensor from 'react-visibility-sensor';
import queryString from 'query-string';

import convertHtml from '../../utils/convertHtml';
import EmailSquiggle from '../../images/assets/emailSignupSquiggle.svg';
import ButtonNotUnderlined from '../../components/ButtonNotUnderlined';
import { NEWSLETTER_LIST_ID, NEWSLETTER_URL } from '../../utils/constants';

const BlogNewsletterBlockContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 50px;
`;

const BlogNewsletterBlockContainerInner = styled.div`
  background-color: #fafafa;
  width: 70%;
  max-width: 1440px;
  display: flex;
  align-items: center;
  @media (max-width: 960px) {
    width: 90%;
  }
  @media (max-width: 750px) {
    padding: 20px;
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 36px;
  line-height: 48px;
  display: flex;
  align-items: center;
  color: #ffffff;
  padding-right: 50px;
  @media (max-width: 800px) {
    padding-right: 10px;
    font-size: 28px;
    line-height: 34px;
  }
`;

const Subtitle = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-bottom: 30px;
  margin-top: 20px;
  padding-right: 50px;
  @media (max-width: 800px) {
    padding-right: 10px;
    font-size: 16px;
    line-height: 26px;
  }
`;

const EmailInput = styled.input`
  background-color: #fafafa;
  outline: none;
  border-style: solid;
  border-width: 0px;
  border-bottom-width: 1px;
  border-color: #ffffff;
  margin-right: 40px;
  width: 220px;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 5px;
  @media (max-width: 750px) {
    width: 60%;
  }
  @media (max-width: 450px) {
    width: 100%;
    margin-right: 0;
    margin-bottom: 40px;
  }
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: 450px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const Squiggle = styled.div`
  margin-right: 50px;
  @media (max-width: 750px) {
    display: none;
  }
`;

const Content = styled.div``;

const TextContent = styled.div``;

const Form = styled.form``;

const EmailContent = styled.div`
  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};
`;

const Message = styled.div`
  margin-top: 8px;
`;

export class BlogNewsletterBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
      inputValue: '',
      errorMsg: false,
      disabled: false,
      subscriptionSuccess: false,
    };
  }

  updateInputValue = evt => {
    ////console.log(evt);
    this.setState({
      inputValue: evt.target.value,
    });
  };

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  validateEmail = email => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  handleSubmit = e => {
    e.preventDefault();
    const { inputValue, errorMsg } = this.state;

    if (this.validateEmail(inputValue)) {
      this.setState({ errorMsg: false, disabled: true });
      axios({
        method: 'post',
        url: `https://cors-anywhere.herokuapp.com/${NEWSLETTER_URL}`,
        data: queryString.stringify({
          g: NEWSLETTER_LIST_ID,
          email: inputValue,
        }),
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
        .then(response => {
          ////console.log(response);
          this.setState({ subscriptionSuccess: true, disabled: true });
        })
        .catch(error => {
          console.error(error);
          this.setState({ errorMsg: true, disabled: false });
        });
    } else {
      this.setState({ errorMsg: true });
    }
  };

  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <BlogNewsletterBlockContainer>
          <BlogNewsletterBlockContainerInner>
            <Content>
              <TextContent>
                <Title
                  dangerouslySetInnerHTML={convertHtml(this.props.title)}
                />
                <Subtitle
                  dangerouslySetInnerHTML={convertHtml(this.props.subtitle)}
                />
              </TextContent>
              <Form onSubmit={this.handleSubmit}>
                <EmailContent disabled={this.state.disabled}>
                  <InputContainer>
                    <EmailInput
                      name="email"
                      type="email"
                      color={this.props.color}
                      value={this.state.inputValue}
                      onChange={this.updateInputValue}
                    />
                    <ButtonNotUnderlined
                      buttonText={'Sign Up'}
                      color={this.props.color}
                    />
                  </InputContainer>
                </EmailContent>
                {this.state.errorMsg && (
                  <Message>Please enter a valid email address</Message>
                )}
                {this.state.subscriptionSuccess && (
                  <Message>Subscribed!</Message>
                )}
              </Form>
            </Content>
          </BlogNewsletterBlockContainerInner>
        </BlogNewsletterBlockContainer>
      </VisibilitySensor>
    );
  }
}

export default BlogNewsletterBlock;

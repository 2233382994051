const emailTemplate = props => {
  const { clientEmails, state } = props;
  const { name, email, phone, reason, message } = state;
  const template = {
    to_email: clientEmails && clientEmails.toString(),
    from_name: name,
    reply_to: email,
    to_name: 'Admin',
    message_html: `
            <div>
              <div>
                Name: ${name}
              </div>
              <div>
                Phone No: ${phone} 
              </div>
              <div>
                Email: ${email} 
              </div>
              <div>
                Reason for contacting: ${reason} 
              </div>
              <div>
                Message: ${message} 
              </div>
            </div>
          `,
  };
  return template;
};

export default emailTemplate;

import React from 'react';
import { graphql } from 'gatsby';

import ProjectBrief from '../../blocks/projectBrief';
import urlParser from '../../utils/urlParser';
import linkStripper from '../../utils/link-stripper';

function ProjectBriefPrismic({
  paragraph_one_title,
  paragraph_one,
  paragraph_two_title,
  paragraph_two,
  cta_title,
  cta_color,
  cta_arrow_color,
  cta_link,
}) {
  return (
    <ProjectBrief
      paragraph_one_title={paragraph_one_title.text}
      paragraph_one={paragraph_one.text}
      paragraph_two_title={paragraph_two_title.text}
      paragraph_two={paragraph_two.text}
      cta_title={cta_title.text}
      cta_color={cta_color}
      cta_arrow_color={cta_arrow_color}
      cta_link={linkStripper(urlParser(cta_link))}
      cta_link_target={cta_link.target}
    />
  );
}

export const query = graphql`
  fragment ProjectBrief on PrismicPageBodyProjectBrief {
    id
    slice_type
    primary {
      paragraph_one_title {
        text
      }
      paragraph_one {
        text
      }
      paragraph_two_title {
        text
      }
      paragraph_two {
        text
      }
      cta_title {
        text
      }
      cta_arrow_color
      cta_color
      cta_link {
        url
        slug
        target
      }
    }
  }
`;

export default ProjectBrief;

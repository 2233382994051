import React from 'react';
import { graphql } from 'gatsby';

import BlogDivider from '../../../blocks/blog/blogDivider';

function BlogDividerPrismic({ show_line, line_color, height, mobile_height }) {
  return (
    <BlogDivider
      show_line={show_line}
      line_color={line_color}
      height={height}
      mobile_height={mobile_height}
    />
  );
}

export const query = graphql`
  fragment Divider on PrismicBlogBodyDivider {
    id
    slice_type
    primary {
      show_line
      line_color
      height
      mobile_height
    }
  }
`;

export default BlogDivider;

import React from 'react';
import { graphql } from 'gatsby';

import OurGrowthTeam from '../../blocks/ourGrowthTeam/index';

function OurGrowthTeamPrismic({
  title_text,
  subtitle_text,
  title_underline_color,
  team_member_one_image,
  team_member_one_name,
  team_member_one_email,
  team_member_two_image,
  team_member_two_name,
  team_member_two_email,
}) {
  return (
    <OurGrowthTeam
      title_text={title_text}
      subtitle_text={subtitle_text}
      title_underline_color={title_underline_color}
      team_member_one_image={team_member_one_image}
      team_member_one_name={team_member_one_name}
      team_member_one_email={team_member_one_email}
      team_member_two_image={team_member_two_image}
      team_member_two_name={team_member_two_name}
      team_member_two_email={team_member_two_email}
    />
  );
}

export const query = graphql`
  fragment OurGrowthTeam on PrismicPageBodyOurGrowthTeam {
    id
    slice_type
    primary {
      title_text {
        text
      }
      subtitle_text {
        text
      }
      title_underline_color
      team_member_one_image {
        alt
        url
localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      team_member_one_name {
        text
      }
      team_member_one_email {
        text
      }
      team_member_two_image {
        alt
        url
localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      team_member_two_name {
        text
      }
      team_member_two_email {
        text
      }
    }
  }
`;

export default OurGrowthTeam;

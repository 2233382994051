import React from 'react';
import { graphql } from 'gatsby';

import AwardsIcon from '../../blocks/awardIcons';

function AwardIconsPrismic({
  block_color_theme,
  label_text,
  title_text,
  award_icon_one,
  award_icon_two,
  award_icon_three,
  award_icon_four,
  award_icon_five,
  customData,
}) {
  return (
    <AwardsIcon
      block_color_theme={block_color_theme}
      label_text={label_text}
      title_text={title_text}
      award_icon_one={award_icon_one}
      award_icon_two={award_icon_two}
      award_icon_three={award_icon_three}
      award_icon_four={award_icon_four}
      award_icon_five={award_icon_five}
      pageData={customData.prismicPage.data}
    />
  );
}

export const query = graphql`
  fragment AwardIcons on PrismicPageBodyAwardIcons {
    id
    slice_type
    primary {
      block_color_theme
      label_text {
        text
      }
      title_text {
        text
      }
      award_icon_one {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 450) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      award_icon_two {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 450) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      award_icon_three {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 450) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default AwardIconsPrismic;

import React from 'react';
import { graphql } from 'gatsby';
import urlParser from '../../utils/urlParser';

import SmallerServicesList from '../../blocks/smallerServicesList';

function SmallerServicesListPrismic({
  block_color_theme,
  label_text,
  title_text,
  cta_text,
  cta_link,
  column_one_title,
  column_two_title,
  column_three_title,
  column_four_title,
  column_one_link_one,
  column_one_link_two,
  column_one_link_three,
  column_one_link_four,
  column_one_link_one_title,
  column_one_link_two_title,
  column_one_link_three_title,
  column_one_link_four_title,
  column_two_link_one,
  column_two_link_two,
  column_two_link_three,
  column_two_link_four,
  column_two_link_one_title,
  column_two_link_two_title,
  column_two_link_three_title,
  column_two_link_four_title,
  column_three_link_one,
  column_three_link_two,
  column_three_link_three,
  column_three_link_four,
  column_three_link_one_title,
  column_three_link_two_title,
  column_three_link_three_title,
  column_three_link_four_title,
  column_four_link_one,
  column_four_link_two,
  column_four_link_three,
  column_four_link_four,
  column_four_link_one_title,
  column_four_link_two_title,
  column_four_link_three_title,
  column_four_link_four_title,
  block_links_text_color,
  column_link_divider_color,
  cta_color,
}) {
  return (
    <SmallerServicesList
      block_color_theme={block_color_theme}
      block_links_text_color={block_links_text_color}
      label_text={label_text.text}
      title_text={title_text.text}
      cta_text={cta_text.text}
      cta_color={cta_color}
      column_link_divider_color={column_link_divider_color}
      cta_link={urlParser(cta_link)}
      column_one_title={column_one_title.text}
      column_two_title={column_two_title.text}
      column_three_title={column_three_title.text}
      column_four_title={column_four_title.text}
      column_one_link_one={urlParser(column_one_link_one)}
      column_one_link_two={urlParser(column_one_link_two)}
      column_one_link_three={urlParser(column_one_link_three)}
      column_one_link_four={urlParser(column_one_link_four)}
      column_one_link_one_title={column_one_link_one_title.text}
      column_one_link_two_title={column_one_link_two_title.text}
      column_one_link_three_title={column_one_link_three_title.text}
      column_one_link_four_title={column_one_link_four_title.text}
      column_two_link_one={urlParser(column_two_link_one)}
      column_two_link_two={urlParser(column_two_link_two)}
      column_two_link_three={urlParser(column_two_link_three)}
      column_two_link_four={urlParser(column_two_link_four)}
      column_two_link_one_title={column_two_link_one_title.text}
      column_two_link_two_title={column_two_link_two_title.text}
      column_two_link_three_title={column_two_link_three_title.text}
      column_two_link_four_title={column_two_link_four_title.text}
      column_three_link_one={urlParser(column_three_link_one)}
      column_three_link_two={urlParser(column_three_link_two)}
      column_three_link_three={urlParser(column_three_link_three)}
      column_three_link_four={urlParser(column_three_link_four)}
      column_three_link_one_title={column_three_link_one_title.text}
      column_three_link_two_title={column_three_link_two_title.text}
      column_three_link_three_title={column_three_link_three_title.text}
      column_three_link_four_title={column_three_link_four_title.text}
      column_four_link_one={urlParser(column_four_link_one)}
      column_four_link_two={urlParser(column_four_link_two)}
      column_four_link_three={urlParser(column_four_link_three)}
      column_four_link_four={urlParser(column_four_link_four)}
      column_four_link_one_title={column_four_link_one_title.text}
      column_four_link_two_title={column_four_link_two_title.text}
      column_four_link_three_title={column_four_link_three_title.text}
      column_four_link_four_title={column_four_link_four_title.text}
    />
  );
}

export const query = graphql`
  fragment SmallerServicesList on PrismicPageBodySmallerServicesList {
    id
    slice_type
    primary {
      block_color_theme
      block_links_text_color
      column_link_divider_color
      label_text {
        text
      }
      title_text {
        text
      }
      cta_text {
        text
      }
      cta_link {
        url
        slug
      }
      cta_color
      column_one_title {
        text
      }
      column_two_title {
        text
      }
      column_three_title {
        text
      }
      column_four_title {
        text
      }
      column_one_link_one {
        url
        slug
      }
      column_one_link_two {
        url
        slug
      }
      column_one_link_three {
        url
        slug
      }
      column_one_link_four {
        url
        slug
      }
      column_one_link_one_title {
        text
      }
      column_one_link_two_title {
        text
      }
      column_one_link_three_title {
        text
      }
      column_one_link_four_title {
        text
      }
      column_two_link_one {
        url
        slug
      }
      column_two_link_two {
        url
        slug
      }
      column_two_link_three {
        url
        slug
      }
      column_two_link_four {
        url
        slug
      }
      column_two_link_one_title {
        text
      }
      column_two_link_two_title {
        text
      }
      column_two_link_three_title {
        text
      }
      column_two_link_four_title {
        text
      }
      column_three_link_one {
        url
        slug
      }
      column_three_link_two {
        url
        slug
      }
      column_three_link_three {
        url
        slug
      }
      column_three_link_four {
        url
        slug
      }
      column_three_link_one_title {
        text
      }
      column_three_link_two_title {
        text
      }
      column_three_link_three_title {
        text
      }
      column_three_link_four_title {
        text
      }
      column_four_link_one {
        url
        slug
      }
      column_four_link_two {
        url
        slug
      }
      column_four_link_three {
        url
        slug
      }
      column_four_link_four {
        url
        slug
      }
      column_four_link_one_title {
        text
      }
      column_four_link_two_title {
        text
      }
      column_four_link_three_title {
        text
      }
      column_four_link_four_title {
        text
      }
    }
  }
`;

export default SmallerServicesList;

import React from 'react';
import { graphql } from 'gatsby';

import ContactForm from '../../blocks/contactForm/index';

function ContactFormPrismic({
  items,
  title,
  paragraph_one,
  paragraph_two,
  reason_for_contacting_options,
  accent_color,
}) {
  return (
    <ContactForm
      items={items}
      title={title.text}
      paragraph_one={paragraph_one.text}
      paragraph_two={paragraph_two.text}
      reasonForContactingOptions={reason_for_contacting_options.text}
      accent_color={accent_color ? accent_color : '#ff22ee'}
    />
  );
}

export const query = graphql`
  fragment ContactForm on PrismicPageBodyContactForm {
    id
    slice_type
    primary {
      title {
        text
      }
      paragraph_one {
        text
      }
      paragraph_two {
        text
      }
      reason_for_contacting_options {
        text
      }
      accent_color
    }
    items {
      target_email {
        text
      }
    }
  }
`;

export default ContactForm;

import React from 'react';
import { graphql } from 'gatsby';

import TeamBlock from '../../blocks/teamBlock';
import urlParser from '../../utils/urlParser';

function TeamBlockPrismic({
  subtitle_header,
  subtitle_text,
  image_1,
  image_2,
  image_3,
  image_4,
  cta_title,
  cta_link,
  cta_theme,
}) {
  return (
    <div>
      <TeamBlock
        subtitle_header={subtitle_header.text}
        subtitle_text={subtitle_text.text}
        image_1={image_1.url}
        image_2={image_2.url}
        image_3={image_3.url}
        image_4={image_4.url}
        cta_title={cta_title.text}
        cta_link={cta_link.url}
        cta_link_target={cta_link.target}
        cta_theme={cta_theme}
      />
    </div>
  );
}

export const query = graphql`
  fragment TeamBlock on PrismicPageBodyTeamBlock {
    id
    slice_type
    primary {
      subtitle_header {
        text
      }
      subtitle_text {
        text
      }
      image_1 {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      image_2 {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      image_3 {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      image_4 {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      cta_title {
        text
      }
      cta_link {
        url
        slug
        target
      }
      cta_theme
    }
  }
`;

export default TeamBlockPrismic;

import React, { Component } from 'react';
import Slider from 'react-slick';
import Helmet from 'react-helmet';
import {
  SliderItem,
  SliderOneItem,
  SliderItemText,
  SliderItemName,
} from '../../utils/global-styles';

const settings = {
  className: 'center center-slide',
  centerMode: true,
  infinite: true,
  centerPadding: '260px',
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: false,
  autoplay: false,
  autoplaySpeed: 8000,
  focusOnSelect: true,
  speed: 1000,
  responsive: [
    {
      breakpoint: 1367,
      settings: {
        centerPadding: '200px',
      },
    },
    {
      breakpoint: 1200,
      settings: {
        centerPadding: '160px',
      },
    },
    {
      breakpoint: 1025,
      settings: {
        centerPadding: '120px',
      },
    },
    {
      breakpoint: 768,
      settings: {
        centerPadding: '80px',
      },
    },
    {
      breakpoint: 481,
      settings: {
        slidesToShow: 1,
        ccenterMode: true,
        centerPadding: '20%',
      },
    },
  ],
};

export class CenterModelSlickSlider extends Component {
  componentDidUpdate() {
    if (this.refs.slick) {
      this.refs.slick.innerSlider.onWindowResized();
    }
  }

  render() {
    const { items } = this.props;

    return (
      <>
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            charset="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css"
          />
        </Helmet>

        {items.length === 1 && (
          <SliderOneItem>
            <SliderItemText>{items[0].review_text.text}</SliderItemText>
            <SliderItemName>{items[0].review_author.text}</SliderItemName>
          </SliderOneItem>
        )}
        {items.length > 1 && (
          <Slider ref="slick" {...settings}>
            {items.map((item, index) => (
              <SliderItem key={index}>
                <SliderItemText>{item.review_text.text}</SliderItemText>
                <SliderItemName>{item.review_author.text}</SliderItemName>
              </SliderItem>
            ))}
          </Slider>
        )}
      </>
    );
  }
}

import React from 'react';
import { graphql } from 'gatsby';

import CaseStudyHeaderTwo from '../../blocks/caseStudyHeaderTwo';

function CaseStudyHeaderTwoPrismic({
  color_theme,
  upper_block_background_color,
  label_text,
  label_text_color,
  tags,
  tags_color,
  title_text,
  title_color,
  subtitle_text,
  subtitle_color,
  cta_text,
  cta_link,
  cta_text_color,
  the_brief_title,
  the_brief_subtitle,
  brief_paragraph_title,
  brief_paragraph,
  brief_cta_text,
  brief_cta_link,
  brief_cta_color,
  header_image,
  cta_arrow_color,
}) {
  return (
    <CaseStudyHeaderTwo
      color_theme={color_theme}
      upper_block_background_color={upper_block_background_color}
      label_text={label_text}
      label_text_color={label_text_color}
      tags={tags}
      tags_color={tags_color}
      title_text={title_text}
      title_color={title_color}
      subtitle_text={subtitle_text}
      subtitle_color={subtitle_color}
      cta_text={cta_text}
      cta_link={cta_link}
      cta_link_target={cta_link.target}
      cta_text_color={cta_text_color}
      cta_arrow_color={cta_arrow_color}
      the_brief_title={the_brief_title}
      the_brief_subtitle={the_brief_subtitle}
      brief_paragraph_title={brief_paragraph_title}
      brief_paragraph={brief_paragraph}
      brief_cta_text={brief_cta_text}
      brief_cta_link={brief_cta_link}
      brief_cta_link_target={brief_cta_link.target}
      brief_cta_color={brief_cta_color}
      header_image={header_image}
    />
  );
}

export const query = graphql`
  fragment CaseStudyHeaderTwo on PrismicPageBodyCaseStudyHeader2 {
    id
    slice_type
    primary {
      color_theme
      upper_block_background_color
      label_text {
        text
      }
      label_text_color
      tags {
        text
      }
      tags_color
      title_text {
        text
      }
      title_color
      subtitle_text {
        text
      }
      subtitle_color
      cta_text {
        text
      }
      cta_link {
        url
        slug
        target
      }
      cta_text_color
      the_brief_title {
        text
      }
      the_brief_subtitle {
        text
      }
      brief_paragraph_title {
        text
      }
      brief_paragraph {
        text
      }
      brief_cta_text {
        text
      }
      brief_cta_link {
        url
        slug
        target
      }
      brief_cta_color
      cta_arrow_color
      header_image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 2700) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default CaseStudyHeaderTwo;

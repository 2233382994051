import React from 'react';
import { graphql } from 'gatsby';

import RightTwoImageLeftText from '../../blocks/rightTwoImagesLeftText';

function RightTwoImageLeftTextPrismic({
  label_text,
  label_color,
  title_text,
  paragraph_text,
  image_1,
  image_2,
}) {
  return (
    <RightTwoImageLeftText
      label_text={label_text.text}
      label_color={label_color}
      title_text={title_text.text}
      paragraph_text={paragraph_text.text}
      image_1={image_1}
      image_2={image_2}
    />
  );
}

export const query = graphql`
  fragment RightTwoImageLeftText on PrismicPageBodyRightTwoImageLeftText {
    id
    slice_type
    primary {
      label_text {
        text
      }
      label_color
      title_text {
        text
      }
      paragraph_text {
        text
      }
      image_1 {
        alt
        url
localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      image_2 {
        alt
        url
localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default RightTwoImageLeftText;

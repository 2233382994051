import React from 'react';
import { graphql } from 'gatsby';

import StandOutBlock from '../../blocks/standOutBlock/index';

function StandOutBlockPrismic({
  block_color_theme,
  label_text,
  title_text,
  subtitle_text,
  items,
  cta_text_color,
  cta_arrow_color,
}) {
  return (
    <StandOutBlock
      items={items}
      block_color_theme={block_color_theme}
      label_text={label_text.text}
      title_text={title_text.text}
      subtitle_text={subtitle_text.text}
      cta_text_color={cta_text_color}
      cta_arrow_color={cta_arrow_color}
    />
  );
}

export const query = graphql`
  fragment StandOutBlock on PrismicPageBodyStandOutBlock {
    id
    slice_type
    primary {
      block_color_theme
      label_text {
        text
      }
      title_text {
        text
      }
      subtitle_text {
        text
      }
      cta_text_color
      cta_arrow_color
    }
    items {
      image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      item_title {
        text
      }
      item_paragraph {
        text
      }
      item_cta_text {
        text
      }
      item_link {
        url
        target
      }
    }
  }
`;

export default StandOutBlock;

import React from 'react';
import { graphql } from 'gatsby';

import CaseStudySplash from '../../blocks/caseStudySplash/index';
import urlParser from '../../utils/urlParser';
//eslint-disable-next-line
function PrismicCaseStudySplash({
  label_text,
  label_colour,
  title_text,
  cta_text,
  cta_link,
  cta_colour,
  cta_arrow_colour,
  case_study_image,
  case_study_figure,
  case_study_figure_type,
  case_study_subtitle,
  block_background_color,
  items,
}) {
  return (
    <CaseStudySplash
      label_text={label_text.text}
      label_colour={label_colour}
      title_text={title_text.text}
      cta_text={cta_text.text}
      cta_link={cta_link.url}
      block_background_color={block_background_color}
      cta_colour={cta_colour}
      cta_arrow_colour={cta_arrow_colour}
      case_study_image={case_study_image.url}
      case_study_figure={case_study_figure}
      case_study_figure_type={case_study_figure_type}
      case_study_subtitle={case_study_subtitle}
      cta_link_target={cta_link.target}
      items={items}
    />
  );
}

export const query = graphql`
  fragment CaseStudyFullWidthSplash on PrismicPageBodyCaseStudyFullWidthSplash {
    id
    slice_type
    primary {
      label_text {
        text
      }
      label_colour
      title_text {
        text
      }
      cta_text {
        text
      }
      block_background_color
      cta_link {
        url
        slug
        target
      }
      cta_colour
      cta_arrow_colour
      case_study_image {
        url
      }
    }
    items {
      case_study_figure {
        text
      }
      case_study_figure_type {
        text
      }
      case_study_subtitle {
        text
      }
    }
  }
`;

export default PrismicCaseStudySplash;

import React from 'react';
import { graphql } from 'gatsby';

import BlogParagraphText from '../../../blocks/blog/blogParagraphText';

function BlogParagraphTextPrismic({ text_content, ...rest }) {
  return <BlogParagraphText text_content={text_content} {...rest} />;
}

export const query = graphql`
  fragment BlogParagraphText on PrismicBlogBodyParagraphText {
    id
    slice_type
    primary {
      text_content {
        html
        raw
        text
      }
      show_floating_signup
    }
  }
`;

export default BlogParagraphTextPrismic;

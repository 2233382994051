import React from 'react';
import styled from '@emotion/styled';
import linkStripper from '../utils/link-stripper';
import { motion } from 'framer-motion';
import LazyImage from '../components/lazy-image';

//Component Import
import ButtonNotUnderlined from '../components/ButtonNotUnderlined';
import BlankSpace from '../components/blankSpace';

const LinkObj = styled.a`
  width: 85%;
  max-width: 1600px;
  display: flex;
  height: 250px;
  align-items: center;
  position: relative;
  overflow: hidden;
  @media (max-width: 735px) {
    width: 90%;
    height: unset;
  }
`;

const CtaCardContainer = styled(motion.div)`
  width: 100%;
  max-width: 1600px;
  display: flex;
  height: 250px;
  align-items: center;
  position: relative;
  @media (max-width: 735px) {
    flex-wrap: wrap;
  }
`;

const CtaTextContainer = styled.div`
  width: 50%;
  background-color: #212121;
  height: 100%;
  display: flex;
  justify-content: center;
  padding-left: 15px;
  padding-right: 15px;
  align-items: center;
  @media (max-width: 735px) {
    width: 100%;
    flex-direction: column-reverse;
  }
`;

const CtaTextContainerInner = styled.div`
  @media (max-width: 735px) {
    width: 90%;
    margin-left: 5%;
  }
`;

const CtaImgContainer = styled.div`
  width: 50%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: 735px) {
  }
`;

const CtaCardBlur = styled.div`
  background: #d1d1d1;
  filter: blur(28px);
  opacity: 0.6;
  width: 80%;
  height: 70%;
  margin-top: 5%;
  position: absolute;
  z-index: -1;
`;

const CtaCardContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CtaCardContainerInner = styled.div`
  width: 90%;
  max-width: 1440px;
  background-color: #131313;
  display: flex;
  @media (max-width: 800px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
`;

const CtaCardLeftContent = styled.div`
  width: 50%;
  padding-top: 80px;
  padding-bottom: 80px;
  margin-left: 110px;
  @media (max-width: 1200px) {
    margin-left: 75px;
  }
  @media (max-width: 1050px) {
    margin-left: 25px;
  }

  @media (max-width: 850px) {
    margin-left: 25px;
  }
  @media (max-width: 800px) {
    width: 90%;
    margin-left: 5%;
    padding: 0px;
    padding-top: 25px;
    padding-bottom: 25px;
  }
`;

const CtaCardRightContent = styled.div`
  width: 50%;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ButtonContainer = styled.div`
  margin-bottom: 15px;
`;

const Subtitle = styled.div`
  font-weight: 800;
  font-size: 24px;
  line-height: 30px;
  font-family: Montserrat;
  color: #ffffff;
  padding-right: 10px;
  margin-top: 20px;

  @media (max-width: 1050px) {
    font-size: 20px;
    line-height: 26px;
  }
  @media (max-width: 825px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const CtaCard = ({
  props,
  cta_title,
  cta_description,
  cta_theme,
  image,
  cta_link,
  cta_link_target,
}) => (
  <CtaCardContainerOuter>
    <CtaCardContainerInner>
      <CtaCardLeftContent>
        <ButtonContainer>
          <ButtonNotUnderlined
            buttonText={cta_title}
            color={'#ffffff'}
            link={linkStripper(cta_link)}
            arrowColor={cta_theme}
            fontSize={'40px'}
            fontSizeMobile={'20px'}
            link={linkStripper(cta_link)}
            target={cta_link_target}
          />
        </ButtonContainer>
        <Subtitle>{cta_description}</Subtitle>
      </CtaCardLeftContent>
      <CtaCardRightContent>
        <ImageContainer>{image && <LazyImage {...image} />}</ImageContainer>
      </CtaCardRightContent>
    </CtaCardContainerInner>
  </CtaCardContainerOuter>

  /*
  <CtaCardContainerOuter>
    <LinkObj href={linkStripper(cta_link)}>
      <CtaCardContainer whileHover={{ scale: 1.005 }}>
        <CtaTextContainer>
          <CtaTextContainerInner>
            <ButtonNotUnderlined
              buttonText={cta_title}
              color={cta_theme}
              link={linkStripper(cta_link)}
              arrowColor={cta_theme}
              fontSize={'40px'}
              fontSizeMobile={'20px'}
              link={linkStripper(cta_link)}
              target={cta_link_target}
            />
            <BlankSpace height="15px" />
            <Subtitle>{cta_description}</Subtitle>
          </CtaTextContainerInner>
        </CtaTextContainer>
        <CtaImgContainer
          style={{
            backgroundImage: `url(${image})`,
          }}
        />
        <CtaCardBlur />
      </CtaCardContainer>
    </LinkObj>
  </CtaCardContainerOuter>
        */
);

export default CtaCard;

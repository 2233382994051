import React from 'react';
import { graphql } from 'gatsby';

import Anchor from '../../blocks/anchor';

function AnchorPrismic({ anchor_id, customData }) {
  return (
    <Anchor anchor_id={anchor_id.raw} pageData={customData.prismicPage.data} />
  );
}

export const query = graphql`
  fragment Anchor on PrismicPageBodyAnchor {
    id
    slice_type
    primary {
      anchor_id
    }
  }
`;

export default Anchor;

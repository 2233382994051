import React from 'react';
import { graphql } from 'gatsby';

import ReviewSlider from '../../blocks/reviewSlider';

function ReviewSliderPrismic({
  block_color_theme,
  star_count,
  cta_text,
  cta_link,
  review_source_logo,
  items,
}) {
  return (
    <ReviewSlider
      items={items}
      block_color_theme={block_color_theme}
      star_count={star_count}
      cta_text={cta_text}
      cta_link={cta_link}
      review_source_logo={review_source_logo}
    />
  );
}

export const query = graphql`
  fragment ReviewSlider on PrismicPageBodyReviewSlider {
    id
    slice_type
    primary {
      block_color_theme
      star_count
      cta_text {
        text
      }
      cta_link {
        url
      }
      review_source_logo {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
    items {
      review_text {
        text
      }
      review_author {
        text
      }
    }
  }
`;

export default ReviewSlider;

import React from 'react';
import { graphql } from 'gatsby';

import Vacancies from '../../blocks/vacancies';

function VacanciesPrismic({
  items,
  block_color_theme,
  label_title,
  title,
  subtitle,
  paragraph_title,
  paragraph,
}) {
  return (
    <Vacancies
      items={items}
      block_color_theme={block_color_theme}
      label_title={label_title.text}
      title={title.text}
      subtitle={subtitle.text}
      paragraph_title={paragraph_title.text}
      paragraph={paragraph.text}
    />
  );
}

export const query = graphql`
  fragment Vacancies on PrismicPageBodyVacancies {
    id
    slice_type
    primary {
      block_color_theme
      label_title {
        text
      }
      title {
        text
      }
      subtitle {
        text
      }
      paragraph_title {
        text
      }
      paragraph {
        text
      }
    }
    items {
      job_location {
        text
      }
      job_title {
        text
      }
      job_link {
        url
        target
      }
    }
  }
`;

export default Vacancies;

import React from 'react';
import { graphql } from 'gatsby';

import FooterPreface from '../../blocks/footerPreface';
import urlParser from '../../utils/urlParser';

function PrismicFooterPreface({
  subtitle_text,
  title_text,
  cta_text,
  cta_link,
  cta_color,
  footer_text_background_wave,
  left_aligned_image,
  right_aligned_image,
}) {
  return (
    <FooterPreface
      subtitle_text={subtitle_text.text}
      main_text={title_text.text}
      cta_text={cta_text.text}
      cta_link={urlParser(cta_link)}
      cta_link_target={cta_link.target}
      cta_color={cta_color}
      footer_text_background_wave={footer_text_background_wave.url}
      left_aligned_image={left_aligned_image}
      right_aligned_image={right_aligned_image}
    />
  );
}

export const query = graphql`
  fragment FooterPreface on PrismicPageBodyFooterPreface {
    id
    slice_type
    primary {
      subtitle_text {
        text
      }
      title_text {
        text
      }
      cta_text {
        text
      }
      cta_link {
        url
        slug
        target
      }
      cta_color
      footer_text_background_wave {
        url
      }
      left_aligned_image {
        alt
        url
      }
      right_aligned_image {
        alt
        url
      }
    }
  }
`;

export default PrismicFooterPreface;

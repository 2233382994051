import React from 'react';
import styled from '@emotion/styled';

//Component Import
import Label from '../../components/Label';
import RightImageLeftContent from './leftImageRightContent';
import LeftImageRightContent from './rightImageLeftContent';
import BlankSpace from '../../components/blankSpace';
import Squiggle from '../../components/squiggle';
import convertHtml from '../../utils/convertHtml';
import TitleUnderlined from '../../components/TitleUnderlined';

const StandOutBlockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const Container = styled.div`
  width: 60%;
  max-width: 1280px;
  @media (max-width: 1325px) {
    width: 75%;
  }
  @media (max-width: 1000px) {
    width: 90%;
  }
`;

const HeadingContent = styled.div``;

const Title = styled.div`
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  margin-top: 30px;
  margin-bottom: 150px;
  @media (max-width: 735px) {
    margin-bottom: 50px;
  }
`;

const ProccessItemsContainer = styled.div``;

const SquiggleContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin-right: -175px;
  display: none;
`;

const SquiggleContainerTwo = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  margin-left: -150px;
`;

const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Subtitle = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  width: 90%;
  margin-left: 5%;
  color: #ffffff;
`;

const StandOutBlock = ({
  props,
  block_color_theme,
  label_text,
  title_text,
  subtitle_text,
  cta_arrow_color,
  cta_text_color,
  items,
}) => (
  <StandOutBlockContainer>
    <Container>
      <HeadingContent>
        <LabelContainer>
          <Label text={label_text.text} color={block_color_theme} />
        </LabelContainer>
        <TitleContainer>
          <TitleUnderlined
            underlineColor={block_color_theme}
            textColor={'#ffffff'}
            fontSize={'58px'}
            mobileFontSize={'26px'}
            titleText={title_text.text}
            h2Mode={true}
          />
        </TitleContainer>
        <Subtitle
          dangerouslySetInnerHTML={convertHtml(subtitle_text.text)}
        ></Subtitle>
      </HeadingContent>
      <ProccessItemsContainer>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            {index % 2 ? (
              <LeftImageRightContent
                key={index}
                title={item.item_title.text}
                paragraph={item.item_paragraph.text}
                image={item.image}
                number={index + 1}
                cta_text={item.item_cta_text.text}
                cta_link={item.item_link.url}
                cta_link_target={item.item_link.target}
                length={items.length}
                displayLine={index === 0 ? false : true}
                colorTheme={block_color_theme}
                cta_text_color={cta_text_color}
                cta_arrow_color={cta_arrow_color}
              />
            ) : (
              <RightImageLeftContent
                key={index}
                title={item.item_title.text}
                paragraph={item.item_paragraph.text}
                image={item.image}
                number={index + 1}
                cta_text={item.item_cta_text.text}
                cta_link={item.item_link.url}
                cta_link_target={item.item_link.target}
                length={items.length}
                displayLine={index === 0 ? false : true}
                colorTheme={block_color_theme}
                cta_text_color={cta_text_color}
                cta_arrow_color={cta_arrow_color}
              />
            )}
          </React.Fragment>
        ))}
      </ProccessItemsContainer>
      <BlankSpace height={'200px'} mobileHeight={'50px'} />
    </Container>
  </StandOutBlockContainer>
);

export default StandOutBlock;

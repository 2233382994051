import React from 'react';
import styled from '@emotion/styled';

const BlankSpacePrismicContainer = styled.div`
  height: ${props => props.height};
  width: 100%;
  @media (max-width: 735px) {
    height: ${props => props.mobileHeight};
  }
`;

const BlankSpacePrismic = ({ desktop_height, mobile_height }) => (
  <BlankSpacePrismicContainer
    height={desktop_height + 'px'}
    mobileHeight={mobile_height + 'px'}
  ></BlankSpacePrismicContainer>
);

export default BlankSpacePrismic;

import React from 'react';
import { graphql } from 'gatsby';

import FAQ from '../../blocks/faq';

function PrismicFaq({ items, title, block_color_theme }) {
  return (
    <FAQ
      items={items}
      title={title.text}
      block_color_theme={block_color_theme}
    />
  );
}

export const query = graphql`
  fragment Faq on PrismicPageBodyFaqs {
    id
    slice_type
    primary {
      block_color_theme
      title {
        text
      }
    }
    items {
      question {
        html
        text
      }
      answer {
        html
        text
      }
    }
  }
`;

export default PrismicFaq;

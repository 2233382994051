import React from 'react';
import styled from '@emotion/styled';

//Compnent import
import Label from '../components/Label';
import Squiggle from '../components/squiggle';

const LeftSubtitleRightTextContainer = styled.div`
  display: flex;
  position: relative;
  font-family: Montserrat;
  overflow: hidden;
  @media (max-width: 800px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    flex-wrap: wrap;
  }
`;

const LeftContent = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 800px) {
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 25px;
  }
`;

const LeftContentInner = styled.div`
  width: fit-content;
  margin-right: 150px;
  @media (max-width: 800px) {
    margin-right: 0px;
  }
`;

const RightContent = styled.div`
  width: 50%;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const Title = styled.h2`
  font-weight: 800;
  font-size: 40px;
  font-family: Montserrat;
  font-style: normal;
  line-height: 48px;
  color: #ffffff;
  max-width: 450px;
  margin-top: 20px;
  @media (max-width: 800px) {
    max-width: 100%;
  }
`;

const Paragraph = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  color: #ffffff;
  max-width: 450px;
  margin-top: 20px;
`;

const Subtitle = styled.div`
  font-size: 24px;
  line-height: 36px;
  font-weight: 800;
  text-align: right;
  font-family: Montserrat;
  font-style: normal;
  max-width: 250px;
  color: #ffffff;
  @media (max-width: 800px) {
    text-align: left;
  }
`;

const SubtitleText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: right;
  max-width: 250px;
  color: #ffffff;
  @media (max-width: 800px) {
    text-align: left;
    font-size: 18px;
    line-height: 36px;
    max-width: 100%;
  }
`;

const SquiggleContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: -150px;
  transform: rotate(45deg);
  @media (max-width: 800px) {
    display: none;
  }
`;

const LeftSubtitleRightText = ({
  props,
  subtitle_title,
  subtitle_text,
  label_text,
  label_color,
  paragraph_title,
  paragraph_text,
  background_color,
}) => (
  <LeftSubtitleRightTextContainer>
    <LeftContent>
      <LeftContentInner>
        <Subtitle>{subtitle_title}</Subtitle>
        <SubtitleText>{subtitle_text}</SubtitleText>
      </LeftContentInner>
    </LeftContent>
    <RightContent>
      <Label text={label_text} color={label_color} />
      <Title>{paragraph_title}</Title>
      <Paragraph>{paragraph_text}</Paragraph>
    </RightContent>
  </LeftSubtitleRightTextContainer>
);

export default LeftSubtitleRightText;

import React, { Component } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import range from 'lodash/range';
import { CenterModelSlickSlider } from '../../components/CenterModeSlickSlider';
import {
  ReviewSliderContainer,
  GoogleDiv,
  TopSection,
  TopSectionLeft,
  TopSectionRight,
  RecoLink,
} from './style';
import {
  ScoreContainer,
  StarContainer,
  SliderSlickDiv,
} from '../../utils/global-styles';
import GoogleLogo from '../../images/assets/googleLogo.png';
import Star from '../../images/assets/star.svg';
import RedArrow from '../../images/assets/redArrow.svg';
import ButtonNotUnderlined from '../../components/ButtonNotUnderlined';

const item = {
  hidden: { x: -20, opacity: 0, scale: 7 },
  visible: {
    x: 0,
    opacity: 1,
    scale: 1,
  },
};
const container = {
  hidden: { opacity: 0, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.075,
    },
  },
};
export class ReviewSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };
  render() {
    const {
      items,
      review_source_logo,
      block_color_theme,
      cta_link,
      cta_text,
      star_count,
    } = this.props;

    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <ReviewSliderContainer>
          <TopSection>
            <TopSectionLeft>
              <GoogleDiv src={review_source_logo.url || GoogleLogo} />
              <ScoreContainer
                variants={container}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                {range(0, parseInt(star_count)).map(index => (
                  <StarContainer key={index} variants={item}>
                    <Star
                      fill={block_color_theme}
                      style={{
                        marginLeft: '1.5px',
                        marginRight: '1.5px',
                      }}
                    />
                  </StarContainer>
                ))}
              </ScoreContainer>
            </TopSectionLeft>
            <TopSectionRight>
              <ButtonNotUnderlined
                buttonText={cta_text.text}
                link={cta_link.url}
                arrowColor={block_color_theme}
                color={block_color_theme}
                fontSizeMobile={'12px'}
              />
            </TopSectionRight>
          </TopSection>

          <SliderSlickDiv>
            <CenterModelSlickSlider items={items} />
          </SliderSlickDiv>
        </ReviewSliderContainer>
      </VisibilitySensor>
    );
  }
}

export default ReviewSlider;

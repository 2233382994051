import React from 'react';
import styled from '@emotion/styled';

//Component Import
import Label from '../components/Label';
import NumberBlock from '../components/numberBlock';
import BlankSpace from '../components/blankSpace';

const StatsBlockContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 80px;
  font-family: Montserrat;
  overflow: hidden;
`;

const StatsBlockContainer = styled.div`
  width: 100%;
  max-width: 1440px;
`;

const LabelContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 900px) {
  }
  @media (max-width: 800px) {
    padding-left: 0;
  }
`;

const LabelContainerInner = styled.div`
  width: 450px;
  display: flex;
  justify-content: flex-start;
`;

const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 20px;
  @media (max-width: 800px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
`;

const LeftText = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  margin-top: -80px;
  margin-right: 50px;

  @media (max-width: 800px) {
    width: 100%;
    justify-content: flex-start;
    margin-left: 5%;
    margin-right: 5%;
  }
`;

const LeftTextInner = styled.div`
  max-width: 450px;
  @media (max-width: 900px) {
    padding-left: 50px;
  }
  @media (max-width: 800px) {
    max-width: 90%;
    padding-left: unset;
  }
`;

const RightText = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  padding-left: 20px;
  @media (max-width: 800px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    padding: 0;
    justify-content: flex-start;
  }
`;

const RightTextInner = styled.div`
  max-width: 500px;
  @media (max-width: 800px) {
    max-width: 100%;
  }
`;

const HeadingText = styled.h2`
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  font-style: normal;
  font-family: Montserrat;
  color: #ffffff;
  @media (max-width: 800px) {
    margin-bottom: 25px;
  }
`;

const ParagraphText = styled.div`
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  color: #ffffff;
  @media (max-width: 1200px) {
    padding-right: 50px;
  }
`;

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  @media (max-width: 1050px) {
    max-width: 400px;
  }

  @media (max-width: 800px) {
    max-width: 400px;
  }

  @media (max-width: 650px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const Stat = styled.div`
  display: flex;
  @media (max-width: 800px) {
    width: 50%;
    margin-bottom: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 490px) {
    width: 100%;
  }
`;

const LeftTextOuter = styled.div``;

const LabelContainer = styled.div`
  margin-bottom: 30px;
  @media (max-width: 900px) {
    padding-left: 50px;
  }
  @media (max-width: 800px) {
    padding: 0;
  }
`;

const statsBlockReversed = ({
  props,
  label_text,
  label_color,
  title,
  paragraph_text,
  items,
}) => (
  <StatsBlockContainerOuter>
    <StatsBlockContainer>
      <TextContainer>
        <RightText>
          <RightTextInner>
            <ParagraphText>{paragraph_text}</ParagraphText>
            <BlankSpace height="40px" />
            <StatContainer>
              {items.map((stat, index) => (
                <Stat key={index}>
                  <NumberBlock
                    number={stat.stat_value.text}
                    statType={stat.stat_type.text}
                    color={'#ffffff'}
                    numberSubtitle={stat.stat_text.text}
                    statFontSize={'100px'}
                    statFontLineHeight={'80px'}
                    fontWeight={'bold'}
                    padding={'20px 0px 0px 0px'}
                    borderStyle={'solid'}
                    borderColor={'#ffffff'}
                    margin={'0'}
                  />
                </Stat>
              ))}
            </StatContainer>
          </RightTextInner>
        </RightText>
        <LeftText>
          <LeftTextOuter>
            <LabelContainer>
              <Label text={label_text} color={label_color} />
            </LabelContainer>
            <LeftTextInner>
              <HeadingText>{title}</HeadingText>
            </LeftTextInner>
          </LeftTextOuter>
        </LeftText>
      </TextContainer>
    </StatsBlockContainer>
  </StatsBlockContainerOuter>
);

export default statsBlockReversed;

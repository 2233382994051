import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const LargeReviewSliderContainer = styled.div`
    position: relative;
    padding-top: 5rem;
    padding-bottom: 4rem;
    overflow: hidden;
`;
export const GlassDoorDiv = styled(motion.img)`
    max-width: 163px;
    width: 100%;
    margin: 23px auto 60px;

    @media (max-width: 1199px) {
        margin: 15px auto 40px;
    }
    @media (max-width: 965px) {
        max-width: 130px;
        margin: 10px auto 32px;
    }
    @media (max-width: 735px) {
        max-width: 100px;
        margin: 5px auto 27px;
    }
`;
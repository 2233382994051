import React from 'react';
import { graphql } from 'gatsby';

import TextCta from '../../blocks/TextCta';
import urlParser from '../../utils/urlParser';

function TextCtaPrismic({ cta_text, cta_theme, cta_link, paragraph_text }) {
  return (
    <TextCta
      paragraph_text={paragraph_text}
      cta_text={cta_text.text}
      cta_theme={cta_theme}
      cta_link={cta_link.url}
      cta_link_target={cta_link.target}
    />
  );
}

export const query = graphql`
  fragment TextCta on PrismicPageBodyTextCta {
    id
    slice_type
    primary {
      paragraph_text {
        html
      }
      cta_text {
        text
      }
      cta_link {
        url
        target
      }
      cta_theme
    }
  }
`;

export default TextCtaPrismic;

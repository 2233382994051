//Page Imports
import BlankSpace from '../components/prismic-adapters/prismic-blank-space';
import ExpertiseHeader from '../components/prismic-adapters/prismic-expertise-header';
import WhatWeDo from '../components/prismic-adapters/prismic-what-we-do';
import LeftTextRightImage from '../components/prismic-adapters/prismic-left-text-right-image';
import CaseStudyFullWidthSplash from '../components/prismic-adapters/prismic-case-study-full-width-splash';
import AwardsBlock from '../components/prismic-adapters/prismic-awards-block';
import FooterPreface from '../components/prismic-adapters/prismic-footer-preface';
import CustomHtml from '../components/customHtml';
import SubPageHeader from '../components/prismic-adapters/prismic-subpage-header';
import Review from '../components/prismic-adapters/prismic-review.jsx';
import RightTextLeftImage from '../components/prismic-adapters/prismic-right-text-left-image';
import BackgroundLeftTextRightImage from '../components/prismic-adapters/prismic-background-left-text-right-image';
import ProcessBlock from '../components/prismic-adapters/prismic-process-block';
import RectangleSplash from '../components/prismic-adapters/prismic-rectangle-splash';
import Faq from '../components/prismic-adapters/prismic-faq.jsx';
import CaseStudySplash from '../components/prismic-adapters/prismic-case-study-splash';
import StatsBlock from '../components/prismic-adapters/prismic-stats-block';
import GradientStatBlock from '../components/prismic-adapters/prismic-gradient-stat-block';
import LeftSubtitleRightText from '../components/prismic-adapters/prismic-left-subtitle-right-text';
import RightLinksLeftSubtitle from '../components/prismic-adapters/prismic-right-links-left-subtitle';
import TeamBlock from '../components/prismic-adapters/prismic-team-block';
import CtaCard from '../components/prismic-adapters/prismic-cta-card';
import CtaText from '../components/prismic-adapters/prismic-text-cta';
import ExpertiseHeaderTwo from '../components/prismic-adapters/prismic-expertise-header-two';
import ServicesListExpanded from '../components/prismic-adapters/prismic-services-list-expanded';
import CaseStudiesSlider from '../blocks/caseStudiesSlider';
import Anchor from '../components/prismic-adapters/prismic-anchor';
import LeftText from '../components/prismic-adapters/prismic-left-text';
import RightText from '../components/prismic-adapters/prismic-right-text';
import HomepageHeader from '../components/prismic-adapters/prismic-homepage-header';
import RightTwoImageLeftText from '../components/prismic-adapters/prismic-right-two-image-left';
import RightTwoTextLeftImage from '../components/prismic-adapters/prismic-right-two-text-left-image';
import ClientList from '../components/prismic-adapters/prismic-client-list';
import SmallerServicesList from '../components/prismic-adapters/prismic-smaller-services-list';
import CaseStudyHeaderOne from '../components/prismic-adapters/prismic-case-study-header-one';
import ProjectBrief from '../components/prismic-adapters/prismic-project-brief';
import TypingText from '../components/prismic-adapters/prismic-typing-text';
import ContactForm from '../components/prismic-adapters/prismic-contact-form';
import OurGrowthTeam from '../components/prismic-adapters/prismic-our-growth-team';
import WhatHappensNext from '../components/prismic-adapters/prismic-what-happens-next';
import RecognitionSplash from '../components/prismic-adapters/prismic-recognition-splash';
import ReviewTwo from '../components/prismic-adapters/prismic-review-two';
import GoogleReviewSlider from '../components/prismic-adapters/prismic-google-review-slider';
import LargeReviewSlider from '../components/prismic-adapters/prismic-large-review-slider';
import ReviewSlider from '../components/prismic-adapters/prismic-review-slider';
import CaseStudyHeaderTwo from '../components/prismic-adapters/prismic-case-study-header-two';
import MeetTheTeam from '../components/prismic-adapters/prismic-meet-the-team';
import Vacancies from '../components/prismic-adapters/prismic-vacancies';
import FlexibleCards from '../components/prismic-adapters/prismic-flexible-cards';
import ClientsBlock from '../components/prismic-adapters/prismic-clients-block';
import SocialImagesBlock from '../components/prismic-adapters/prismic-social-images-block';
import TwoImageHeader from '../components/prismic-adapters/prismic-two-image-header';
import Proposition from '../components/prismic-adapters/prismic-proposition';
import StandOutBlock from '../components/prismic-adapters/prismic-stand-out-block';
import MeetOurTeamSplash from '../components/prismic-adapters/prismic-meet-our-team-splash';
import AwardsNoTitle from '../components/prismic-adapters/prismic-awards-no-title';
import AwardIcons from '../components/prismic-adapters/prismic-awards-icon';
import StatsBlockReversed from '../components/prismic-adapters/prismic-stats-block-reversed';
import ImageGallery from '../components/prismic-adapters/prismic-image-gallery';
import FullWidthImageHeader from '../components/prismic-adapters/prismic-full-width-image-header';
import VideoShowcase from '../components/prismic-adapters/prismic-video-showcase';
import OurProcess from '../components/prismic-adapters/prismic-process';
import StandardText from '../components/prismic-adapters/prismic-standard-text';
import DynamicCaseStudyOne from '../components/prismic-adapters/prismic-dynamic_case_study_one';

//Blog Imports
import BlogHeading from '../components/prismic-adapters/blog/prismic-blog-heading';
import BlogParagraphText from '../components/prismic-adapters/blog/prismic-blog-paragraph-text';
import BlogYoutubeVideo from '../components/prismic-adapters/blog/prismic-blog-youtube-video';
import BlogAnchorsBlock from '../components/prismic-adapters/blog/prismic-blog-anchors-block';
import BlogAnchor from '../components/prismic-adapters/blog/prismic-blog-anchor';
import BlogSingleImage from '../components/prismic-adapters/blog/prismic-blog-single-image';
import BlogNewsletterSignup from '../components/prismic-adapters/blog/prismic-blog-newsletter-signup';
import BlogServicesBlock from '../components/prismic-adapters/blog/prismic-blog-services-block';
import TwoImageOffsetBlock from '../components/prismic-adapters/blog/prismic-two-image-offset';
import Divider from '../components/prismic-adapters/blog/prismic-blog-divider';
import BlogQuote from '../components/prismic-adapters/blog/prismic-blog-quote';
import BlogCta from '../components/prismic-adapters/blog/prismic-blog-cta';
import BlogHtml from '../components/prismic-adapters/blog/prismic-blog-html';

function Empty() {
  return null;
}

export const SLICE_MAPPINGS = {
  blank_space: BlankSpace,
  custom_html: CustomHtml,
  expertise_header: ExpertiseHeader,
  what_we_do: WhatWeDo,
  left_text_right_image: LeftTextRightImage,
  right_text_left_image: RightTextLeftImage,
  case_study_full_width_splash: CaseStudyFullWidthSplash,
  awards_block: AwardsBlock,
  footer_preface: FooterPreface,
  sub_page_header: SubPageHeader,
  review: Review,
  background_left_text_right_image_: BackgroundLeftTextRightImage,
  proccess_block: ProcessBlock,
  rectangle_splash_block: RectangleSplash,
  faqs: Faq,
  case_study_splash: CaseStudySplash,
  stats_block: StatsBlock,
  gradient_stat_block: GradientStatBlock,
  left_subtitle_right_text: LeftSubtitleRightText,
  right_links_left_subtitle: RightLinksLeftSubtitle,
  team_block: TeamBlock,
  cta_card: CtaCard,
  text_cta: CtaText,
  expertise_header1: ExpertiseHeaderTwo,
  services_list_expanded: ServicesListExpanded,
  case_studies_slider: CaseStudiesSlider,
  anchor: Anchor,
  left_text: LeftText,
  right_text: RightText,
  homepage_header: HomepageHeader,
  right_two_image_left_text: RightTwoImageLeftText,
  right_two_text_left_image: RightTwoTextLeftImage,
  client_list: ClientList,
  full_width_image_header: FullWidthImageHeader,
  video_showcase: VideoShowcase,
  process: OurProcess,
  standard_text: StandardText,
  dynamic_case_study_one: DynamicCaseStudyOne,
  smaller_services_list: SmallerServicesList,
  case_study_header_1: CaseStudyHeaderOne,
  project_brief: ProjectBrief,
  typing_text: TypingText,
  contact_form: ContactForm,
  our_growth_team: OurGrowthTeam,
  what_happens_next: WhatHappensNext,
  recognition_splash: RecognitionSplash,
  review_2: ReviewTwo,
  google_review_slider: GoogleReviewSlider,
  large_review_slider: LargeReviewSlider,
  review_slider: ReviewSlider,
  case_study_header_2: CaseStudyHeaderTwo,
  meet_the_team: MeetTheTeam,
  vacancies: Vacancies,
  flexible_cards: FlexibleCards,
  clients_block: ClientsBlock,
  social_images_block: SocialImagesBlock,
  two_image_header: TwoImageHeader,
  proposition: Proposition,
  stand_out_block: StandOutBlock,
  meet_our_team_splash: MeetOurTeamSplash,
  awards_no_title: AwardsNoTitle,
  award_icons: AwardIcons,
  stats_block_reversed: StatsBlockReversed,
  image_gallery: ImageGallery,
};

export const CASE_STUDY_SLICE_MAPPINGS = {
  blank_space: BlankSpace,
  custom_html: CustomHtml,
  expertise_header: ExpertiseHeader,
  what_we_do: WhatWeDo,
  left_text_right_image: LeftTextRightImage,
  right_text_left_image: RightTextLeftImage,
  case_study_full_width_splash: CaseStudyFullWidthSplash,
  awards_block: AwardsBlock,
  footer_preface: FooterPreface,
  sub_page_header: SubPageHeader,
  review: Review,
  background_left_text_right_image_: BackgroundLeftTextRightImage,
  proccess_block: ProcessBlock,
  rectangle_splash_block: RectangleSplash,
  faqs: Faq,
  case_study_splash: CaseStudySplash,
  stats_block: StatsBlock,
  gradient_stat_block: GradientStatBlock,
  left_subtitle_right_text: LeftSubtitleRightText,
  right_links_left_subtitle: RightLinksLeftSubtitle,
  team_block: TeamBlock,
  cta_card: CtaCard,
  text_cta: CtaText,
  expertise_header1: ExpertiseHeaderTwo,
  services_list_expanded: ServicesListExpanded,
  case_studies_slider: CaseStudiesSlider,
  anchor: Anchor,
  left_text: LeftText,
  right_text: RightText,
  homepage_header: HomepageHeader,
  right_two_image_left_text: RightTwoImageLeftText,
  right_two_text_left_image: RightTwoTextLeftImage,
  client_list: ClientList,
  smaller_services_list: SmallerServicesList,
  case_study_header_1: CaseStudyHeaderOne,
  project_brief: ProjectBrief,
  typing_text: TypingText,
};

export const BLOG_SLICE_MAPPINGS = {
  heading: BlogHeading,
  paragraph_text: BlogParagraphText,
  youtube_video: BlogYoutubeVideo,
  anchors_block: BlogAnchorsBlock,
  anchor: BlogAnchor,
  single_image: BlogSingleImage,
  newsletter_signup_form: BlogNewsletterSignup,
  services_block: BlogServicesBlock,
  two_image_offset_block: TwoImageOffsetBlock,
  divider: Divider,
  quote: BlogQuote,
  cta: BlogCta,
  custom_html: BlogHtml,
  blank_space: BlankSpace,
  left_text_right_image: LeftTextRightImage,
  right_text_left_image: RightTextLeftImage,
  stand_out_block: StandOutBlock,
};

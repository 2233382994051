import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import ReactSwiper from '../components/swiper';
import '../utils/Carousel.css';
import SliderArrow from '../images/assets/sliderArrow.svg';
import SliderArrowBlack from '../images/assets/sliderArrowBlack.svg';
import PlayIcon from '../images/assets/play-icon.svg';

const VideoShowcaseWrapper = styled.div`
  overflow: hidden;
`;

const VideoShowcaseHolder = styled.div`
  background: #131313;
  padding: 80px 30px 40px;
  margin-top: 30px;
  margin-bottom: 30px;
  position: relative;

  video {
    pointer-events: none;
  }
`;

const VideoShowcaseHolderBefore = styled.div`
  background: #131313;
  width: 100vw;
  left: calc(-50vw + 50%);
  height: 100%;
  position: absolute;
  top: 0;
  display: block;
  z-index: -1;
`;

const VideoShowcaseItem = styled.div`
  padding: 30px;
  position: relative;
  height: 0;
  padding-bottom: 150%;

  img,
  video {
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }
`;

const SWIPER_OPTIONS = {
  slidesPerView: 1.16,
  spaceBetween: 30,
  centeredSlides: true,
  speed: 300,
  swipe: false,
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  breakpoints: {
    768: {
      slidesPerView: 2.5,
    },
    1024: {
      slidesPerView: 2,
    },
    1500: {
      slidesPerView: 3.16,
    },
  },
};

const ArrowContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 40px;
  // pointer-events: none;
`;

const ArrowsContainer = styled.div`
  display: flex;

  svg path {
    stroke: #fff !important;
  }
`;

const VideoHolder = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;

  &.playing {
    .play-icon {
      display: none;
    }
  }
`;

const VideoPlayIcon = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 1000px;
  pointer-events: none;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: 3px;
    transform: translate(-50%, -50%);
    width: 20px;
    height: auto;
  }
`;

const ArrowContainer = styled.div`
  height: 42px;
  width: 42px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
`;

const ArrowContainerReversed = styled.div`
  height: 42px;
  width: 42px;
  transform: rotate(180deg);
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
`;

// Create a VideoItem component
class VideoItem extends Component {
  constructor(props) {
    super(props);
    this.videoRef = React.createRef();
    this.state = {
      videoPlaying: false,
    };
  }

  handleVideoClick = () => {
    const video = this.videoRef.current;

    if (video) {
      const parentElement = video.parentNode;

      this.setState(prevState => ({
        videoPlaying: !prevState.videoPlaying,
      }));

      if (!video.paused) {
        video.pause();
        video.currentTime = 0;
        parentElement.classList.remove('playing');
      } else {
        video.play();
        parentElement.classList.add('playing');
      }
    }
  };

  render() {
    const { item } = this.props;

    return (
      <VideoHolder onClick={this.handleVideoClick}>
        <video
          ref={this.videoRef}
          loop
          playsInline
          controls={this.state.videoPlaying}
          autoPlay={this.state.videoPlaying && !item.video.url.includes('.mp4')}
        >
          <source src={item.video.url} type="video/mp4" />
        </video>
        <VideoPlayIcon className="play-icon">
          <PlayIcon />
        </VideoPlayIcon>
      </VideoHolder>
    );
  }
}

// Your VideoShowcase component
export class VideoShowcase extends Component {
  constructor(props) {
    super(props);
    this.swiperRef = React.createRef();
  }

  componentDidMount() {
    // Add a slide change event listener to the Swiper component
    if (this.swiperRef.current) {
      this.swiperRef.current.on('slideChange', () => {
        // Pause the videos in all slides
        const allVideos = document.querySelectorAll('video');
        allVideos.forEach(video => {
          video.pause();
          video.currentTime = 0;
          video.parentNode.classList.remove('playing');
        });

        // Play the video in the currently active slide
        const activeSlide = document.querySelector('.swiper-slide-active');
        const videoInActiveSlide = activeSlide.querySelector('video');
        if (videoInActiveSlide) {
          videoInActiveSlide.play();
          videoInActiveSlide.parentNode.classList.add('playing');
        }
      });
    }
  }

  render() {
    return (
      <VideoShowcaseWrapper>
        <VideoShowcaseHolder>
          <VideoShowcaseHolderBefore />
          <ReactSwiper
            id="video-showcase"
            ref={this.swiperRef}
            options={SWIPER_OPTIONS}
            onSlideChange={() => console.log('slide change')}
          >
            {this.props.items &&
              this.props.items.map((item, index) => (
                <ReactSwiper.Item key={index}>
                  <VideoShowcaseItem>
                    {item.video &&
                    item.video.url &&
                    item.video.url.includes('.mp4') ? (
                      <VideoItem item={item} />
                    ) : (
                      <img src={item.image.url} alt={`Image ${index}`} />
                    )}
                  </VideoShowcaseItem>
                </ReactSwiper.Item>
              ))}
          </ReactSwiper>
          <ArrowContainerOuter>
            <ArrowsContainer>
              <ArrowContainer>
                <SliderArrowBlack class="swiper-button-prev"></SliderArrowBlack>
              </ArrowContainer>
              <ArrowContainerReversed>
                <SliderArrowBlack class="swiper-button-next"></SliderArrowBlack>
              </ArrowContainerReversed>
            </ArrowsContainer>
          </ArrowContainerOuter>
        </VideoShowcaseHolder>
      </VideoShowcaseWrapper>
    );
  }
}
export default VideoShowcase;

import React from 'react';

const css = `
  .svgElements {
    fill-opacity: 1;
    position: absolute;
    bottom: 3rem;
    left: 0;
    max-width: 100px;
    z-index: 9; 
  }  
  
  @media (max-width: 965px) {
    .svgElements {
    max-width: 80px;
    opacity: 0.1;
  }
}
@media (max-width: 735px) {
    .svgElements {  
  opacity: 0.1;
  z-index: 1;
}
}
`;

const whnBGLeft = props => {
  return (
    <svg
      width="68"
      height="150"
      viewBox="0 0 68 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="svgElements"
    >
      <defs>
        <style>{css}</style>
      </defs>
      <path
        d="M-2.26402 139.387C16.3194 126.838 38.588 116.551 54.3251 100.059C63.9069 90.0185 53.8871 85.5247 43.6852 84.6226C12.438 81.8596 -18.2327 95.9536 -48.7696 100.073C-61.7894 101.829 -51.2317 93.7027 -46.26 89.0646C-35.5256 79.0507 -24.0312 70.1867 -12.4769 61.2589C0.792226 51.0061 17.0302 40.8198 26.7173 26.3305C36.9613 11.0083 -5.22498 10.2758 -10.5872 10.3058C-26.9566 10.3973 -43.1408 13.2394 -59.0591 16.6854C-70.7847 19.2238 -76.6589 20.8418 -87.7083 23.9413"
        stroke={props.color}
        strokeWidth="19.7125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default whnBGLeft;

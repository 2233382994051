import React from 'react';
import { graphql } from 'gatsby';

import ExpertiseHeaderTwo from '../../blocks/expertiseHeaderTwo';
import urlParser from '../../utils/urlParser';

function ExpertiseHeaderTwoPrismic({
  header_text,
  subtitle_text,
  cta_text,
  cta_link,
  theme_color,
  cta_text_color,
  left_aligned_image,
  right_aligned_image,
}) {
  return (
    <ExpertiseHeaderTwo
      header_text={header_text}
      subtitle_text={subtitle_text.html}
      cta_text={cta_text.text}
      cta_link={urlParser(cta_link)}
      cta_target={cta_link.target}
      theme_color={theme_color}
      cta_text_color={cta_text_color}
      left_aligned_image={left_aligned_image.url}
      right_aligned_image={right_aligned_image.url}
    />
  );
}

export const query = graphql`
  fragment ExpertiseHeaderTwo on PrismicPageBodyExpertiseHeader1 {
    id
    slice_type
    primary {
      header_text {
        text
      }
      subtitle_text {
        html
      }
      cta_text {
        text
      }
      cta_link {
        url
        slug
        target
      }
      cta_text_color
      theme_color
      left_aligned_image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      right_aligned_image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default ExpertiseHeaderTwoPrismic;

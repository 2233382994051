import React from 'react';
import styled from '@emotion/styled';

//Component Import
import Label from '../../components/Label';
import RightImageLeftContent from './leftImageRightContent';
import LeftImageRightContent from './rightImageLeftContent';
import BlankSpace from '../../components/blankSpace';
import Squiggle from '../../components/squiggle';

import convertHtml from '../../utils/convertHtml';

const ProccessBlockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const Container = styled.div`
  width: 60%;
  max-width: 1280px;
  @media (max-width: 1325px) {
    width: 75%;
  }
  @media (max-width: 1000px) {
    width: 90%;
  }
`;

const HeadingContent = styled.div``;

const Title = styled.h2`
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  margin-top: 30px;
  margin-bottom: 150px;
  font-style: normal;
  font-family: Montserrat;
  @media (max-width: 735px) {
    margin-bottom: 50px;
  }
`;

const ProccessItemsContainer = styled.div``;

const SquiggleContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin-right: -175px;
`;

const SquiggleContainerTwo = styled.div`
  position: absolute;
  left: 0;
  bottom: 50%;
  margin-left: -150px;
  @media (max-width: 800px) {
    display: none;
  }
`;

const ProccessBlock = ({ props, title, label_title, color_theme, items }) => (
  <ProccessBlockContainer>
    <Container>
      <HeadingContent>
        <Label text={label_title} color={color_theme} />
        <Title dangerouslySetInnerHTML={convertHtml(title)}></Title>
      </HeadingContent>
      <ProccessItemsContainer>
        {items.map((item, index) => (
          <React.Fragment key={index}>
            {index % 2 ? (
              <LeftImageRightContent
                key={index}
                title={item.block_title.text}
                paragraph={item.block_paragraph.text}
                image={item.block_image.url}
                number={index + 1}
                length={items.length}
                color_theme={color_theme}
                displayLine={index === 0 ? false : true}
              />
            ) : (
              <RightImageLeftContent
                key={index}
                title={item.block_title.text}
                paragraph={item.block_paragraph.text}
                image={item.block_image.url}
                number={index + 1}
                length={items.length}
                color_theme={color_theme}
                displayLine={index === 0 ? false : true}
              />
            )}
          </React.Fragment>
        ))}
      </ProccessItemsContainer>
      <BlankSpace height={'200px'} mobileHeight={'50px'} />
    </Container>
  </ProccessBlockContainer>
);

export default ProccessBlock;

import React from 'react';
import { graphql } from 'gatsby';

import ExpertiseHeader from '../../blocks/expertiseHeader';
import urlParser from '../../utils/urlParser';

function PrismicExpertiseHeader({
  title,
  title_underline_colour1,
  subtitle_text,
  call_to_action_text,
  call_to_action_text_colour,
  call_to_action_arrow_colour,
  hero_image,
  cta_link,
  squiggle_color,
}) {
  return (
    <ExpertiseHeader
      title={title.text}
      title_underline_color={title_underline_colour1}
      subtitle_text={subtitle_text.html}
      call_to_action_text={call_to_action_text.text}
      call_to_action_text_colour={call_to_action_text_colour}
      call_to_action_arrow_colour={call_to_action_arrow_colour}
      hero_image={hero_image.url}
      cta_link={cta_link}
      cta_link_target={cta_link.target}
      squiggle_color={squiggle_color}
    />
  );
}

export const query = graphql`
  fragment ExpertiseHeader on PrismicPageBodyExpertiseHeader {
    id
    slice_type
    primary {
      title {
        text
      }
      title_underline_colour1
      subtitle_text {
        html
      }
      call_to_action_text {
        text
      }
      cta_link {
        url
        slug
        target
      }
      call_to_action_text_colour
      call_to_action_arrow_colour
      squiggle_color
      hero_image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default PrismicExpertiseHeader;

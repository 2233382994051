import React from 'react';
import { graphql } from 'gatsby';

import BlogSingleImage from '../../../blocks/blog/blogSingleImage';

function BlogSingleImagePrismic({
  single_image,
  image_size,
  dimensions,
  custom_desktop_width,
  custom_desktop_height,
  custom_tablet_width,
  custom_tablet_height,
  custom_phone_height,
  custom_phone_width,
  ...rest
}) {
  return <BlogSingleImage single_image={single_image} {...rest} />;
}

export const query = graphql`
  fragment BlogSingleImage on PrismicBlogBodySingleImage {
    id
    slice_type
    primary {
      single_image {
        alt
        url
        dimensions {
          height
          width
        }
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default BlogSingleImagePrismic;

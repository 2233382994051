import React from 'react';
import styled from '@emotion/styled';

//Component Import
import ButtonNotUnderlined from '../components/ButtonNotUnderlined';
import linkStripper from '../utils/link-stripper';
import convertHtml from '../utils/convertHtml';

const TextCtaContainer = styled.div`
  width: 100%;

  overflow: hidden;
  padding: 30px;
  max-width: 1080px;
  margin: auto;
  text-align: center;
  box-sizing: border-box;
`;
const TextCtaParagraph = styled.div`
  width: 100%;
  min-width: 100%;
  display: block;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const TextCtaContainerInner = styled.div``;

const ButtonNotUnderlinedContainer = styled.div`
  div {
    display: inline-block;
  }
`;

const TextCta = ({
  cta_text,
  cta_theme,
  cta_link,
  cta_link_target,
  paragraph_text,
}) => (
  <TextCtaContainer>
    <TextCtaContainerInner>
      <TextCtaParagraph
        dangerouslySetInnerHTML={convertHtml(paragraph_text.html)}
      />
      <ButtonNotUnderlinedContainer>
        {cta_link.includes('.pdf') ? (
          <ButtonNotUnderlined
            buttonText={cta_text}
            color={cta_theme}
            link={cta_link}
            target={cta_link_target}
          />
        ) : (
          <ButtonNotUnderlined
            buttonText={cta_text}
            color={cta_theme}
            link={linkStripper(cta_link)}
            target={cta_link_target}
          />
        )}
      </ButtonNotUnderlinedContainer>
    </TextCtaContainerInner>
  </TextCtaContainer>
);

export default TextCta;

import React from 'react';
import { graphql } from 'gatsby';

import BlogNewsletterSignup from '../../../blocks/blog/blogNewsletterSignup';

function BlogNewsletterSignupPrismic({ title, subtitle, blogData, ...rest }) {
  return (
    <div>
      <BlogNewsletterSignup
        title={title.text}
        subtitle={subtitle.text}
        blogData={blogData}
        {...rest}
      />
    </div>
  );
}

export const query = graphql`
  fragment BlogNewsletterSignup on PrismicBlogBodyNewsletterSignupForm {
    id
    slice_type
    primary {
      title {
        text
      }
      subtitle {
        text
      }
    }
  }
`;

export default BlogNewsletterSignupPrismic;

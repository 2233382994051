import React from 'react';
import { graphql } from 'gatsby';

import LeftText from '../../blocks/leftText';

function LeftTextPrismic({ label_text, label_color, title_text, text, image }) {
  return (
    <LeftText
      label_text={label_text.text}
      label_color={label_color}
      title_text={title_text.text}
      text={text.html}
      image={image}
    />
  );
}

export const query = graphql`
  fragment LeftText on PrismicPageBodyLeftText {
    id
    slice_type
    primary {
      label_text {
        text
      }
      label_color
      title_text {
        text
      }
      text {
        html
      }
      image {
        alt
        url
localFile {
          id
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default LeftText;

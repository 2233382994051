import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';

//Component Import
import ButtonNotUnderlined from '../components/ButtonNotUnderlined';
import linkStripper from '../utils/link-stripper';

const TeamBlockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;
  height: 640px;
  @media (max-width: 1050px) {
    height: 520px;
  }
  @media (max-width: 855px) {
    flex-wrap: wrap;
    height: fit-content;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
`;

const LeftContent = styled.div`
  width: 50%;
  display: flex;
  align-items: flex-start;
  height: 100%;
  justify-content: flex-end;
  position: relative;
  @media (max-width: 1200px) {
    width: 800px;
  }

  @media (max-width: 855px) {
    width: 100%;
  }
`;

const LeftContentInner = styled.div`
  width: fit-content;
  margin-right: 150px;
  @media (max-width: 1200px) {
    margin-right: 0px;
  }

  @media (max-width: 1000px) {
    margin-right: 50px;
  }

  @media (max-width: 855px) {
    width: 100%;
    margin-right: 0px;
  }
`;

const RightContent = styled.div`
  width: 50%;
  @media (max-width: 1200px) {
    width: fill-available;
  }

  @media (max-width: 855px) {
    width: 100%;
  }
`;

const ImageContainer = styled(motion.div)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-width: 700px;
  @media (max-width: 1200px) {
    justify-content: center;
  }
  @media (max-width: 855px) {
    margin-top: 25px;
  }
`;

const ImageItem = styled(motion.div)`
  width: 50%;
  max-width: 240px;
  margin-bottom: 50px;
  margin-right: 50px;
  @media (max-width: 1200px) {
    margin-right: 25px;
    margin-left: 25px;
  }
  @media (max-width: 1050px) {
    margin-bottom: 20px;
    margin-right: 10px;
    margin-left: 10px;
    max-width: 200px;
  }
  @media (max-width: 855px) {
    margin-left: 25px;
    margin-right: 25px;
  }
  @media (max-width: 400px) {
    margin: 0;
    max-width: unset;
    width: calc(50% - 25px);
    padding: 12.5px;
  }
`;

const TeamImage = styled(motion.img)`
  width: 100%;
`;

const Subtitle = styled.div`
  font-size: 24px;
  line-height: 36px;
  font-weight: 800;
  text-align: right;
  max-width: 250px;
  font-family: Montserrat;
  font-style: normal;
  color: #ffffff;
  @media (max-width: 855px) {
    text-align: left;
  }
`;

const SubtitleText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: right;
  max-width: 250px;
  color: #ffffff;
  @media (max-width: 855px) {
    text-align: left;
  }
`;

const CTAContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  margin-right: 150px;

  @media (max-width: 1200px) {
    margin-right: 0px;
  }

  @media (max-width: 1000px) {
    margin-right: 50px;
  }

  @media (max-width: 855px) {
    position: relative;

    margin-top: 5px;
  }
`;

const ImageContainerGrid = styled(motion.ul)`
  max-width: 700px;
  display: grid;
  overflow: hidden;
  margin: 0;
  list-style: none;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 15px;
  padding: 15px;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50px;
`;

const container = {
  hidden: { opacity: 0, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export class TeamBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <TeamBlockContainer>
          <LeftContent>
            <LeftContentInner>
              <Subtitle>{this.props.subtitle_header}</Subtitle>
              <SubtitleText>{this.props.subtitle_text}</SubtitleText>
              <CTAContainer>
                <ButtonNotUnderlined
                  buttonText={this.props.cta_title}
                  link={linkStripper(this.props.cta_link)}
                  color={this.props.cta_theme}
                  arrowColor={this.props.cta_theme}
                  target={this.props.cta_link_target}
                />
              </CTAContainer>
            </LeftContentInner>
          </LeftContent>
          <RightContent>
            <ImageContainer
              variants={container}
              initial="hidden"
              animate={this.state.visible ? 'visible' : 'hidden'}
            >
              <ImageItem variants={item} key="0">
                <TeamImage
                  src={this.props.image_1}
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: '0.4' }}
                />
              </ImageItem>
              <ImageItem variants={item} key="1">
                <TeamImage
                  src={this.props.image_2}
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: '0.4' }}
                />
              </ImageItem>
              <ImageItem variants={item} key="2">
                <TeamImage
                  src={this.props.image_3}
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: '0.4' }}
                />
              </ImageItem>
              <ImageItem variants={item} key="3">
                <TeamImage
                  src={this.props.image_4}
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: '0.4' }}
                />
              </ImageItem>
            </ImageContainer>
          </RightContent>
        </TeamBlockContainer>
      </VisibilitySensor>
    );
  }
}

export default TeamBlock;

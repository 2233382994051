export const initialState = {
  name: '',
  email: '',
  phone: '',
  reason: '',
  message: '',
  isnameInvalid: true,
  isemailInvalid: true,
  isphoneInvalid: true,
  ismessageInvalid: true,
  isreasonInvalid: true,
  isnameTouched: false,
  isemailTouched: false,
  isphoneTouched: false,
  isreasonTouched: false,
  ismessageTouched: false,
  disabled: true,
  optionData: [
    { value: 'reason 1', label: 'Reason For Contacting-1' },
    { value: 'reason 2', label: 'Reason For Contacting-2' },
  ],
  isSuccess: false,
  error: '',
  isLoading: false,
  active: false,
  visible: false,
  visibilitySensorActive: true,
};

const stateReducer = (state, action) => {
  switch (action.type) {
    case 'setFormValue':
      return {
        ...state,
        [action.fieldName]: action.value,
      };
    case 'setFormValidation':
      return {
        ...state,
        [`is${action.name}Invalid`]: !action.value,
        disabled: action.disabled,
      };
    case 'setFieldStatus':
      return {
        ...state,
        [`is${action.name}Touched`]: action.value,
      };
    case 'sendMessageLoading':
      return {
        ...state,
        isLoading: action.value,
      };
    case 'setSendMessageResponse':
      let stateToPersist = { ...state };
      if (action.isSuccess) {
        stateToPersist = { ...initialState };
      }
      return {
        ...stateToPersist,
        isSuccess: action.isSuccess || initialState.isSuccess,
        error: action.error || initialState.error,
      };
    case 'handleVisibilityChange':
      return {
        ...state,
        visible: action.visible,
        visibilitySensorActive: action.visibilitySensorActive,
      };
    default:
      break;
  }
};

export default stateReducer;

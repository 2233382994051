import React from 'react';
import { graphql } from 'gatsby';

import GoogleReviewSlider from '../../blocks/googleReviewSlider';

function GoogleReviewSliderPrismic({
  block_color_theme,
  label_text,
  subtitle_text,
  star_count,
  review_count,
  google_logo,
  title_text,
  items,
}) {
  return (
    <GoogleReviewSlider
      items={items}
      block_color_theme={block_color_theme}
      title_text={title_text}
      label_text={label_text}
      subtitle_text={subtitle_text}
      star_count={star_count}
      review_count={review_count}
      google_logo={google_logo}
    />
  );
}

export const query = graphql`
  fragment GoogleReviewSlider on PrismicPageBodyGoogleReviewSlider {
    id
    slice_type
    primary {
      block_color_theme
      label_text {
        text
      }
      title_text {
        text
      }
      subtitle_text {
        text
      }
      star_count
      review_count {
        text
      }
      google_logo {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
    items {
      review_text {
        text
      }
      review_author {
        text
      }
    }
  }
`;

export default GoogleReviewSlider;

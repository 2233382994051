import React from 'react';
import { graphql } from 'gatsby';

import CaseStudySplash from '../../blocks/caseStudySplash';
import urlParser from '../../utils/urlParser';

function CaseStudySplashPrismic({
  page_background_color,
  tags,
  title,
  title_text_color,
  subtitle,
  subtitle_text_color,
  cta_text,
  cta_link,
  label_text,
  label_color,
  label_text_color,
  header_image,
  paragraph_title,
  paragraph_text,
  paragraph_title_color,
  paragraph_cta_text,
  paragraph_cta_link,
  paragraph_cta_color,
}) {
  return (
    <CaseStudySplash
      page_background_color={page_background_color}
      tags={tags.text}
      title={title.text}
      title_text_color={title_text_color}
      subtitle={subtitle.text}
      subtitle_text_color={subtitle_text_color}
      cta_text={cta_text.text}
      cta_link={cta_link.url}
      cta_link_target={cta_link.target}
      label_text={label_text.text}
      label_color={label_color}
      label_text_color={label_text_color}
      header_image={header_image.url}
      paragraph_title={paragraph_title.text}
      paragraph_text={paragraph_text.text}
      paragraph_title_color={paragraph_title_color}
      paragraph_cta_text={paragraph_cta_text.text}
      paragraph_cta_link={paragraph_cta_link.url}
      paragraph_cta_link_target={paragraph_cta_link.target}
      paragraph_cta_color={paragraph_cta_color}
    />
  );
}

export const query = graphql`
  fragment CaseStudySplash on PrismicPageBodyCaseStudySplash {
    id
    slice_type
    primary {
      page_background_color
      tags {
        text
      }
      title {
        text
      }
      title_text_color
      subtitle {
        text
      }
      subtitle_text_color
      cta_text {
        text
      }
      cta_link {
        url
        slug
        target
      }
      label_text {
        text
      }
      label_color
      label_text_color
      header_image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      paragraph_title {
        text
      }
      paragraph_title_color
      paragraph_text {
        text
      }
      paragraph_text_color
      paragraph_cta_text {
        text
      }
      paragraph_cta_link {
        url
        slug
        target
      }
      paragraph_cta_color
    }
  }
`;

export default CaseStudySplashPrismic;

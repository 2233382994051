import React, { Component } from 'react';
import styled from '@emotion/styled';
import convertHtml from '../../utils/convertHtml';

const BlogQuoteContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
const BlogQuoteContainerInner = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 50px auto;
  @media (max-width: 1150px) {
    width: 100%;
    margin: 50px 50px;
  }
  @media (max-width: 768px) {
    margin: 30px 20px;
  }
`;
const BlogQuoteContainer = styled.div`
  width: 100%;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 180%;
  color: #ffffff;

  em {
    font-style: normal;
  }
  strong {
    font-weight: bold;
  }
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  a {
    cursor: pointer;
  }
  @media (max-width: 1150px) {
    width: 90%;
  }
  @media (max-width: 750px) {
    width: 100%;
  }
`;

const Quote = styled.div`
  p {
    color: #ff22ee;
    color: ${props => props.themeColor};
    font-size: 1.2em;
  }
`;
export class BlogQuote extends Component {
  render() {
    return (
      <BlogQuoteContainerOuter>
        <BlogQuoteContainerInner>
          <BlogQuoteContainer>
            <Quote
              themeColor={this.props.customData.post_color_theme}
              dangerouslySetInnerHTML={convertHtml(
                this.props.quote_text &&
                  (this.props.quote_text.html || this.props.quote_text.text)
              )}
            />
          </BlogQuoteContainer>
        </BlogQuoteContainerInner>
      </BlogQuoteContainerOuter>
    );
  }
}

export default BlogQuote;

import React, { Component } from 'react';
import styled from '@emotion/styled';
import convertHtml from '../utils/convertHtml';

const ProcessWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 80px 0;

  p {
    line-height: 1.6;

    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }
`;

const ProcessWrapperLeft = styled.div`
  width: 50%;
  padding: 50px 60px 0 10%;
  box-sizing: border-box;
  position: sticky;
  top: 0;

  @media only screen and (max-width: 80em) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media only screen and (max-width: 56.25em) {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    position: static;
    margin-bottom: 50px;
  }
`;
const ProcessWrapperRight = styled.div`
  width: 50%;
  padding: 50px 100px;
  box-sizing: border-box;
  background-color: #131313;

  @media only screen and (max-width: 80em) {
    padding-left: 50px;
    padding-right: 50px;
  }

  @media only screen and (max-width: 56.25em) {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const MainTitle = styled.div`
  font-family: 'Montserrat';
  font-weight: 800;
  font-size: 32px;
  line-height: 1.5;
  margin-bottom: 20px;
`;

const MainText = styled.div``;

const ProcessItems = styled.div``;

const ProcessItem = styled.div`
  &:not(:last-child) {
    margin-bottom: 80px;
  }
`;

const ItemTitle = styled.div`
  font-family: 'Montserrat';
  font-weight: 800;
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 20px;
`;

const ItemText = styled.div``;

const ItemNumber = styled.div`
  font-family: 'Montserrat';
  font-weight: 400;
  font-size: 32px;
  margin-bottom: 30px;
  display: table;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  border-radius: 100px;
  border: 1px solid #fff;
`;

export class OurProcess extends Component {
  render() {
    return (
      <ourProcess>
        <ProcessWrapper>
          <ProcessWrapperLeft>
            <MainTitle>{this.props.main_title.text}</MainTitle>
            <MainText
              dangerouslySetInnerHTML={convertHtml(this.props.main_text.html)}
            ></MainText>
          </ProcessWrapperLeft>
          <ProcessWrapperRight>
            <ProcessItems>
              {this.props.items &&
                this.props.items.map((item, index) => (
                  <ProcessItem key={index}>
                    <ItemNumber>{index + 1}</ItemNumber>
                    <ItemTitle>{item.title.text}</ItemTitle>
                    <ItemText
                      dangerouslySetInnerHTML={convertHtml(item.text.html)}
                    ></ItemText>
                  </ProcessItem>
                ))}
            </ProcessItems>
          </ProcessWrapperRight>
        </ProcessWrapper>
      </ourProcess>
    );
  }
}
export default OurProcess;

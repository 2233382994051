import React from 'react';
import { graphql } from 'gatsby';

import RightTextLeftImage from '../../blocks/rightTextLeftImage';

function RightTextLeftImagePrismic({
  label_text,
  label_colour,
  title_text,
  paragraph_text,
  left_aligned_image,
  image_height,
}) {
  return (
    <RightTextLeftImage
      label_text={label_text.text}
      label_colour={label_colour}
      title_text={title_text.text}
      paragraph_text={paragraph_text.html}
      left_aligned_image={left_aligned_image}
    />
  );
}

export const query = graphql`
  fragment RightTextLeftImage on PrismicPageBodyRightTextLeftImage {
    id
    slice_type
    primary {
      label_text {
        text
      }
      label_colour
      title_text {
        text
      }
      paragraph_text {
        html
      }

      left_aligned_image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default RightTextLeftImagePrismic;

import React from 'react';
import { graphql } from 'gatsby';

import MeetOurTeamSplash from '../../blocks/meetOurTeam';

function MeetOurTeamSplashPrismic({
  block_color_theme,
  title_text,
  subtitle_text,
  cta_text,
  cta_link,
  image,
  cta_text_color,
  cta_arrow_color,
}) {
  return (
    <MeetOurTeamSplash
      block_color_theme={block_color_theme}
      title_text={title_text.text}
      subtitle_text={subtitle_text.text}
      cta_text={cta_text.text}
      cta_link={cta_link.url}
      cta_link_target={cta_link.target}
      image={image}
      cta_text_color={cta_text_color}
      cta_arrow_color={cta_arrow_color}
    />
  );
}

export const query = graphql`
  fragment MeetOurTeamSplash on PrismicPageBodyMeetOurTeamSplash {
    id
    slice_type
    primary {
      block_color_theme
      title_text {
        text
      }
      subtitle_text {
        text
      }
      cta_text {
        text
      }
      cta_link {
        url
        target
      }
      cta_text_color
      cta_arrow_color
      image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default MeetOurTeamSplash;

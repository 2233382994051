import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import Label from '../../components/Label';
import convertHtml from '../../utils/convertHtml';
import ButtonNotUnderlined from '../../components/ButtonNotUnderlined';

const BlogServicesBlockContainer = styled.div`
  width: 100%;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  padding-top: 115px;
  padding-bottom: 115px;
  margin-top: 50px;
  margin-bottom: 50px;
  @media (max-width: 1200px) {
    margin-top: 25px;
    margin-bottom: 25px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const BlogServicesBlockContainerInner = styled.div`
  width: 70%;
  max-width: 1440px;
  height: 100%;
  @media (max-width: 1200px) {
    width: 90%;
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
  margin-top: 20px;
  @media (max-width: 750px) {
    max-width: 100%;
    font-size: 28px;
    line-height: 34px;
  }
`;

const Subtitle = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  letter-spacing: 0.01em;
  color: #ffffff;
  max-width: 550px;
  margin-top: 20px;
  max-width: 700px;
  @media (max-width: 750px) {
    max-width: 100%;
    font-size: 16px;
    line-height: 26px;
  }
`;

const ParagraphContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  @media (max-width: 750px) {
    flex-wrap: wrap;
  }
`;

const LeftParagraph = styled.div`
  width: 50%;
  @media (max-width: 1250px) {
    padding-right: 25px;
    margin-right: 25px;
  }
  @media (max-width: 750px) {
    width: 100%;
    padding: 0;
    margin: 0;
  }
`;

const RightParagraph = styled.div`
  width: 50%;
  @media (max-width: 750px) {
    width: 100%;
    padding: 0;
    margin: 0;
  }
`;

const ParagraphTitle = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  color: #ffffff;
  max-width: 450px;
  margin-top: 50px;
  @media (max-width: 750px) {
    max-width: 100%;
    margin-top: 40px;
    font-size: 18px;
  }
`;

const ParagraphContent = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: #ffffff;
  max-width: 450px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 750px) {
    max-width: 100%;
    font-size: 14px;
    line-height: 22px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export class BlogServicesBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };
  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <BlogServicesBlockContainer>
          <BlogServicesBlockContainerInner>
            <Label color={this.props.color} text={this.props.label_text} />
            <Title dangerouslySetInnerHTML={convertHtml(this.props.title)} />
            <Subtitle
              dangerouslySetInnerHTML={convertHtml(this.props.subtitle)}
            />
            <ParagraphContainer>
              <LeftParagraph>
                <ParagraphTitle
                  dangerouslySetInnerHTML={convertHtml(
                    this.props.paragraph_one_title
                  )}
                />
                <ParagraphContent
                  dangerouslySetInnerHTML={convertHtml(
                    this.props.paragraph_one
                  )}
                />
                <ButtonNotUnderlined
                  buttonText={this.props.paragraph_one_cta_text}
                  link={this.props.paragraph_one_cta_link}
                  color={this.props.color}
                  arrowColor={this.props.color}
                  target={this.props.paragraph_one_cta_link_target}
                />
              </LeftParagraph>
              <RightParagraph>
                <ParagraphTitle
                  dangerouslySetInnerHTML={convertHtml(
                    this.props.paragraph_two_title
                  )}
                />
                <ParagraphContent
                  dangerouslySetInnerHTML={convertHtml(
                    this.props.paragraph_two
                  )}
                />
                <ButtonNotUnderlined
                  buttonText={this.props.paragraph_two_cta_text}
                  link={this.props.paragraph_two_cta_link}
                  color={this.props.color}
                  arrowColor={this.props.color}
                  target={this.props.paragraph_two_cta_link_target}
                />
              </RightParagraph>
            </ParagraphContainer>
          </BlogServicesBlockContainerInner>
        </BlogServicesBlockContainer>
      </VisibilitySensor>
    );
  }
}

export default BlogServicesBlock;

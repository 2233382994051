import React from 'react';
import { graphql } from 'gatsby';

import AwardsBlock from '../../blocks/awards';
import urlParser from '../../utils/urlParser';

function PrismicAwardsBlock({
  title,
  see_all_cta_title,
  scribble_colour_theme,
  awards_title,
  awards_paragraph,
  awards_colour_theme,
  image,
  award_name,
  award_outcome,
  items,
  see_all_cta_link,
  customData,
}) {
  return (
    <AwardsBlock
      title={title.text}
      see_all_cta_title={see_all_cta_title.text}
      scribble_colour_theme={scribble_colour_theme}
      awards_title={awards_title.text}
      awards_paragraph={awards_paragraph.text}
      awards_colour_theme={awards_colour_theme}
      image={image.url}
      award_name={award_name}
      award_outcome={award_outcome}
      see_all_cta_link={urlParser(see_all_cta_link)}
      see_all_cta_link_target={see_all_cta_link.target}
      items={items}
      pageData={customData.prismicPage.data}
    />
  );
}

export const query = graphql`
  fragment AwardsBlock on PrismicPageBodyAwardsBlock {
    id
    slice_type
    primary {
      title {
        text
      }
      see_all_cta_title {
        text
      }
      see_all_cta_link {
        url
        slug
        target
      }
      scribble_colour_theme
      awards_title {
        text
      }
      awards_paragraph {
        text
      }
      awards_colour_theme
      image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
    items {
      award_name {
        text
      }
      award_outcome {
        text
      }
    }
  }
`;

export default PrismicAwardsBlock;

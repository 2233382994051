import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

const FullWidthImageHeaderContainer = styled.div`
  position: relative;
  width: 100vw;
  margin-left: calc((100% - 100vw) / 2);
  min-height: 100vh;
  display: flex;
  align-items: center;
  margin-top: -120px;

  @media only screen and (max-width: 45.9375em) {
    margin-top: -20px;
  }
  @media only screen and (max-width: 28.125em) {
    margin-top: 0;
  }

  img,
  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

const FullWidthImageHeaderInner = styled.div`
  padding: 200px 30px;
  position: relative;
  z-index: 1;
  color: #fff;
  text-align: center;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;

  h1 {
    font-family: 'Montserrat';
    font-weight: 900;
    font-size: 65px;
    font-style: normal;
    line-height: 1.2;
    margin-bottom: 20px;

    @media only screen and (max-width: 48em) {
      font-size: 32px;
    }
  }
  p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 180%;

    @media only screen and (max-width: 48em) {
      font-size: 18px;
      line-height: 1.5;
    }
  }
`;

const FullWidthImageHeaderContainerOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #212121;
  opacity: 0.5;
`;

export class FullWidthImageHeader extends Component {
  render() {
    return (
      <FullWidthImageHeaderContainer className="FullWidthImageHeaderContainer">
        {this.props.video &&
        this.props.video.url &&
        this.props.video.url.includes('.mp4') ? (
          <video autoPlay loop playsInline muted>
            <source src={this.props.video.url} type="video/mp4" />
          </video>
        ) : (
          <img src={this.props.image.url} alt="Image" />
        )}
        {this.props.show_overlay ? (
          <FullWidthImageHeaderContainerOverlay />
        ) : null}
        <FullWidthImageHeaderInner>
          {this.props.title_text.text ? (
            <h1>{this.props.title_text.text}</h1>
          ) : null}
          {this.props.subtitle_text.text ? (
            <p>{this.props.subtitle_text.text}</p>
          ) : null}
        </FullWidthImageHeaderInner>
      </FullWidthImageHeaderContainer>
    );
  }
}

export default FullWidthImageHeader;

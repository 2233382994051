import React from 'react';
import { graphql } from 'gatsby';

import FlexibleCards from '../../blocks/flexibleCards';

function FlexibleCardsPrismic({ items, block_color_theme, block_title }) {
  return (
    <FlexibleCards
      items={items}
      block_title={block_title}
      block_color_theme={block_color_theme}
    />
  );
}

export const query = graphql`
  fragment FlexibleCards on PrismicPageBodyFlexibleCards {
    id
    slice_type
    primary {
      block_color_theme
      block_title {
        text
      }
    }
    items {
      card_background_color
      title_color
      subtext_color
      step_color
      step {
        text
      }
      title {
        text
      }
      subtitle {
        text
      }
    }
  }
`;

export default FlexibleCards;

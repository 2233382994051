import React from 'react';
import { graphql } from 'gatsby';

import AwardsNoTitle from '../../blocks/awardsNoTitle';

function AwardsNoTitlePrismic({
  items,
  block_color_theme,
  title_text,
  award_year,
  paragraph_text,
  image,
  customData,
}) {
  return (
    <AwardsNoTitle
      items={items}
      block_color_theme={block_color_theme}
      title_text={title_text.text}
      award_year={award_year.text}
      paragraph_text={paragraph_text.text}
      image={image}
    />
  );
}

export const query = graphql`
  fragment AwardsNoTitle on PrismicPageBodyAwardsNoTitle {
    id
    slice_type
    primary {
      block_color_theme
      award_year {
        text
      }
      title_text {
        text
      }
      paragraph_text {
        text
      }
      image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
    items {
      award_title {
        text
      }
      award_result {
        text
      }
    }
  }
`;

export default AwardsNoTitle;

import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export const GoogleReviewSliderContainer = styled.div`
    padding-top: 5rem;
    padding-bottom: 4rem;
`;
export const RattingCount = styled.span`
    font-weight: 600;
    font-size: 26px;
    margin-left: 12px;
    display: inline-block;
    vertical-align: middle;

    @media (max-width: 965px) {
        font-size: 21px;
    }

    @media (max-width: 735px) {
        font-size: 16px;
    }
`;

export const GoogleDiv = styled(motion.img)`
    max-width: 163px;
    width: 100%;
    margin: 48px auto;

    @media (max-width: 1199px) {
        margin: 36px auto;
    }
    @media (max-width: 965px) {
        max-width: 130px;
        margin: 27px auto;
    }
    @media (max-width: 735px) {
        max-width: 100px;
        margin: 20px auto;
    }
`;

import React from 'react';
import { graphql } from 'gatsby';

import Proposition from '../../blocks/proposition';

function PropositionPrismic({
  block_color_theme,
  label_text,
  title_text,
  subtitle_text,
}) {
  return (
    <Proposition
      block_color_theme={block_color_theme}
      label_text={label_text.text}
      title_text={title_text.text}
      subtitle_text={subtitle_text.text}
    />
  );
}

export const query = graphql`
  fragment Proposition on PrismicPageBodyProposition {
    id
    slice_type
    primary {
      block_color_theme
      label_text {
        text
      }
      title_text {
        text
      }
      subtitle_text {
        text
      }
    }
  }
`;

export default Proposition;

import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import LazyImage from '../../components/lazy-image';
import Squiggle from '../../images/squiggles/blogSquiggle.svg';
import { EmailJSResponseStatus } from 'emailjs-com';

const TwoImageOffsetContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const TwoImageOffsetContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1440px;
  height: calc(100% + 50px);
  justify-content: flex-end;
  position: relative;
  margin-bottom: 0px;
  margin-top: 40px;
  @media (max-width: 700px) {
    justify-content: center;
    margin-top: 0px;
  }
`;

const BackgroundBlockContainer = styled.div`
  height: 350px;
  width: 780px;
  z-index: 0;
  position: absolute;
  margin-top: 50px;
  @media (max-width: 1230px) {
    height: 400px;
  }
  @media (max-width: 900px) {
    height: 300px;
    width: 750px;
  }
  @media (max-width: 700px) {
    height: 100%;
    display: none;
  }
`;

const BackgroundBlock = styled(motion.div)`
  background-color: ${props => props.backgroundColor};
  height: 100%;
  width: 100%;
  position: relative;
  @media (max-width: 700px) {
    display: none;
  }
`;

const MainImage = styled(motion.div)`
  height: 300px;
  width: 450px;
  margin-right: 60px;
  z-index: 2;
  margin-bottom: 50px;
  @media (max-width: 1230px) {
    height: 200px;
    width: 400px;
  }
  @media (max-width: 900px) {
    height: 200px;
    width: 400px;
    margin-right: 15px;
  }
  @media (max-width: 700px) {
    width: 60%;
    height: auto;
    margin-right: 0px;
  }
`;

const SecondaryImage = styled(motion.div)`
  width: 210px;
  height: 300px;
  z-index: 2;
  @media (max-width: 1230px) {
    height: 200px;
  }
  @media (max-width: 900px) {
    height: 200px;
  }
  @media (max-width: 700px) {
    width: 60%;
    height: auto;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  @media (max-width: 700px) {
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
  }
`;

const SquiggleContainer = styled(motion.div)`
  postion: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  margin-right: -125px;
  margin-top: -50px;
  mix-blend-mode: multiply;
  @media (max-width: 700px) {
    display: none;
  }
`;

const MainImgAnim = {
  hidden: { opacity: 0, x: -20 },
  visible: {
    opacity: 1,
    x: -0,
    transition: {
      delay: 0.2,
      duration: 0.3,
      ease: 'easeIn',
    },
  },
};

const SecondImgAnim = {
  hidden: { opacity: 0, x: -20 },
  visible: {
    opacity: 1,
    x: -0,
    transition: {
      delay: 0.35,
      duration: 0.3,
      ease: 'easeIn',
    },
  },
};

const SquiggleAnim = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.7,
      duration: 0.3,
      ease: 'easeIn',
    },
  },
};

const BgAnim = {
  hidden: { height: 0 },
  visible: {
    height: '100%',
    transition: {
      delay: 0.9,
      duration: 0.3,
      ease: 'easeIn',
    },
  },
};

export class TwoImageOffset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };
  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <TwoImageOffsetContainerOuter>
          <TwoImageOffsetContainer>
            <ImageContainer>
              <MainImage
                variants={MainImgAnim}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                {this.props.main_image && (
                  <LazyImage {...this.props.main_image} />
                )}
              </MainImage>
              <SecondaryImage
                variants={SecondImgAnim}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                {this.props.secondary_image && (
                  <LazyImage {...this.props.secondary_image} />
                )}
              </SecondaryImage>
            </ImageContainer>
            <BackgroundBlockContainer>
              <BackgroundBlock
                backgroundColor={this.props.background_color}
                variants={BgAnim}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              />
            </BackgroundBlockContainer>
          </TwoImageOffsetContainer>
        </TwoImageOffsetContainerOuter>
      </VisibilitySensor>
    );
  }
}

export default TwoImageOffset;

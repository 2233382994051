import React from 'react';
import { graphql } from 'gatsby';

import GradientStatBlock from '../../blocks/gradientStatBlock';

function GradientStatBlockPrismic({
  items,
  label_title,
  label_color,
  title,
  background_color,
  image,
}) {
  return (
    <GradientStatBlock
      items={items}
      label_color={label_color}
      label_title={label_title.text}
      title={title.text}
      background_color={background_color}
      image={image.url}
    />
  );
}

export const query = graphql`
  fragment GradientStatBlock on PrismicPageBodyGradientStatBlock {
    id
    slice_type
    primary {
      label_title {
        text
      }
      label_color
      title {
        text
      }
      background_color
      image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
    items {
      stat_value {
        text
      }
      stat_type {
        text
      }
      stat_text {
        text
      }
    }
  }
`;

export default GradientStatBlockPrismic;

import React from 'react';
import { graphql } from 'gatsby';

import BlogYoutubeVideo from '../../../blocks/blog/blogYoutubeVideo';

function BlogYoutubeVideoPrismic({ youtube_url, ...rest }) {
  return <BlogYoutubeVideo youtube_url={youtube_url.html} {...rest} />;
}

export const query = graphql`
  fragment BlogYoutubeVideo on PrismicBlogBodyYoutubeVideo {
    id
    slice_type
    primary {
      youtube_url {
        html
      }
    }
  }
`;

export default BlogYoutubeVideoPrismic;

import React from 'react';
import PropTypes from 'prop-types';
import { SLICE_MAPPINGS } from '../utils/slice-mappings';
import withPrismicErrorBoundary from '../utils/with-prismic-error-boundary';

function SliceZoneItem({ component: Component, ...rest }) {
  return <Component {...rest} />;
}

const SliceZoneItemWithErrorBoundary = withPrismicErrorBoundary(SliceZoneItem);

function SliceZone({ body, sliceMappings, customData, color }) {
  return (
    body &&
    body.length > 0 &&
    body.map(slice => {
      if (!slice) {
        console.error(`Slice is null or undefined`);
        return null;
      }

      const { id, slice_type, primary, items } = slice;

      if (!slice_type) {
        return null;
      }

      const SliceComponent = sliceMappings[slice_type];
      if (!SliceComponent) {
        console.error(
          `A corresponding component was not found for the slice of type: ${slice_type}`
        );

        return null;
      }

      return (
        <SliceZoneItemWithErrorBoundary
          key={id}
          {...primary}
          items={items}
          component={SliceComponent}
          customData={customData}
          color={color}
        />
      );
    })
  );
}

SliceZone.propTypes = {
  sliceMappings: PropTypes.object.isRequired,
};

SliceZone.defaultProps = {
  sliceMappings: SLICE_MAPPINGS,
};

export default SliceZone;

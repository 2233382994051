import React from 'react';
import { graphql } from 'gatsby';

import VideoShowcase from '../../blocks/videoShowcase';

function VideoShowcasePrismic({ slice_type }) {
  return (
    <>
      <VideoShowcase />
      <p>HERE>....</p>
    </>
  );
}

export const query = graphql`
  fragment VideoShowcase on PrismicPageBodyVideoShowcase {
    id
    slice_type
    items {
      image {
        url
      }
      video {
        url
      }
    }
  }
`;

export default VideoShowcase;

import React from 'react';
import styled from '@emotion/styled';
import ReactSwiper from './swiper';
import { motion } from 'framer-motion';
import ButtonNotUnderlined from '../components/ButtonNotUnderlined';
import '../utils/Carousel.css';
import { IS_BROWSER } from '../utils/constants';
import linkStripper from '../utils/link-stripper';
import urlParser from '../utils/urlParser';
import SliderArrow from '../images/assets/sliderArrow.svg';
// import ReactResizeDetector from 'react-resize-detector';

const Item = styled.div`
  width: 550px;
  height: 100%;
  margin-top: 100px;
  padding-left: 10px;
  padding-right: 10px;

  @media (max-width: 1250px) {
    margin-top: 50px;
    width: 400px;
  }
  @media (max-width: 600px) {
    width: calc(100vw - 80px);
    // margin-right: 20px;
  }
`;

const ImageContainer = styled(motion.div)`
  height: 0;
  padding-bottom: 125%;
  width: 100%;
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    background: linear-gradient(to bottom, transparent 0%, #000 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    z-index: 1;
  }

  @media (max-width: 1250px) {
    // width: 360px;
  }
  @media (max-width: 600px) {
    // width: 280px;
  }
`;

const VideoContainer = styled(motion.div)`
  height: 0;
  padding-bottom: 125%;
  width: 100%;
  position: relative;
  overflow: hidden;
  @media (max-width: 1250px) {
    // width: 360px;
  }
  @media (max-width: 600px) {
    // width: 280px;
  }

  &:before {
    content: '';
    background: linear-gradient(to bottom, transparent 0%, #000 100%);
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    z-index: 1;
  }

  video {
    position: absolute;
    height: 100%;
    width: 100%;
    object-fit: cover;
    @media (max-width: 1250px) {
      // height: 330px;
      // width: 360px;
    }
    @media (max-width: 600px) {
      // height: 250px;
      // width: 280px;
    }
  }
`;

const ItemSrcContainer = styled.a``;

const ItemImage = styled(motion.img)`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  top: 0;
  left: 0;
`;

const Title = styled(motion.div)`
  font-weight: 400;
  font-size: 22px;
  font-style: normal;
  font-family: Montserrat;
  line-height: 1.4;
  letter-spacing: -0.03em;
  color: #ffffff;
  margin-top: 25px;
  width: 385px;
  // min-height: 75px;
  margin-bottom: 15px;
  @media (max-width: 1250px) {
    font-size: 22px;
    line-height: 24px;
  }
  @media (max-width: 600px) {
    width: 100%;
    // max-width: 280px;
    font-size: 18px;
  }
`;

const ArrowContainerOuter = styled.div`
  width: 80%;
  margin-top: -50px;
  padding-bottom: 0px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 800px) {
    // padding-bottom: 110px;
    // width: 90%;
    // margin-left: -30px;
    // margin-top: -175px;
  }
  @media (max-width: 600px) {
    margin-top: 0;
    position: absolute;
    top: 45px;
    padding-bottom: 5px;
    margin-left: 0px;
    width: 90%;
  }
  @media (max-width: 310px) {
    justify-content: flex-start;
    margin-top: 0px;
    width: 100%;
    margin-left: -5%;
    margin-top: 10px;
    margin-left: 0%;
    margin-top: 30px;
  }
`;

const ArrowsContainer = styled.div`
  display: flex;
`;

const ArrowContainer = styled.div`
  height: 42px;
  width: 42px;
  padding-left: 2.5px;
  padding-right: 2.5px;
  cursor: pointer;
`;

const ArrowContainerReversed = styled.div`
  height: 42px;
  width: 42px;
  transform: rotate(180deg);
  padding-left: 2.5px;
  padding-right: 2.5px;
  cursor: pointer;
`;

const ContentContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: calc(100% - 20px);
  padding: 0 30px 40px;
  z-index: 2;
  box-sizing: border-box;

  @media (max-width: 600px) {
    padding: 0 20px 40px;
  }
`;

const SwiperItemContainer = styled.div``;

const container = {
  hidden: { opacity: 0, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
};

const swiperItem = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const imgVariants = {
  inactive: {
    scale: 1,
  },
  hover: {
    scale: 1.1,
    transition: { duration: 0.5 },
  },
  active: {
    scale: 1.1,
    transition: { duration: 0.5 },
  },
};

const onResize = () => {
  this.forceUpdate();
};

function Carousel({ visible, items, color }) {
  const SWIPER_OPTIONS = {
    slidesPerView: 'auto',
    speed: 500,
    loop: true,
    loopedSlides: items.length,
    centerInsufficientSlides:
      IS_BROWSER && !!SVGComponentTransferFunctionElement,
    breakpoints: {},
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  return (
    <div>
      <ArrowContainerOuter>
        <ArrowsContainer>
          <ArrowContainer>
            <SliderArrow class="swiper-button-prev"></SliderArrow>
          </ArrowContainer>
          <ArrowContainerReversed>
            <SliderArrow class="swiper-button-next"></SliderArrow>
          </ArrowContainerReversed>
        </ArrowsContainer>
      </ArrowContainerOuter>

      <ReactSwiper
        height={[240, 320, 560]}
        options={SWIPER_OPTIONS}
        variants={container}
        initial="hidden"
        animate={visible ? 'visible' : 'hidden'}
      >
        {items.map((item, index) => (
          <ReactSwiper.Item
            key={index}
            variants={swiperItem}
            initial="hidden"
            animate={visible ? 'visible' : 'hidden'}
          >
            <Item>
              {item.case_study_video &&
              item.case_study_video.url &&
              item.case_study_video.url.includes('.mp4') ? (
                <VideoContainer>
                  <video autoPlay loop playsInline muted>
                    <source src={item.case_study_video.url} type="video/mp4" />
                  </video>
                </VideoContainer>
              ) : (
                <ImageContainer>
                  <ItemSrcContainer
                    href={linkStripper(item.cta_link.url)}
                    target={item.cta_link.target}
                  >
                    <ItemImage
                      src={item.case_study_image.url}
                      whileTap="active"
                      whileHover="hover"
                      initial="inactive"
                      variants={imgVariants}
                    />
                  </ItemSrcContainer>
                </ImageContainer>
              )}
              <ContentContainer>
                <Title>{item.case_study_title.text}</Title>

                <ButtonNotUnderlined
                  buttonText={item.cta_text.text}
                  link={linkStripper(item.cta_link.url)}
                  color={color}
                  arrowColor={color}
                  target={item.cta_link.target}
                />
              </ContentContainer>
            </Item>
          </ReactSwiper.Item>
        ))}
      </ReactSwiper>
    </div>
  );
}

export default Carousel;

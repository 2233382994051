import React from 'react';
import { graphql } from 'gatsby';

import BlogHeading from '../../../blocks/blog/blogHeading';

function BlogHeadingPrismic({
  title_text,
  heading_size,
  heading_color,
  blogData,
  ...rest
}) {
  return (
    <div>
      <BlogHeading
        title_text={title_text.text}
        heading_size={heading_size}
        heading_color={heading_color}
        blogData={blogData}
        {...rest}
      />
    </div>
  );
}

export const query = graphql`
  fragment BlogHeading on PrismicBlogBodyHeading {
    id
    slice_type
    primary {
      title_text {
        text
      }
      heading_size
      heading_color
    }
  }
`;

export default BlogHeadingPrismic;

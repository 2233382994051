import React from 'react';
import { graphql } from 'gatsby';

import StatsBlock from '../../blocks/statsBlock';

function PrismicStatsBlock({
  label_text,
  label_color,
  title,
  paragraph_text,
  items,
}) {
  return (
    <StatsBlock
      items={items}
      label_text={label_text.text}
      label_color={label_color}
      title={title.text}
      paragraph_text={paragraph_text.text}
    />
  );
}

export const query = graphql`
  fragment StatsBlock on PrismicPageBodyStatsBlock {
    id
    slice_type
    primary {
      label_text {
        text
      }
      label_color
      title {
        text
      }
      paragraph_text {
        text
      }
    }
    items {
      stat_value {
        text
      }
      stat_type {
        text
      }
      stat_text {
        text
      }
    }
  }
`;

export default PrismicStatsBlock;

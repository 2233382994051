import React from 'react';
import { graphql } from 'gatsby';

import RecognitionSplash from '../../blocks/whyReload/recognitionSplash';

function RecognitionSplashPrismic({
  block_color_theme,
  star_count,
  title,
  subtitle,
  cta_text,
  cta_link,
}) {
  return (
    <RecognitionSplash
      block_color_theme={block_color_theme}
      star_count={star_count}
      title={title.text}
      subtitle={subtitle.text}
      cta_text={cta_text.text}
      cta_link={cta_link.url}
      cta_link_target={cta_link.target}
    />
  );
}

export const query = graphql`
  fragment RecognitionSplash on PrismicPageBodyRecognitionSplash {
    id
    slice_type
    primary {
      block_color_theme
      star_count
      title {
        text
      }
      subtitle {
        text
      }
      cta_text {
        text
      }
      cta_link {
        url
        target
      }
    }
  }
`;

export default RecognitionSplash;

import React from 'react';
import styled from '@emotion/styled';

const BlogDividerContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${props => props.height};
  @media (max-width: 750px) {
    height: ${props => props.mobileHeight};
  }
`;

const Divider = styled.div`
  width: 500px;
  height: 0;
  max-width: 80%;
  border: 1px solid ${props => props.color};
`;

const BlogDivider = props => (
  <BlogDividerContainer
    height={props.height + 'px'}
    mobileHeight={props.mobile_height + 'px'}
  >
    {props.show_line === 'true' ? (
      <Divider
        color={
          props.line_color && props.line_color !== null
            ? props.line_color
            : '#ffffff'
        }
      />
    ) : null}
  </BlogDividerContainer>
);

export default BlogDivider;

import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import linkStripper from '../../utils/link-stripper';
import { Link } from 'gatsby';
import { buttonReset } from '../../utils/styles';
import Arrow from '../../components/ButtonNotUnderlined/assets/arrow.svg';
import convertHtml from '../../utils/convertHtml';

const BlogCtaContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 1150px) {
    width: 90%;
    margin-left: 5%;
  }
`;
const BlogCtaContainerInner = styled.div`
  width: 70%;
  max-width: 800px;
  @media (max-width: 1150px) {
    width: 100%;
  }
  margin: 25px auto;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
`;
const BlogCtaContainer = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 180%;
  color: #ffffff;
  margin: 50px 20px;
  em {
    font-style: normal;
  }
  strong {
    font-weight: bold;
  }
  ul {
    list-style-type: disc;
    margin-left: 20px;
  }
  a {
    cursor: pointer;
  }
  @media (max-width: 1150px) {
    width: 90%;
  }
  @media (max-width: 750px) {
    width: 100%;
    margin: 30px 0;
  }
`;

const CtaTitle = styled.div`
  font-size: 1.6em;
  font-weight: 600;
  line-height: 1.4em;
`;

const ButtonText = styled.div`
  font-weight: bold;
  font-size: 1em;
  color: #ff22ee;
`;

const ArrowContainer = styled(motion.div)`
  margin-left: 10px;
  padding-top: 1px;
  display: flex;
  align-items: center;
`;

const ButtonNotUnderlinedContainerInner = styled.a`
  display: flex;
  margin-top: 20px;
`;

export class BlogCta extends Component {
  render() {
    console.log('this.props.link_url', this.props.link_url);
    return (
      <BlogCtaContainerOuter>
        <BlogCtaContainerInner>
          <BlogCtaContainer>
            <CtaTitle
              dangerouslySetInnerHTML={convertHtml(
                this.props.title &&
                  (this.props.title.html || this.props.title.text)
              )}
            />
            <ButtonNotUnderlinedContainerInner
              href={this.props.link_url.url}
              target={this.props.link_url.target}
            >
              <ButtonText>{this.props.link_text.text}</ButtonText>
              <ArrowContainer transition={{ duration: 0.5 }}>
                <Arrow
                  style={{
                    fill: `#ff22ee`,
                  }}
                />
              </ArrowContainer>
            </ButtonNotUnderlinedContainerInner>
          </BlogCtaContainer>
        </BlogCtaContainerInner>
      </BlogCtaContainerOuter>
    );
  }
}

export default BlogCta;

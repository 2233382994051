import React from 'react';
import { graphql } from 'gatsby';

import Review from '../../blocks/review';
import urlParser from '../../utils/urlParser';

function PrismicReview({
  review_logo,
  review_score,
  review_cta_text,
  cta_link,
  review,
  review_author,
  review_color_theme,

  block_background_color,
}) {
  return (
    <Review
      review_logo={review_logo.url}
      review_score={review_score}
      review_cta_text={review_cta_text.text}
      cta_link={urlParser(cta_link)}
      cta_link_target={cta_link.target}
      review_author={review_author.text}
      review={review.text}
      review_color_theme={review_color_theme}
      block_background_color={block_background_color}
    />
  );
}

export const query = graphql`
  fragment Review on PrismicPageBodyReview {
    id
    slice_type
    primary {
      review_color_theme
      block_background_color
      review_logo {
        url
      }
      review_score
      review_cta_text {
        text
      }
      cta_link {
        url
        slug
        target
      }
      review {
        text
      }
      review_author {
        text
      }
      review_color_theme
    }
  }
`;

export default PrismicReview;

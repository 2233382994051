import React from 'react';
import { graphql } from 'gatsby';

import BlogCta from '../../../blocks/blog/blogCta.jsx';

function BlogCtaPrismic({ title, link_text, link_url, ...rest }) {
  return (
    <div>
      <BlogCta
        title={title}
        link_text={link_text}
        link_url={link_url}
        {...rest}
      />
    </div>
  );
}

export const query = graphql`
  fragment BlogCta on PrismicBlogBodyCta {
    id
    slice_type
    primary {
      title {
        html
        raw
        text
      }
      link_text {
        text
      }
      link_url {
        url
        target
      }
    }
  }
`;

export default BlogCtaPrismic;

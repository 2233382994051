import React from 'react';
import { graphql } from 'gatsby';

import TwoImageHeader from '../../blocks/headerThree';

function TwoImageHeaderPrismic({
  color_theme,
  background_color,
  header_text,
  header_text_color,
  subtitle_text,
  subtitle_text_color,
  cta_text,
  cta_link,
  cta_text_color,
}) {
  return (
    <TwoImageHeader
      color_theme={color_theme}
      background_color={background_color}
      header_text={header_text.text}
      header_text_color={header_text_color}
      subtitle_text={subtitle_text.text}
      subtitle_text_color={subtitle_text_color}
      cta_text={cta_text.text}
      cta_link={cta_link.url}
      cta_link_target={cta_link.target}
      cta_text_color={cta_text_color}
      nonLazyMode={false}
    />
  );
}

export const query = graphql`
  fragment TwoImageHeader on PrismicPageBodyTwoImageHeader {
    id
    slice_type
    primary {
      color_theme
      background_color
      header_text {
        text
      }
      header_text_color
      subtitle_text {
        text
      }
      subtitle_text_color
      cta_text {
        text
      }
      cta_link {
        url
        target
      }
      cta_text_color
      left_image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      right_image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default TwoImageHeader;

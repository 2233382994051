import React, { Component } from 'react';
import styled from '@emotion/styled';

const BlogYoutubeVideoContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px auto;

  @media (max-width: 1150px) {
    width: 90%;
    margin-left: 5%;
  }
`;

const BlogYoutubeVideoContainerInner = styled.div`
  width: 70%;
  max-width: 800px;
  @media (max-width: 1150px) {
    width: 100%;
  }
`;

export class BlogYoutubeVideo extends Component {
  render() {
    let regex = /embed\/([\w-]*)?/;
    let iframe = this.props.youtube_url;
    let video_id = iframe.match(regex)[1]; // id = 'Ahg6qcgoay4'
    //console.log('video_id', video_id);
    return (
      <BlogYoutubeVideoContainerOuter>
        <BlogYoutubeVideoContainerInner>
          <lite-youtube videoid={video_id}></lite-youtube>
        </BlogYoutubeVideoContainerInner>
      </BlogYoutubeVideoContainerOuter>
    );
  }
}

export default BlogYoutubeVideo;

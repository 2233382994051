import React, { Component } from 'react';
import styled from '@emotion/styled';
import convertHtml from '../../utils/convertHtml';
const CustomHtmlContainer = styled.div``;

export class BlogHtml extends Component {
  render() {
    return (
      <CustomHtmlContainer
        dangerouslySetInnerHTML={convertHtml(this.props.custom_html)}
      ></CustomHtmlContainer>
    );
  }
}

export default BlogHtml;

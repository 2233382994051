import React from 'react';
import { graphql } from 'gatsby';

import TypingText from '../../blocks/typingText';

function TypingTextPrismic({
  items,
  background_color,
  text_color,
  text_accent_color,
  text_type,
  typing_speed,
  backspace_speed,
}) {
  return (
    <TypingText
      background_color={background_color}
      text_color={text_color}
      text_accent_color={text_accent_color}
      text_type={text_type}
      items={items}
      typing_speed={typing_speed}
      backspace_speed={backspace_speed}
    />
  );
}

export const query = graphql`
  fragment TypingText on PrismicPageBodyTypingText {
    id
    slice_type
    primary {
      background_color
      text_color
      text_accent_color
      typing_speed
      backspace_speed
    }
    items {
      text {
        text
        html
      }
    }
  }
`;

export default TypingText;

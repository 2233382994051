import styled from '@emotion/styled';

export const WhyReloadContainer = styled.div`
  font-family: 'Montserrat', sans-serif;
`;

export const WhyFiveStarDivWrap = styled.div`
  position: relative;
`;
export const WhyFiveStarDiv = styled.div`
  max-width: 810px;
  margin: 0 auto;
  padding: 5rem 2rem;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;
export const StarDiv = styled.div`
  text-align: center;
  margin-bottom: 2rem;
  svg {
    margin: 0.5rem;
    width: 74px;
    height: 71px;
  }
  @media (max-width: 1480px) {
    margin-bottom: 1rem;
    svg {
      width: 50px;
      height: 50px;
    }
  }
  @media (max-width: 965px) {
    margin-bottom: 0.5rem;
    svg {
      width: 32px;
      height: 32px;
    }
  }
  @media (max-width: 735px) {
    svg {
      width: 22px;
      height: 22px;
    }
  }
`;
export const StarText = styled.div``;
export const StarSubText = styled.div`
  color: #ffffff;
  font-size: 18px;
  line-height: 32px;
  font-weight: 500;
  margin: 3rem 0 4rem;
  @media (max-width: 1480px) {
    margin: 2rem 0 2.5rem;
  }
  @media (max-width: 965px) {
    margin: 1rem 0 1.5rem;
  }
  @media (max-width: 735px) {
  }
`;

export const StarTextSection = styled.div``;
export const RecognitionDiv = styled.div`
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.01em;
  cursor: pointer;
  transition: 0.5s all;
  svg {
    margin-left: 5px;
  }
  &:hover {
    color: #40e0d0;
  }
  @media (max-width: 965px) {
    font-size: 16px;
  }
`;

export const WhyFeedbackDiv = styled.div`
  background: transparent;
  padding: 5rem 2rem;
  margin: 2rem 0;
  position: relative;
  @media (max-width: 965px) {
    padding: 2rem 1rem;
  }
`;
export const FeedBackTopDiv = styled.div`
  max-width: 920px;
  margin: auto;
`;
export const FeedBackStarDiv = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
  @media (max-width: 735px) {
    margin-bottom: 1.5rem;
  }
`;

export const FeedBackName = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #aaaaaa;
  margin-top: 2rem;
  @media (max-width: 735px) {
    margin-bottom: 1rem;
  }
`;
export const FeedBackQuoteDiv = styled.div`
  max-width: 800px;
  margin: auto;
  font-weight: 800;
  font-size: 32px;
  line-height: 48px;
  color: #ffffff;
  text-align: center;
  @media (max-width: 1480px) {
    font-size: 22px;
    line-height: 34px;
  }
  @media (max-width: 965px) {
    font-size: 22px;
    line-height: 34px;
    font-weight: 600;
    br {
      display: none;
    }
  }
  @media (max-width: 735px) {
    font-size: 18px;
    line-height: 34px;
  }
`;
export const GreenstarWrap = styled.div`
  margin-left: 10px;
  svg {
    margin: 0 0.1rem;
  }
`;

import React from 'react';
import styled from '@emotion/styled';
import ReactSwiper from './swiper';
import { motion } from 'framer-motion';
import ButtonNotUnderlined from '../components/ButtonNotUnderlined';
import '../utils/Carousel.css';
import { IS_BROWSER } from '../utils/constants';
import linkStripper from '../utils/link-stripper';
import urlParser from '../utils/urlParser';
import SliderArrow from '../images/assets/sliderArrow.svg';
import SliderArrowBlack from '../images/assets/sliderArrow.svg';
// import ReactResizeDetector from 'react-resize-detector';

const Item = styled.div`
  height: 100%;
  margin-top: 100px;
  @media (max-width: 1250px) {
    margin-top: 50px;
  }
  @media (max-width: 600px) {
    :nth-last-child {
      padding-right: 0px;
    }
  }
`;

const ImageContainer = styled(motion.div)`
  margin: 0 50px;
  @media (max-width: 768px) {
    /*margin: 10px*/
    margin: 0;
  }
  overflow: hidden;
`;

const ItemSrcContainer = styled.span``;

const ItemImage = styled(motion.img)`
  object-fit: cover;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

const ArrowContainerOuter = styled.div`
  width: 80%;
  margin-top: -50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 800px) {
    padding-bottom: 0;
    width: 90%;
    margin-left: -30px;
    margin-top: 80px;
  }
`;

const ArrowsContainer = styled.div`
  display: flex;
`;

const ArrowContainer = styled.div`
  height: 42px;
  width: 42px;
  padding-left: 2.5px;
  padding-right: 2.5px;
  cursor: pointer;
`;

const ArrowContainerReversed = styled.div`
  height: 42px;
  width: 42px;
  transform: rotate(180deg);
  padding-left: 2.5px;
  padding-right: 2.5px;
  cursor: pointer;
`;

const SwiperItemContainer = styled.div``;

const container = {
  hidden: { opacity: 0, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
};

const swiperItem = {
  hidden: { y: 20, opacity: 0.8 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const imgVariants = {
  inactive: {
    scale: 1,
  },
  hover: {
    scale: 0.95,
    transition: { duration: 0.5 },
  },
  active: {
    scale: 0.9,
    transition: { duration: 0.25 },
  },
};

const onResize = () => {
  this.forceUpdate();
};

function SimpleCarousel({ items }) {
  const SWIPER_OPTIONS = {
    slidesPerView: 'auto',
    spaceBetween: 0,
    speed: 300,
    loop: true,
    loopedSlides: 0,
    centerInsufficientSlides:
      IS_BROWSER && !!SVGComponentTransferFunctionElement,
    breakpoints: {},
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };

  return (
    <div>
      <ArrowContainerOuter>
        <ArrowsContainer>
          <ArrowContainer>
            <SliderArrowBlack class="swiper-button-prev"></SliderArrowBlack>
          </ArrowContainer>
          <ArrowContainerReversed>
            <SliderArrowBlack class="swiper-button-next"></SliderArrowBlack>
          </ArrowContainerReversed>
        </ArrowsContainer>
      </ArrowContainerOuter>

      <ReactSwiper
        height={[240, 320, 560]}
        options={SWIPER_OPTIONS}
        variants={container}
        initial="hidden"
        animate="visible"
      >
        {items.map((item, index) => (
          <ReactSwiper.Item
            key={index}
            variants={swiperItem}
            initial="hidden"
            animate="visible"
          >
            <Item>
              <ImageContainer>
                <ItemSrcContainer>
                  <ItemImage
                    src={item.gallery_image.url}
                    whileTap="active"
                    whileHover="hover"
                    initial="inactive"
                    variants={imgVariants}
                  />
                </ItemSrcContainer>
              </ImageContainer>
            </Item>
          </ReactSwiper.Item>
        ))}
      </ReactSwiper>
    </div>
  );
}

export default SimpleCarousel;

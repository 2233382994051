import React from 'react';

const css = `
  .svgElements_r {
    fill-opacity: 1;
    position: absolute;
    bottom: 0;
    right: 0;
    max-width: 170px;
    z-index: 9; 
  }  
  
  @media (max-width: 965px) {
    .svgElements_r {
    max-width: 80px;
  }
}
@media (max-width: 735px) {
    .svgElements_r {  
  display: none;
  z-index: 1;
}
}
`;

class whnBGRight extends React.Component {
  render() {
    return (
      <svg
        width="156"
        height="346"
        viewBox="0 0 156 346"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="svgElements_r"
      >
        <defs>
          <style>{css}</style>
        </defs>
        <path
          d="M221.764 66.1057C194.516 58.9904 164.613 53.5908 136.416 56.6097C112.334 59.1881 106.985 69.3443 125.279 85.8559C138.381 97.6814 152.615 108.635 167.092 118.716C168.401 119.628 182.754 126.855 182.885 130.057C183.107 135.466 129.416 134.9 126.164 135.108C118.699 135.586 105.854 135.025 98.6435 138.643C90.413 142.773 111.956 158.84 112.592 159.361C121.271 166.475 222.117 230.156 220.736 232.047C215.626 239.044 184.165 234.337 178.648 234.061C148.812 232.563 119.026 228.342 89.1486 227.867C70.4823 227.57 38.0999 227.04 33.4331 250.087"
          stroke={this.props.color}
          strokeWidth="33.2569"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
}

export default whnBGRight;

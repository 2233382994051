import React from 'react';
import { graphql } from 'gatsby';

import SubPageHeader from '../../blocks/subPageHeader';
import urlParser from '../../utils/urlParser';

function SubPageHeaderPrismic({
  label_text,
  label_color,
  title_text,
  title_underline_color,
  title_color,
  paragraph_text,
  call_to_action_color,
  call_to_action_link,
  squiggle_color,
  right_aligned_image,
  subtitle_text,
  cta_text,
}) {
  return (
    <div>
      <SubPageHeader
        label_text={label_text.text}
        label_color={label_color}
        title_text={title_text.text}
        title_underline_color={title_underline_color}
        subtitle_text={subtitle_text.text}
        paragraph_text={paragraph_text.html}
        call_to_action_color={call_to_action_color}
        call_to_action_link={call_to_action_link}
        call_to_action_link_target={call_to_action_link.target}
        squiggle_color={squiggle_color}
        right_aligned_image={right_aligned_image}
        title_color={title_color}
        cta_text={cta_text.text}
      />
    </div>
  );
}

export const query = graphql`
  fragment SubPageHeader on PrismicPageBodySubPageHeader {
    id
    slice_type
    primary {
      label_text {
        text
      }
      label_color
      title_text {
        text
      }
      title_underline_color
      subtitle_text {
        text
      }
      paragraph_text {
        html
      }
      cta_text {
        text
      }
      call_to_action_color
      call_to_action_link {
        url
        target
      }
      squiggle_color
      right_aligned_image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      title_color
    }
  }
`;

export default SubPageHeaderPrismic;

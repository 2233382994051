import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import LazyImage from '../components/lazy-image';
import ButtonWithCircle from '../components/ButtonWithCircle';

import urlParser from '../utils/urlParser';
import linkStripper from '../utils/link-stripper';
import Label from '../components/Label';
import ButtonNotUnderlined from '../components/ButtonNotUnderlined';

const CaseStudyHeaderOneContainerOuter = styled.div`
  display: flex;
  justify-content: center;
  height: 100vh;
  min-height: 1000px;
  background-color: ${props => props.backgroundColor};
  width: 100%;
  max-height: 1300px;
  overflow: hidden;
`;

const CaseStudyHeaderOneContainer = styled.div`
  display: flex;
  max-width: 1440px;
  width: 100%;
  padding-top: 100px;
  @media (max-width: 800px) {
    flex-direction: column-reverse;
    padding-top: 180px;
  }
`;

const HeaderImageContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  max-width: 700px;
  min-width: 100px;
  @media (max-width: 800px) {
    justify-content: flex-end;
    max-width: unset;
    height: 60%;
  }
  @media (max-width: 600px) {
    justify-content: center;
    max-width: unset;
  }
`;

const HeaderImage = styled.img`
  height: 100%;
  padding-left: 25px;
  object-fit: contain;
  object-position: bottom;
  @media (max-width: 1200px) {
    height: 80%;
  }
  @media (max-width: 985px) {
    height: 80%;
  }
  @media (max-width: 830px) {
    height: 70%;
  }
  @media (max-width: 800px) {
    padding-left: 0px;
    height: 100%;
    height: 52.5%;
    position: absolute;
  }
  @media (max-width: 600px) {
    position: unset;
    height: 80%;
  }
`;

const ContentContainer = styled.div`
  width: 60%;
  height: 100%;
  padding-left: 200px;
  display: flex;
  align-items: center;
  @media (max-width: 1200px) {
    width: 70%;
  }
  @media (max-width: 1100px) {
    align-items: flex-start;
  }
  @media (max-width: 985px) {
    width: 100%;
  }
  @media (max-width: 800px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 0;
    align-items: flex-start;
    height: fit-content;
    margin-bottom: 5px;
  }
`;

const ContentContainerInner = styled.div``;

const TagContainer = styled.div`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #817e7e;
  @media (max-width: 800px) {
    display: none;
    font-size: 11px;
    line-height: 20px;
  }
`;

const LabelContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 800px) {
    display: none;
  }
`;

const LabelContainerMobile = styled.div`
  display: none;
  margin-top: 10px;
  margin-bottom: 10px;
  @media (max-width: 800px) {
    display: block;
  }
`;

const Title = styled.h1`
  font-family: Montserrat;
  font-weight: 800;
  font-size: 80px;
  line-height: 80px;
  color: ${props => props.color};
  margin-bottom: 20px;
  margin-top: 20px;
  padding-right: 50px;
  z-index: 10;
  font-style: normal;
  @media (max-width: 1250px) {
    font-size: 60px;
    line-height: 60px;
  }
  @media (max-width: 985px) {
    padding-right: 20px;
  }
  @media (max-width: 900px) {
    font-size: 30px;
    line-height: 30px;
  }
  @media (max-width: 800px) {
    max-width: 100%;
    font-size: 65px;
    line-height: 65px;
  }
  @media (max-width: 600px) {
    font-size: 45px;
    line-height: 45px;
  }
  @media (max-height: 600px) {
    font-size: 35px;
    line-height: 35px;
  }
`;

const Paragraph = styled.div`
  max-width: 456px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 40px;
  z-index: 10;
  @media (max-width: 600px) {
    max-width: 100%;
    font-size: 14px;
    line-height: 20px;
    width: unset;
    margin-bottom: 20px;
  }
`;

const CtaContainer = styled.div`
  z-index: 10;
  @media (max-width: 600px) {
    display: none;
  }
`;

const CtaContainerMobile = styled.div`
  z-index: 10;
  display: none;
  @media (max-width: 600px) {
    display: block;
  }
`;

export class CaseStudyHeaderOne extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };
  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <CaseStudyHeaderOneContainerOuter
          backgroundColor={this.props.block_background_color}
        >
          <CaseStudyHeaderOneContainer>
            <HeaderImageContainer>
              <HeaderImage src={this.props.image.url} />
            </HeaderImageContainer>
            <ContentContainer>
              <ContentContainerInner>
                <TagContainer>{this.props.case_study_tags.text}</TagContainer>
                <LabelContainer>
                  <Label
                    text={this.props.label_text.text}
                    color={this.props.label_color}
                    fontColor={this.props.label_color}
                    fontSize={'14px'}
                  />
                </LabelContainer>
                <LabelContainerMobile>
                  <Label
                    text={this.props.label_text.text}
                    color={this.props.label_color}
                    fontSize={'11px'}
                    fontColor={this.props.label_color}
                    fontWeight={'800'}
                    font={"'Montserrat', sans-serif"}
                  />
                </LabelContainerMobile>
                <Title color={this.props.title_text_color}>
                  {this.props.title_text.text}
                </Title>
                <Paragraph>{this.props.subtitle_text.text}</Paragraph>

                <CtaContainer>
                  <ButtonNotUnderlined
                    buttonText={this.props.cta_text.text}
                    link={linkStripper(this.props.cta_link.url)}
                    arrowColor={this.props.cta_arrow_color}
                    textColor={this.props.cta_text_color}
                    target={this.props.cta_link.target}
                  />
                </CtaContainer>
                <CtaContainerMobile>
                  <ButtonNotUnderlined
                    arrowColor={this.props.cta_arrow_color}
                    textColor={this.props.cta_text_color}
                    buttonText={this.props.cta_text.text}
                    link={linkStripper(urlParser(this.props.cta_link))}
                    target={this.props.cta_link.target}
                  />
                </CtaContainerMobile>
              </ContentContainerInner>
            </ContentContainer>
          </CaseStudyHeaderOneContainer>
        </CaseStudyHeaderOneContainerOuter>
      </VisibilitySensor>
    );
  }
}

export default CaseStudyHeaderOne;

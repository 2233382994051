import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import LazyImage from '../components/lazy-image';
import Label from '../components/Label';
import TitleUnderlined from '../components/TitleUnderlined';
import convertHtml from '../utils/convertHtml';
import Squiggle from '../components/squiggle';

const PropositionOuterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
`;

const PropositionContainer = styled(motion.div)`
  background-color: transparent;
  padding-top: 180px;
  padding-bottom: 180px;
  width: 90%;
  max-width: 1440px;
`;

const LabelContainer = styled(motion.div)`
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TitleContainer = styled(motion.div)`
  max-width: 700px;
  margin-bottom: 30px;
  @media (max-width: 900px) {
    margin-bottom: 10px;
  }
`;

const TitleContainerOuter = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LeftSquiggle = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  transform: rotate(30deg);
  margin-left: -12.5vw;
  @media (max-width: 900px) {
    display: none;
  }
`;

const RightSquiggle = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: rotate(45deg);
  margin-right: -10vw;
  @media (max-width: 900px) {
    display: none;
  }
`;

const Subtitle = styled(motion.div)`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
`;

const container = {
  hidden: { opacity: 1, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.075,
    },
  },
};

const propItem = {
  hidden: { y: -20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export class homepageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };
  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <React.Fragment>
          <PropositionOuterContainer>
            <PropositionContainer
              variants={container}
              initial="hidden"
              animate={this.state.visible ? 'visible' : 'hidden'}
            >
              <LabelContainer variants={propItem}>
                <Label
                  color={this.props.block_color_theme}
                  text={this.props.label_text.text}
                />
              </LabelContainer>
              <TitleContainerOuter variants={propItem}>
                <TitleContainer>
                  <TitleUnderlined
                    underlineColor={this.props.block_color_theme}
                    textColor={'#ffffff'}
                    fontSize={'40px'}
                    mobileFontSize={'26px'}
                    titleText={this.props.title_text.text}
                  />
                </TitleContainer>
              </TitleContainerOuter>
              <Subtitle
                dangerouslySetInnerHTML={convertHtml(
                  this.props.subtitle_text.text
                )}
                variants={propItem}
              />
            </PropositionContainer>
          </PropositionOuterContainer>
        </React.Fragment>
      </VisibilitySensor>
    );
  }
}

export default homepageHeader;

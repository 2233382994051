import React from 'react';
import { graphql } from 'gatsby';

import CtaCard from '../../blocks/ctaCard';
import urlParser from '../../utils/urlParser';

function CtaCardPrismic({
  cta_title,
  cta_description,
  cta_theme,
  image,
  cta_link,
}) {
  return (
    <CtaCard
      cta_title={cta_title.text}
      cta_description={cta_description.text}
      cta_theme={cta_theme}
      image={image}
      cta_link={cta_link.url}
      cta_link_target={cta_link.target}
    />
  );
}

export const query = graphql`
  fragment CtaCard on PrismicPageBodyCtaCard {
    id
    slice_type
    primary {
      cta_title {
        text
      }
      cta_description {
        text
      }
      cta_theme
      cta_link {
        url
        target
      }
      image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default CtaCardPrismic;

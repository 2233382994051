import React from 'react';
import { graphql } from 'gatsby';

import ProcessBlock from '../../blocks/proccessBlock';

function PrismicProcessBlock({ title, label_title, color_theme, items }) {
  return (
    <ProcessBlock
      title={title.text}
      label_title={label_title.text}
      color_theme={color_theme}
      items={items}
    />
  );
}

export const query = graphql`
  fragment ProcessBlock on PrismicPageBodyProccessBlock {
    id
    slice_type
    primary {
      title {
        text
      }
      label_title {
        text
      }
      color_theme
    }
    items {
      block_title {
        text
      }
      block_paragraph {
        text
      }
      block_image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default PrismicProcessBlock;

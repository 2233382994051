import React from 'react';
import styled from '@emotion/styled';

//Component Import
import Label from '../components/Label';
import NumberBlock from '../components/numberBlock';
import BlankSpace from '../components/blankSpace';

const GradientStatBlockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.backgroundColor};
  position: relative;
  background-size: 50%;
  overflow: hidden;
  background-repeat: no-repeat;
  @media (max-width: 800px) {
    background-size: cover;
  }
`;

const ImageContainer = styled.div`
  height: 100%;
  width: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 800px) {
    width: 100%;
    position: absolute;
  }
`;

const ContentContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 75px;
  @media (max-width: 800px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
`;

const ContentContainerInner = styled.div``;

const Title = styled.h2`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
  max-width: 500px;
`;

const StatContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  flex-wrap: wrap;
  @media (max-width: 1050px) {
    max-width: 420px;
  }

  @media (max-width: 900px) {
    max-width: unset;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

const Stat = styled.div`
  display: flex;
  @media (max-width: 1050px) {
    margin-left: 5px;
    margin-right: 5px;
  }
  @media (max-width: 800px) {
    margin-bottom: 20px;
    margin-top: 20px;
  }
`;

const GradientStatBlock = ({
  props,
  items,
  label_title,
  label_color,
  title,
  background_color,
  image,
}) => (
  <GradientStatBlockContainer
    backgroundColor={background_color}
    style={{
      backgroundImage: `linear-gradient(to right, rgba(30,87,153,0) 0%,${background_color +
        '80'} 20%, ${background_color} 100%), url(${image})`,
    }}
  >
    <ImageContainer />
    <ContentContainer>
      <ContentContainerInner>
        <Label text={label_title} color={label_color} />
        <BlankSpace height="40px" />
        <Title>{title}</Title>
        <BlankSpace height="40px" mobileHeight="0px" />
        <StatContainer>
          {items.map((stat, index) => (
            <Stat>
              <NumberBlock
                number={stat.stat_value.text}
                statType={stat.stat_type.text}
                color={'#FFFFFF'}
                numberSubtitle={stat.stat_text.text}
                statFontSize={'100px'}
                statFontLineHeight={'80px'}
                fontWeight={'bold'}
                padding={'20px 0px 0px 0px'}
                borderStyle={'solid'}
                borderColor={'#ffffff'}
                margin={'0'}
                key={index}
              />
            </Stat>
          ))}
        </StatContainer>
      </ContentContainerInner>
    </ContentContainer>
  </GradientStatBlockContainer>
);

export default GradientStatBlock;

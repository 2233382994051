import React from 'react';
import { graphql } from 'gatsby';

import WhatWeDo from '../../blocks/whatWeDo';
import urlParser from '../../utils/urlParser';

function PrismicWhatWeDo({
  label_text,
  label_colour,
  title_text,
  paragraph_text,
  cta_card_subtitle,
  cta_card_title,
  cta_card_button_text,
  cta_card_button_colour,
  left_aligned_image,
  right_aligned_image,
  link_block_colour,
  link_block_item_target,
  link_block_item_read_more,
  link_block_item_color,
  cta_card_button_link,
  card_title_underline_color,
  items,
}) {
  return (
    <div>
      <WhatWeDo
        label_text={label_text.text}
        label_color={label_colour}
        title_text={title_text.text}
        paragraph_text={paragraph_text.html}
        cta_card_subtitle={cta_card_subtitle.text}
        cta_card_title={cta_card_title.text}
        cta_card_button_text={cta_card_button_text}
        cta_card_button_color={cta_card_button_colour}
        left_aligned_image={left_aligned_image}
        right_aligned_image={right_aligned_image}
        link_block_color={link_block_colour}
        cta_card_button_link={urlParser(cta_card_button_link)}
        cta_card_button_link_target={cta_card_button_link.target}
        card_title_underline_color={card_title_underline_color}
        items={items}
      />
    </div>
  );
}

export const query = graphql`
  fragment WhatWeDo on PrismicPageBodyWhatWeDo {
    id
    slice_type
    primary {
      label_text {
        text
      }
      label_colour
      title_text {
        text
      }
      paragraph_text {
        html
      }
      cta_card_subtitle {
        text
      }
      cta_card_title {
        text
      }
      cta_card_button_text {
        text
      }
      cta_card_button_link {
        url
        slug
        target
      }
      cta_card_button_colour
      card_title_underline_color
      left_aligned_image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      right_aligned_image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      link_block_colour
    }
    items {
      link_block_item {
        text
      }
      link_block_item_target {
        url
        slug
        target
      }
      link_block_item_read_more {
        text
      }
      link_block_item_color
    }
  }
`;

export default PrismicWhatWeDo;

import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import Label from '../components/Label';
import convertHtml from '../utils/convertHtml';
import LazyImage from '../components/lazy-image';

const AwardsIconsContainer = styled.div`
  overflow: hidden;
`;

const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Title = styled.h2`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
  margin-bottom: 75px;
`;

const AwardIconImgContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const ImageItem = styled(motion.div)`
  width: 200px;
  height: 200px;
  margin-left: 30px;
  margin-right: 30px;
  margin-bottom: 30px;
`;

const container = {
  hidden: { opacity: 1, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.075,
    },
  },
};

const imageEvenAnim = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export class AwardIcons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };
  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <AwardsIconsContainer>
          <LabelContainer>
            <Label
              color={this.props.block_color_theme}
              text={this.props.label_text.text}
            />
          </LabelContainer>
          <TitleContainer>
            <Title
              dangerouslySetInnerHTML={convertHtml(this.props.title_text.text)}
            />
          </TitleContainer>
          <AwardIconImgContainer
            variants={container}
            initial="hidden"
            animate={this.state.visible ? 'visible' : 'hidden'}
          >
            <ImageItem key={1} variants={imageEvenAnim}>
              {this.props.award_icon_one && (
                <LazyImage {...this.props.award_icon_one} />
              )}
            </ImageItem>
            <ImageItem key={2} variants={imageEvenAnim}>
              {this.props.award_icon_two && (
                <LazyImage {...this.props.award_icon_two} />
              )}
            </ImageItem>
            <ImageItem key={3} variants={imageEvenAnim}>
              {this.props.award_icon_three && (
                <LazyImage {...this.props.award_icon_three} />
              )}
            </ImageItem>
          </AwardIconImgContainer>
        </AwardsIconsContainer>
      </VisibilitySensor>
    );
  }
}

export default AwardIcons;

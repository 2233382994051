import React from 'react';
import styled from '@emotion/styled';

const ErrorMessageContainer = styled.div`
  padding: 60px 20px;
  text-align: center;
`;

const ErrorMessage = styled.div`
  color: #db272a;
  margin-bottom: 5px;
  font-size: 18px;
  font-weight: 700;
  max-width: 540px;
  margin-left: auto;
  margin-right: auto;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

function withPrismicErrorBoundary(WrappedComponent) {
  class WithPrismicErrorBoundary extends React.Component {
    state = {
      error: null,
      errorInfo: null,
    };

    componentDidCatch(error, errorInfo) {
      this.setState({
        error: error,
        errorInfo: errorInfo,
      });
    }

    render() {
      if (this.state.errorInfo) {
        return (
          <ErrorMessageContainer>
            <ErrorMessage>Cannot render this section.</ErrorMessage>
            {this.state.error && (
              <ErrorMessage>
                Error information: {this.state.error.toString()}
              </ErrorMessage>
            )}
          </ErrorMessageContainer>
        );
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  return WithPrismicErrorBoundary;
}

export default withPrismicErrorBoundary;

import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import Label from '../components/Label';
import LazyImage from '../components/lazy-image';
import convertHtml from '../utils/convertHtml';

const RightTwoImagesLeftTextContainerOuter = styled(motion.div)`
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const RightTwoImagesLeftTextContainer = styled.div`
  display: flex;
  max-width: 1440px;
  width: 100%;

  @media (max-width: 925px) {
    flex-wrap: wrap;
  }
`;

const LeftContent = styled(motion.div)`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (min-width: 1500px) {
  }
  @media (max-width: 925px) {
    width: 100%;
  }
`;

const LeftContentInner = styled.div``;

const RightContent = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
  @media (max-width: 1100px) {
    justify-content: center;
  }
  @media (max-width: 925px) {
    width: 100%;
  }
`;

const LeftContentContainer = styled.div`
  max-width: 500px;
  @media (max-width: 1100px) {
    padding-left: 50px;
  }
  @media (max-width: 925px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    padding-left: 0px;
    max-width: unset;
  }
`;

const LabelContainer = styled.div`
  margin-bottom: 20px;
`;

const TitleContainer = styled.h2`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 900;
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
  margin-bottom: 20px;
`;

const ParagraphText = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  letter-spacing: 0.01em;
  color: #ffffff;
  @media (max-width: 925px) {
    margin-bottom: 50px;
  }
`;

const ImageOne = styled(motion.div)`
  width: 365px;
  height: 475px;
  margin-right: 50px;
  @media (max-width: 1175px) {
    margin-right: 25px;
  }
  @media (max-width: 1100px) {
    margin-right: 0px;
    width: 220px;
    height: 360px;
  }
`;

const ImageTwo = styled(motion.div)`
  width: 175px;
  height: 475px;
  @media (max-width: 1100px) {
    display: none;
  }
`;

const TextAnim = {
  hidden: {
    opacity: 0,
    x: -20,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.35,
    },
  },
};

const ImageOneAnim = {
  hidden: {
    opacity: 0,
    x: -20,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.1,
      duration: 0.35,
    },
  },
};

const ImageTwoAnim = {
  hidden: {
    opacity: 0,
    x: -20,
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.2,
      duration: 0.35,
    },
  },
};

export class RightTwoImagesLeftText extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };
  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <RightTwoImagesLeftTextContainerOuter>
          <RightTwoImagesLeftTextContainer>
            <LeftContent
              variants={TextAnim}
              initial="hidden"
              animate={this.state.visible ? 'visible' : 'hidden'}
            >
              <LeftContentContainer>
                <LabelContainer>
                  <Label
                    color={this.props.label_color}
                    text={this.props.label_text.text}
                  />
                </LabelContainer>
                <TitleContainer
                  dangerouslySetInnerHTML={convertHtml(
                    this.props.title_text.text
                  )}
                />
                <ParagraphText
                  dangerouslySetInnerHTML={convertHtml(
                    this.props.paragraph_text.text
                  )}
                />
              </LeftContentContainer>
            </LeftContent>
            <RightContent>
              <ImageOne
                variants={ImageOneAnim}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                {this.props.image_1 && <LazyImage {...this.props.image_1} />}
              </ImageOne>
              <ImageTwo
                variants={ImageTwoAnim}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                {this.props.image_2 && <LazyImage {...this.props.image_2} />}
              </ImageTwo>
            </RightContent>
          </RightTwoImagesLeftTextContainer>
        </RightTwoImagesLeftTextContainerOuter>
      </VisibilitySensor>
    );
  }
}

export default RightTwoImagesLeftText;

import React from 'react';
import { graphql } from 'gatsby';

import TwoImageOffsetBlock from '../../../blocks/blog/blogTwoImageOffset';

function TwoImageOffsetBlockPrismic({
  background_color,
  main_image,
  secondary_image,
  ...rest
}) {
  return (
    <TwoImageOffsetBlock
      background_color={background_color}
      main_image={main_image}
      secondary_image={secondary_image}
      {...rest}
    />
  );
}

export const query = graphql`
  fragment TwoImageOffset on PrismicBlogBodyTwoImageOffsetBlock {
    id
    slice_type
    primary {
      background_color
      main_image {
        alt
        url
        dimensions {
          height
          width
        }
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      secondary_image {
        alt
        url
        dimensions {
          height
          width
        }
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default TwoImageOffsetBlockPrismic;

export const getInTouchSubText =
  'We love to hear from you, whether it be for a project, to have a conversation or to pop in and have a cup of tea, we love a good chat.';
export const alternateEmailText =
  'Alternatively Email us on info@reloaddigital.co.uk or Call us instead at 020 7734 8273';

export const fields = ['name', 'email', 'phone', 'reason', 'message'];

export const networkErrorMessage = 'Network error, try again!';

export const errorMessage = 'Something went wrong!';

export const successMessage = 'Email sent successfully!';

export const phoneRegex = /(^\+[0-9]{2}|^\+[0-9]{2}\(0\)|^\(\+[0-9]{2}\)\(0\)|^00[0-9]{2}|^0)([0-9]{9}$|[0-9\-\s]{10}$)/;

export const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i;

import React from 'react';
import styled from '@emotion/styled';

//Component Import
import Label from '../components/Label';
import BlankSpace from '../components/blankSpace';

//Asset Import
import Placeholder from '../images/tmp/leftTextRightImageBGPlaceholder.png';
import Squiggle from '../components/squiggle';
import LazyImage from '../components/lazy-image';

const LeftTextRightImageBackgroundOuterContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const LeftTextRightImageBGContainer = styled.div`
  background-color: ${props => props.backgroundColor};
  padding-top: 150px;
  padding-bottom: 150px;
  width: 100%;

  display: flex;
  justify-content: center;
  @media (max-width: 1050px) {
    width: 100%;
    margin-left: 0px;
    padding-top: 75px;
    padding-bottom: 75px;
    margin: 0;
  }
  @media (min-width: 1400px) {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
  @media (max-width: 600px) {
    padding-bottom: 0px;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1280px) {
    width: 90%;
    margin-left: 10%;
  }
  @media (max-width: 1100px) {
    width: 90%;
    margin-left: 5%;
  }
`;

const ContentContainerInner = styled.div`
  width: 1280px;

  display: flex;
  @media (max-width: 1050px) {
    flex-wrap: wrap;
    width: 90%;
    margin: 0px;
  }
  @media (max-width: 1450px) {
    width: 100%;
    margin-left: 0;
  }
`;

const LeftContent = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  @media (max-width: 1050px) {
    width: 100%;
  }
`;

const RightContent = styled.div`
  width: 50%;
  display: flex;
  justify-content: center;
  position: relative;
  @media (max-width: 1280px) {
    display: flex;
    justify-content: center;
  }
  @media (max-width: 1050px) {
    width: 90%;
    margin-left: 5%;
    margin-top: 25px;
    display: flex;
    justify-content: center;
  }
`;

const TextBlock = styled.div``;

const Title = styled.h2`
  font-style: normal;
  font-family: Montserrat;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  max-width: 500px;
  color: ${props => props.title_text_color};
  margin: 0;
  @media (max-width: 1050px) {
    width: 100%;
    max-width: unset;
  }
`;

const Paragraph = styled.p`
  max-width: 500px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  letter-spacing: 0.01em;
  color: ${props => props.paragraph_text_color};
  @media (max-width: 1050px) {
    width: 100%;
    max-width: unset;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  @media (max-width: 600px) {
    padding-bottom: 30px;
    width: inherit;
  }
`;

const ImageInnerContainer = styled.div`
  position: relative;
  width: 399px;
  height: 475px;
  @media (max-width: 600px) {
    height: auto;
    width: inherit;
  }
`;

const RightImage = styled.img`
  position: relative;
`;

const SquiggleContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0px;
  right: -125px;
  margin-top: -150px;
  z-index: 2;
  mix-blend-mode: multiply;
  transform: scale(0.8) rotate(5deg);
`;

const ImgContainer = styled.div`
  height: 475px;
  width: 400px;
  overflow: hidden;
  position: relative;
  @media (max-width: 600px) {
    width: 90%;
    margin: 0%;
    margin-left: 5%;
    height: auto;
  }
`;

const leftTextRightImageBG = ({
  props,
  label_text,
  label_color,
  title_text,
  paragraph_text,
  squiggle_color,
  image_height,
  background_color,
  image,
  title_text_color,
  paragraph_text_color,
}) => (
  <LeftTextRightImageBackgroundOuterContainer>
    <LeftTextRightImageBGContainer backgroundColor={background_color}>
      <ContentContainer>
        <ContentContainerInner>
          <LeftContent>
            <TextBlock>
              <Label text={label_text} color={label_color} />
              <BlankSpace height="20px" />
              <Title title_text_color={title_text_color}>{title_text}</Title>
              <BlankSpace height="40px" />
              <Paragraph paragraph_text_color={paragraph_text_color}>
                {paragraph_text}
              </Paragraph>
            </TextBlock>
          </LeftContent>
          <RightContent>
            <ImageContainer>
              <ImageInnerContainer>
                <ImgContainer>{image && <LazyImage {...image} />}</ImgContainer>
              </ImageInnerContainer>
            </ImageContainer>
          </RightContent>
        </ContentContainerInner>
      </ContentContainer>
    </LeftTextRightImageBGContainer>
  </LeftTextRightImageBackgroundOuterContainer>
);

export default leftTextRightImageBG;

import React from 'react';

const css = `
  .svgElements {
    fill-opacity: 1;
    position: absolute;
    top: 0;
    right: 0;
    max-width: 100px;
    z-index: 9; 
  }  
  
  @media (max-width: 965px) {
    .svgElements {
    max-width: 80px;
  }
}
@media (max-width: 735px) {
    .svgElements {  
  opacity: 0.1;
  z-index: 1;
}
}
`;

class WhnBg extends React.Component {
  render() {
    return (
      <svg
        width="180"
        height="344"
        viewBox="0 0 180 344"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="svgElements"
      >
        <defs>
          <style>{css}</style>
        </defs>
        <path
          d="M90.1624 78.0799C75.4723 102.107 61.6568 129.171 56.3936 157.037C51.8984 180.836 60.0743 188.892 81.1704 176.154C96.2791 167.031 110.88 156.573 124.717 145.629C125.968 144.64 137.037 132.99 140.14 133.79C145.382 135.141 129.316 186.375 128.575 189.549C126.874 196.833 122.622 208.967 124.001 216.916C125.575 225.989 147.185 210.012 147.867 209.554C157.187 203.302 247.307 125.178 248.718 127.046C253.939 133.961 240.336 162.718 238.476 167.919C228.416 196.047 215.762 223.341 206.668 251.804C200.987 269.587 191.115 300.433 211.828 311.564"
          stroke="#FF22EE"
          strokeWidth="33.2569"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }
}

export default WhnBg;

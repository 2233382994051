import React from 'react';
import styled from '@emotion/styled';
import LazyImage from '../../components/lazy-image';

const ImageCustomSizeContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageCustomSizeContainerInner = styled.div`
  display: none;
  @media (min-width: ${props => props.breakpoint}) {
    display: block;
    height: ${props => props.height};
    width: ${props => props.width};
  }
`;

const ImageCustomSize = props => (
  <ImageCustomSizeContainer>
    <ImageCustomSizeContainerInner
      height={props.height + 'px'}
      width={props.width + 'px'}
      breakpoint={props.breakpoint + 'px'}
    >
      {props.image && <LazyImage {...props.image} />}
    </ImageCustomSizeContainerInner>
  </ImageCustomSizeContainer>
);

export default ImageCustomSize;

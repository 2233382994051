import React from 'react';
import { graphql } from 'gatsby';

import RightTwoTextLeftImage from '../../blocks/rightTwoTextLeftImage';

function RightTwoTextLeftImagePrismic({
  text_block_1_title,
  text_block_1_paragraph,
  text_block_1_cta_text,
  text_block_1_cta_link,
  text_block_2_title,
  text_block_2_paragraph,
  text_block_2_cta_text,
  text_block_2_cta_link,
  block_color_theme,
  image,
}) {
  return (
    <RightTwoTextLeftImage
      text_block_1_title={text_block_1_title}
      text_block_1_paragraph={text_block_1_paragraph}
      text_block_1_cta_text={text_block_1_cta_text}
      text_block_1_cta_link={text_block_1_cta_link.url}
      text_block_2_title={text_block_2_title}
      text_block_2_paragraph={text_block_2_paragraph}
      text_block_2_cta_text={text_block_2_cta_text}
      text_block_2_cta_link={text_block_2_cta_link.url}
      block_color_theme={block_color_theme}
      image={image}
    />
  );
}

export const query = graphql`
  fragment RightTwoTextLeftImage on PrismicPageBodyRightTwoTextLeftImage {
    id
    slice_type
    primary {
      block_color_theme
      text_block_1_title {
        text
      }
      text_block_1_paragraph {
        text
      }
      text_block_1_cta_text {
        text
      }
      text_block_1_cta_link {
        url
        slug
      }
      text_block_2_title {
        text
      }
      text_block_2_paragraph {
        text
      }
      text_block_2_cta_text {
        text
      }
      text_block_2_cta_link {
        url
        slug
      }
      image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default RightTwoTextLeftImage;

import React from 'react';
import styled from '@emotion/styled';

const BlogAnchorContainer = styled.div`
  scroll-padding: 160px;
  padding-top: 160px;
  margin-top: -160px;
`;

const BlogAnchorScreenReader = styled.div`
  display: none;
  height: 1px;
`;

const BlogAnchor = props => (
  <BlogAnchorContainer>
    <a
      name={'#' + props.anchor_id.replace(/\s/g, '').toLowerCase()}
      style={{
        scrollPadding: '160px',
        paddingTop: '160px',
        marginTop: '160px',
      }}
      id={props.anchor_id.replace(/\s/g, '').toLowerCase()}
    ></a>
    <BlogAnchorScreenReader>Anchor to {props.anchor_id}</BlogAnchorScreenReader>
  </BlogAnchorContainer>
);

export default BlogAnchor;

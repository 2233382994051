import React from 'react';
import { graphql } from 'gatsby';

import SocialImagesBlock from '../../blocks/socialImagesBlock';

function PrismicSocialImagesBlock({
  items,
  block_color_theme,
  title,
  subtitle,
}) {
  return (
    <SocialImagesBlock
      items={items}
      block_color_theme={block_color_theme}
      title={title}
      subtitle={subtitle}
    />
  );
}

export const query = graphql`
  fragment SocialImagesBlock on PrismicPageBodySocialImagesBlock {
    id
    slice_type
    primary {
      block_color_theme
      title {
        text
      }
      subtitle {
        text
      }
    }
    items {
      image {
        alt
        url
localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default PrismicSocialImagesBlock;

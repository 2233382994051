import styled from '@emotion/styled';

export const MemberName = styled.div`
  color: #ffffff;
  font-size: 16px;
  margin-bottom: 0.5rem;
  @media (max-width: 975px) {
    font-size: 12px;
    line-height: 14px;
  }
  @media (max-width: 380px) {
    font-size: 10px;
    line-height: 12px;
  }
`;

export const MemberEmail = styled.div`
  letter-spacing: 0.01em;
  font-size: 22px;
  color: #ffffff;
  font-weight: 600;
  @media (max-width: 975px) {
    font-size: 10px;
  }
  @media (max-width: 350px) {
    font-size: 8px;
    line-height: 10px;
  }
`;

export const OurGrowthTeamContainer = styled.div`
  background: #fafafa;
  margin: 3rem;
  padding: 5rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 965px) {
    margin: 1rem;
    padding: 3rem 2rem;
  }
  @media (max-width: 735px) {
    padding: 1rem;
  }
`;

export const TextContent = styled.p`
  margin: 3rem 1rem;
  color: #ffffff;
  font-size: 18px;

  font-weight: 500;
  @media (max-width: 965px) {
    font-size: 14px;
    text-align: center;
    line-height: 22px;
    margin: 1.5rem 0.5rem;
  }
  @media (max-width: 735px) {
  }
`;
export const TeamMembersWrap = styled.div`
  width: 100%;
  display: flex;
  max-width: 700px;
  justify-content: space-between;
  @media (max-width: 735px) {
    justify-content: center;
    flex-wrap: wrap;
  }
`;

export const GrowthTeamSectionMedia = styled.div`
  width: 300px;
  height: 425px;
  overflow: hidden;
  margin-bottom: 2rem;
  position: relative;
  transition: 0.5s all;
  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  @media (max-width: 735px) {
    width: 200px;
    height: 235px;
    margin-bottom: 1rem;
  }
  @media (max-width: 435px) {
    width: 130px;
    height: 160px;
  }
  @media (max-width: 350px) {
    width: 100px;
    height: 120px;
  }
`;
export const GrowthTeamSection = styled.section`
  @media (max-width: 735px) {
    margin-bottom: 2rem;
    width: 210px;
  }
  @media (max-width: 435px) {
    width: 140px;
  }
  @media (max-width: 350px) {
    width: 110px;
  }
`;
export const SectionContainer = styled.div`
  display: flex;

  max-width: 1220px;
  box-sizing: border-box;
  @media (max-width: 965px) {
    flex-wrap: wrap;
  }
  @media (max-width: 735px) {
  }
`;

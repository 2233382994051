import React from 'react';

class RightArrow extends React.Component {
  render() {
    return (
      <svg
        width="9"
        height="9"
        viewBox="0 0 9 9"
        fill={this.props.fill}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7.5 3.63398C8.16667 4.01888 8.16667 4.98113 7.5 5.36603L3.75 7.53109C3.08333 7.91599 2.25 7.43486 2.25 6.66506L2.25 2.33493C2.25 1.56513 3.08333 1.08401 3.75 1.46891L7.5 3.63398Z" />
      </svg>
    );
  }
}

export default RightArrow;

import React from 'react';
import convertHtml from '../utils/convertHtml';
import styled from '@emotion/styled';

const CustomHtmlContainer = styled.div``;

const CustomHtml = ({ custom_html }) => (
  <CustomHtmlContainer
    dangerouslySetInnerHTML={convertHtml(custom_html.text)}
  ></CustomHtmlContainer>
);

export default CustomHtml;

import React from 'react';
import { graphql } from 'gatsby';

import BackgroundLeftTextRightImage from '../../blocks/leftTextRightImageWithBackground';

function PrismicBackgroundLeftTextRightImage({
  label_text,
  label_color,
  title_text,
  paragraph_text,
  squiggle_color,
  background_color,
  title_text_color,
  paragraph_text_color,
  image,
  customData,
}) {
  return (
    <BackgroundLeftTextRightImage
      label_text={label_text.text}
      background_color={background_color}
      label_color={label_color}
      title_text={title_text.text}
      paragraph_text={paragraph_text.text}
      squiggle_color={squiggle_color}
      image={image}
      title_text_color={title_text_color}
      paragraph_text_color={paragraph_text_color}
    />
  );
}

export const query = graphql`
  fragment BackgroundLeftTextRightImage on PrismicPageBodyBackgroundLeftTextRightImage {
    id
    slice_type
    primary {
      label_text {
        text
      }
      label_color
      title_text {
        text
      }
      paragraph_text {
        text
      }
      background_color
      squiggle_color
      title_text_color
      paragraph_text_color
      image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 450) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default PrismicBackgroundLeftTextRightImage;

import React from 'react';
import { graphql } from 'gatsby';

import ReviewTwo from '../../blocks/whyReload/reviewBlockTwo';

function ReviewTwoPrismic({
  block_color_theme,
  star_count,
  review_text,
  review_author,
  review_provider_logo,
}) {
  return (
    <ReviewTwo
      block_color_theme={block_color_theme}
      star_count={star_count}
      review_text={review_text.text}
      review_author={review_author.text}
      review_provider_logo={review_provider_logo}
    />
  );
}

export const query = graphql`
  fragment ReviewTwo on PrismicPageBodyReview2 {
    id
    slice_type
    primary {
      block_color_theme
      star_count
      review_text {
        text
      }
      review_author {
        text
      }
      review_provider_logo {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default ReviewTwo;

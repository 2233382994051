import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import range from 'lodash/range';
import linkStripper from '../../utils/link-stripper';
import {
  WhyFiveStarDiv,
  StarDiv,
  StarText,
  StarSubText,
  StarTextSection,
  RecognitionDiv,
  WhyFiveStarDivWrap,
} from './style';
import TitleUnderlined from '../../components/TitleUnderlined';
import FiveStar from '../../images/assets/fivestar.svg';
import WhnBGRight from './whnBGRight';
import Star from '../../images/assets/star.svg';
import ButtonNotUnderlined from '../../components/ButtonNotUnderlined';
import Squiggle from '../../components/squiggle';

const StarContainer = styled(motion.div)``;

const ScoreContainer = styled(motion.div)`
  display: flex;
`;

const container = {
  hidden: { opacity: 0, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.075,
    },
  },
};

const item = {
  hidden: { x: -20, opacity: 0, scale: 7 },
  visible: {
    x: 0,
    opacity: 1,
    scale: 1,
  },
};

const SquiggleContainer = styled.div`
  position: absolute;
  right: 0;
  margin-right: -10%;
  @media (max-width: 1200px) {
    margin-right: -15%;
  }
  @media (max-width: 1000px) {
    display: none;
  }
`;

export class RecognitionSplashBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };
  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <WhyFiveStarDivWrap>
          <WhyFiveStarDiv>
            <StarDiv>
              <ScoreContainer
                variants={container}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                {range(0, parseInt(this.props.star_count)).map(index => (
                  <StarContainer key={index} variants={item}>
                    <Star
                      fill={this.props.block_color_theme}
                      style={{
                        marginLeft: '1.5px',
                        marginRight: '1.5px',
                      }}
                    />
                  </StarContainer>
                ))}
              </ScoreContainer>
            </StarDiv>
            <StarTextSection>
              <StarText>
                <TitleUnderlined
                  titleText={this.props.title.text}
                  fontSize="58px"
                  tabletFontSize="26px"
                  tableLineHeight="28px"
                  mobileFontSize="26px"
                  mobileLineHeight="22px"
                  lineHeight="64px"
                  margin="0 auto"
                  underlineColor={this.props.block_color_theme}
                  h2Mode={true}
                />
              </StarText>
            </StarTextSection>
            <StarSubText>{this.props.subtitle.text}</StarSubText>
            <ButtonNotUnderlined
              color={'#ffffff'}
              arrowColor={this.props.block_color_theme}
              link={linkStripper(this.props.cta_link.url)}
              buttonText={this.props.cta_text.text}
              target={this.props.cta_link.target}
            />
          </WhyFiveStarDiv>
        </WhyFiveStarDivWrap>
      </VisibilitySensor>
    );
  }
}

export default RecognitionSplashBlock;

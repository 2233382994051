import React from 'react';
import { graphql } from 'gatsby';

import ServicesListExpanded from '../../blocks/servicesListExpanded';

function ServicesListExpandedPrismic({}) {
  return <ServicesListExpanded />;
}

export const query = graphql`
  fragment ServicesListExpanded on PrismicPageBodyServicesListExpanded {
    id
    slice_type
  }
`;

export default ServicesListExpanded;

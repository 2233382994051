import React from 'react';
import { graphql } from 'gatsby';

import BlankSpace from '../../components/blankSpacePrismic';

function PrismicBlankSpace({
  desktop_height,
  mobile_height,
  space_background_color,
}) {
  return (
    <BlankSpace
      desktop_height={desktop_height}
      space_background_color={space_background_color}
      mobile_height={mobile_height}
    />
  );
}

export const query = graphql`
  fragment BlankSpace on PrismicPageBodyBlankSpace {
    id
    slice_type
    primary {
      desktop_height
      mobile_height
      space_background_color
    }
  }
`;

export default PrismicBlankSpace;

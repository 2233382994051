import React from 'react';
import { graphql } from 'gatsby';

import HomepageHeader from '../../blocks/homepageHeader';

function HomepageHeaderPrismic({
  title_text,
  cta_text,
  cta_color,
  case_study_cta_text,
  cta_link,
  case_study_cta_link,
  case_study_color_theme,
  title_text_color,
  subtitle_text_color,
  subtitle_text,
  customData,
  items,
}) {
  return (
    <HomepageHeader
      title_text={title_text}
      title_text_color={title_text_color}
      subtitle_text={subtitle_text}
      subtitle_text_color={subtitle_text_color}
      cta_text={cta_text}
      cta_color={cta_color}
      cta_link={cta_link}
      cta_link_target={cta_link.target}
      case_study_cta_text={case_study_cta_text}
      case_study_cta_link={case_study_cta_link}
      case_study_cta_link_target={case_study_cta_link.target}
      case_study_color_theme={case_study_color_theme}
      pageData={customData.prismicPage.data}
      items={items}
    />
  );
}

export const query = graphql`
  fragment HomepageHeader on PrismicPageBodyHomepageHeader {
    id
    slice_type
    primary {
      title_text {
        text
      }
      title_text_color
      subtitle_text {
        text
      }
      subtitle_text_color
      cta_text {
        text
      }
      cta_link {
        url
        slug
        target
      }
      cta_color
      case_study_cta_text {
        text
      }
      case_study_cta_link {
        url
        slug
        target
      }
      case_study_color_theme
    }
    items {
      carousel_image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default HomepageHeaderPrismic;

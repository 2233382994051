import React from 'react';
import { graphql } from 'gatsby';

import BlogAnchorsBlock from '../../../blocks/blog/blogAnchorsBlock';

function BlogAnchorsBlockPrismic({ title, items, ...rest }) {
  return <BlogAnchorsBlock title={title} items={items} {...rest} />;
}

export const query = graphql`
  fragment BlogAnchorsBlock on PrismicBlogBodyAnchorsBlock {
    id
    slice_type
    primary {
      title {
        html
        raw
        text
      }
    }
    items {
      anchor_title {
        html
        text
      }
      anchor_link {
        url
        target
      }
    }
  }
`;

export default BlogAnchorsBlockPrismic;

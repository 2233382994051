import React from 'react';
import { graphql } from 'gatsby';

import ClientList from '../../blocks/clientList';

function ClientListPrismic({ items, title_text, title_subtext, color_theme }) {
  return (
    <>
      <ClientList
        items={items}
        title_text={title_text.text}
        title_subtext={title_subtext.text}
        color_theme={color_theme}
      />
    </>
  );
}

export const query = graphql`
  fragment ClientList on PrismicPageBodyClientList {
    id
    slice_type
    primary {
      title_text {
        text
      }
      title_subtext {
        text
      }
      color_theme
    }
    items {
      client_logo {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default ClientList;

import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import dashedLine from '../../images/assets/rightToLeftDash.png';
import LazyImage from '../../components/lazy-image';
import ButtonUnderlined from '../../components/ButtonUnderlined';
import ButtonNotUnderlined from '../../components/ButtonNotUnderlined';

const RightImageLeftContentContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 85px;
  margin-bottom: 85px;
  @media (max-width: 800px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    margin-top: 40px;
    margin-bottom: 40px;
  }
`;

const ImageContainer = styled(motion.div)`
  width: 35%;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  @media (max-width: 800px) {
    width: 100%;
    justify-content: center;
    margin-top: 50px;
  }
`;

const ImageItemHolder = styled.div`
  width: 360px;
  height: 425px;
`;

const TextContainer = styled(motion.div)`
  width: 65%;
  padding-left: 60px;
  display: flex;
  align-items: center;
  @media (max-width: 1325px) {
    padding-left: 120px;
  }

  @media (max-width: 950px) {
    padding-left: 60px;
    width: 80%;
  }
  @media (max-width: 800px) {
    width: 100%;
    padding-left: 0px;
  }
`;

const TextContainerInner = styled.div``;

const ImageItem = styled.img``;

const NumberContainer = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #ff22ee;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  letter-spacing: 0.15em;
  color: ${props => props.color};
`;

const NumberLine = styled.div`
  width: 20px;
  height: 2px;
  margin-left: 10px;
  background: ${props => props.color};
`;

const Title = styled.h2`
  font-weight: 800;
  font-size: 28px;
  line-height: 48px;
  color: #ffffff;
  margin-bottom: 30px;
  font-style: normal;
  font-family: Montserrat;
`;

const Paragraph = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-bottom: 40px;
`;

const DashedLineContainer = styled(motion.div)`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    display: none;
  }
`;

const DashedLineContainerOuter = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const DashedLine = styled.img`
  width: 65%;
  height: 200px;
`;

const MobileSpacer = styled.div`
  @media (max-width: 800px) {
    height: 50px;
  }
`;

const textAnim = {
  hidden: { opacity: 0, x: 20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.15,
    },
  },
};

const imageAnim = {
  hidden: { opacity: 0, x: 20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.3,
    },
  },
};

const lineAnim = {
  hidden: { opacity: 0, x: 0 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.5,
    },
  },
};

export class leftImageRightContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.props.displayLine ? (
          <React.Fragment>
            <MobileSpacer />
          </React.Fragment>
        ) : null}
        <VisibilitySensor
          active={this.state.visibilitySensorActive}
          onChange={this.handleVisibilityChange}
          partialVisibility
          minTopValue={250}
        >
          <RightImageLeftContentContainer>
            <ImageContainer
              variants={imageAnim}
              initial="hidden"
              animate={this.state.visible ? 'visible' : 'hidden'}
            >
              <ImageItemHolder>
                {this.props.image && <LazyImage {...this.props.image} />}
              </ImageItemHolder>
            </ImageContainer>
            <TextContainer
              variants={textAnim}
              initial="hidden"
              animate={this.state.visible ? 'visible' : 'hidden'}
            >
              <TextContainerInner>
                <NumberContainer color={this.props.colorTheme}>
                  {this.props.number > 9 ? (
                    <React.Fragment>{this.props.number}</React.Fragment>
                  ) : (
                    <React.Fragment>0{this.props.number}</React.Fragment>
                  )}
                  <NumberLine color={this.props.colorTheme} />
                </NumberContainer>
                <Title>{this.props.title}</Title>
                <Paragraph>{this.props.paragraph}</Paragraph>
                {this.props.cta_link && (
                  <ButtonNotUnderlined
                    link={this.props.cta_link}
                    color={this.props.cta_text_color}
                    buttonText={this.props.cta_text}
                    arrowColor={this.props.cta_arrow_color}
                    target={this.props.cta_link_target}
                  />
                )}
              </TextContainerInner>
            </TextContainer>
          </RightImageLeftContentContainer>
        </VisibilitySensor>
      </React.Fragment>
    );
  }
}

export default leftImageRightContent;

import React from 'react';
import { graphql } from 'gatsby';

import ClientsBlock from '../../blocks/clientsBlock/index';

function PrismicClientsBlock({
  items,
  block_color_theme,
  title,
  subtitle,
  cta_text,
  cta_link,
  client_link,
}) {
  return (
    <ClientsBlock
      items={items}
      block_color_theme={block_color_theme}
      title={title}
      subtitle={subtitle}
      cta_text={cta_text}
      cta_link={cta_link}
      client_link={client_link}
    />
  );
}

export const query = graphql`
  fragment ClientsBlock on PrismicPageBodyClientsBlock {
    id
    slice_type
    primary {
      block_color_theme
      title {
        text
      }
      subtitle {
        text
      }
      cta_text {
        text
      }
      cta_link {
        url
      }
    }
    items {
      client_name {
        text
      }
      client_bio {
        text
      }
      client_link {
        url
        target
      }
      client_image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default PrismicClientsBlock;

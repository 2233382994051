import React from 'react';
import { graphql } from 'gatsby';

import LargeReviewSlider from '../../blocks/largeReviewSlider';

function LargeReviewSliderPrismic({
  block_color_theme,
  label_text,
  title,
  subtitle,
  star_count,
  review_count,
  review_source_logo,
  items,
}) {
  return (
    <LargeReviewSlider
      items={items}
      block_color_theme={block_color_theme}
      label_text={label_text}
      title={title}
      subtitle={subtitle}
      star_count={star_count}
      review_count={review_count}
      review_source_logo={review_source_logo}
    />
  );
}

export const query = graphql`
  fragment LargeReviewSlider on PrismicPageBodyLargeReviewSlider {
    id
    slice_type
    primary {
      block_color_theme
      label_text {
        text
      }
      title {
        text
      }
      subtitle {
        text
      }
      star_count
      review_source_logo {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
    items {
      review_text {
        text
      }
      review_author {
        text
      }
    }
  }
`;

export default LargeReviewSlider;

import React from 'react';
import { graphql } from 'gatsby';

import LeftTextRightImage from '../../blocks/leftTextRightImage';

function PrismicLeftTextRightImage({
  label_title,
  label_colour,
  title_text,
  paragraph_text,
  right_aligned_image,
  theme_color,
  image_height,
}) {
  return (
    <LeftTextRightImage
      label_title={label_title.text}
      label_colour={label_colour}
      theme_color={theme_color}
      title_text={title_text.text}
      paragraph_text={paragraph_text.text}
      right_aligned_image={right_aligned_image}
    />
  );
}

export const query = graphql`
  fragment LeftTextRightImage on PrismicPageBodyLeftTextRightImage {
    id
    slice_type
    primary {
      label_title {
        text
      }
      label_colour
      theme_color
      title_text {
        text
      }
      paragraph_text {
        text
      }

      right_aligned_image {
        alt
        url
localFile {
          id
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default PrismicLeftTextRightImage;

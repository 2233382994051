import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import LazyImage from '../../components/lazy-image';
import LinkedIn from '../../images/assets/socialIcons/linkedin.svg';

const TeamMemberContainer = styled.div``;
const ImageContainer = styled.div`
  width: 300px;
  height: 425px;
  margin-right: 25px;
  margin-left: 25px;
  margin-bottom: 25px;
  position: relative;
  flex: 1 0 33.3%;
`;

const LowerDetails = styled.div`
  margin-left: 25px;
  margin-bottom: 25px;
`;

const Name = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  color: #ffffff;
`;

const JobPosition = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-top: 10px;
  max-width: 300px;
`;

const BioContainer = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
`;

const Bio = styled.div`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  margin-bottom: 20px;
`;

const Email = styled.div`
  font-family: Montserrat;
  font-weight: bold;
  font-size: 18px;
  line-height: 20px;
  color: #ffffff;
  padding-bottom: 30px;
`;

const BioContentContainerOuter = styled.div`
  display: flex;
  align-items: flex-end;

  height: 100%;
  padding-left: 25px;
  padding-right: 25px;
`;

const container = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const BioContentContainerInner = styled.div``;

const LinkedinContainer = styled.a`
  height: 20px;
  width: 20px;
`;

const LinkedinContainerOuter = styled.div`
  margin-bottom: 15px;
  cursor: pointer;
`;

export class TeamMember extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
    };
  }

  handleVisibilityChange = () => {
    if (this.state.visible === true) {
      this.setState({
        visible: false,
      });
    } else {
      this.setState({
        visible: true,
      });
    }
  };

  handleVisibilityChangeForced = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <TeamMemberContainer key={this.props.id}>
        <ImageContainer>
          <BioContainer
            variants={container}
            onTap={this.handleVisibilityChange}
            onHoverStart={this.handleVisibilityChange}
            onHoverEnd={this.handleVisibilityChangeForced}
            animate={this.state.visible ? 'visible' : 'hidden'}
          >
            <BioContentContainerOuter>
              <BioContentContainerInner>
                <Bio>{this.props.data.bio.text}</Bio>
                <LinkedinContainerOuter>
                  <LinkedinContainer
                    href={this.props.data.linkedin_link.url}
                    target={this.props.data.linkedin_link.target}
                  >
                    <LinkedIn height="20px" width="20px" />
                  </LinkedinContainer>
                </LinkedinContainerOuter>
                <Email>
                  <a href={'mailto:' + this.props.data.email_address.text}>
                    {this.props.data.email_address.text}
                  </a>
                </Email>
              </BioContentContainerInner>
            </BioContentContainerOuter>
          </BioContainer>
          {this.props.data.employee_photo && (
            <LazyImage {...this.props.data.employee_photo} />
          )}
        </ImageContainer>
        <LowerDetails>
          <Name>{this.props.data.name.text}</Name>
          <JobPosition>{this.props.data.job_position.text}</JobPosition>
        </LowerDetails>
      </TeamMemberContainer>
    );
  }
}

export default TeamMember;

import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import ClientBlock from '../clientsBlock/clientBlock';
import TitleUnderlined from '../../components/TitleUnderlined';
import ButtonUnderlined from '../../components/ButtonUnderlined';
import convertHtml from '../../utils/convertHtml';
import SquiggleGenerator from '../../components/squiggle';
import Squiggle from '../../components/squiggle';

const ClientsBlockContainerOuter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
`;

const ClientsBlockContainerMaster = styled.div``;

const ClientsBlockContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ClientsBlockContainerInner = styled(motion.div)`
  display: flex;
  flex-wrap: wrap;
  max-width: 1110px;
  align-items: center;
  width: 100%;
  @media (max-width: 1200px) {
    max-width: 740px;
  }
  @media (max-width: 740px) {
    max-width: 540px;
    justify-content: center;
  }
`;

const UpperContent = styled.div`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
`;

const LowerContent = styled.div`
  margin-top: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  @media (max-width: 600px) {
    margin-top: 30px;
  }
`;
const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;
const Subtitle = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 75px;
  @media (max-width: 600px) {
    margin-bottom: 50px;
  }
`;

const LeftSquiggle = styled.div`
  position: absolute;
  top: 0;
  left: -15vw;
  transform: rotate(-25deg);
  @media (max-width: 1050px) {
    display: none;
  }
`;

const RightSquiggle = styled.div`
  position: absolute;
  top: 0;
  right: -10vw;
  transform: scale(1) rotate(-10deg);
  @media (max-width: 1050px) {
    display: none;
  }
`;

const ClientsBlockItemContainer = styled(motion.a)``;

const container = {
  hidden: { opacity: 1, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.075,
    },
  },
};

const clientItem = {
  hidden: { x: -20, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
  },
};

export class ClientsBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <React.Fragment>
          <ClientsBlockContainerOuter>
            <ClientsBlockContainerMaster>
              <UpperContent>
                <TitleContainer>
                  <TitleUnderlined
                    underlineColor={this.props.block_color_theme}
                    textColor={'#ffffff'}
                    titleText={'<span>' + this.props.title.text + '</span>'}
                    fontSize={'58px'}
                    mobileFontSize={'45px'}
                    h2Mode={true}
                  />
                </TitleContainer>
                <Subtitle
                  dangerouslySetInnerHTML={convertHtml(
                    this.props.subtitle.text
                  )}
                />
              </UpperContent>
              <ClientsBlockContainer>
                <ClientsBlockContainerInner
                  variants={container}
                  initial="hidden"
                  animate={this.state.visible ? 'visible' : 'hidden'}
                >
                  {this.props.items.map((item, index) => (
                    <ClientsBlockItemContainer
                      key={index}
                      variants={clientItem}
                      href={item.client_link.url}
                      target={item.client_link.target}
                    >
                      <ClientBlock data={item} id={index} />
                    </ClientsBlockItemContainer>
                  ))}
                </ClientsBlockContainerInner>
              </ClientsBlockContainer>
              <LowerContent>
                <ButtonUnderlined
                  color={this.props.block_color_theme}
                  buttonText={this.props.cta_text.text}
                  link={this.props.cta_link.url}
                />
              </LowerContent>
            </ClientsBlockContainerMaster>
          </ClientsBlockContainerOuter>
        </React.Fragment>
      </VisibilitySensor>
    );
  }
}

export default ClientsBlock;

import React from 'react';
import { graphql } from 'gatsby';

import MeetTheTeam from '../../blocks/meetTheTeam/index';

function MeetTheTeamPrismic({
  items,
  block_color_theme,
  title,
  subtitle,
  linkedin_link,
}) {
  return (
    <MeetTheTeam
      items={items}
      block_color_theme={block_color_theme}
      title={title}
      subtitle={subtitle}
      linkedin_link={linkedin_link}
    />
  );
}

export const query = graphql`
  fragment MeetTheTeam on PrismicPageBodyMeetTheTeam {
    id
    slice_type
    primary {
      block_color_theme
      title {
        text
      }
      subtitle {
        text
      }
    }
    items {
      name {
        text
      }
      job_position {
        text
      }
      bio {
        text
      }
      email_address {
        text
      }
      linkedin_link {
        url
        target
      }
      employee_photo {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default MeetTheTeam;

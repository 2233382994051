import React from 'react';
import { graphql } from 'gatsby';

import BlogAnchor from '../../../blocks/blog/blogAnchor';

function BlogAnchorPrismic({ anchor_id }) {
  return <BlogAnchor anchor_id={anchor_id.raw} />;
}

export const query = graphql`
  fragment BlogAnchor on PrismicBlogBodyAnchor {
    id
    slice_type
    primary {
      anchor_id
    }
  }
`;

export default BlogAnchor;

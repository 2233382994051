import React from 'react';
import { graphql } from 'gatsby';

import RightLinksLeftSubtitle from '../../blocks/rightLinksLeftSubtitle';

function RightLinksLeftSubtitlePrismic({
  items,
  title,
  subtitle_heading,
  subtitle_text,
  link_theme,
}) {
  return (
    <RightLinksLeftSubtitle
      items={items}
      title={title.text}
      subtitle_heading={subtitle_heading.text}
      subtitle_text={subtitle_text.text}
      link_theme={link_theme}
    />
  );
}

export const query = graphql`
  fragment RightLinksLeftSubtitlePrismic on PrismicPageBodyRightLinksLeftSubtitle {
    id
    slice_type
    primary {
      title {
        text
      }
      subtitle_heading {
        text
      }
      subtitle_text {
        text
      }
      link_theme
    }
    items {
      link_text {
        text
      }
      link {
        url
        slug
        target
      }
    }
  }
`;

export default RightLinksLeftSubtitlePrismic;

import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import ButtonNotUnderlined from '../components/ButtonNotUnderlined';
import range from 'lodash/range';
import linkStripper from '../utils/link-stripper';

//Asset Import
import Star from '../images/assets/star.svg';

const ReviewContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  overflow: hidden;
  margin-top: 30px;
  margin-bottom: 30px;
  @media (max-width: 900px) {
    background-color: #131313;
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const ReviewContainerInner = styled.div`
  background-color: #131313;
  width: 100%;
  max-width: 1150px;
  padding: 150px;

  @media (max-width: 1250px) {
    width: 90%;
  }
  @media (max-width: 900px) {
    padding: 0px;
  }
`;

const ReviewHeader = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-wrap: wrap;
  }
`;

const ReviewScore = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 15px;
    width: 100%;
  }
`;

const ScoreContainer = styled(motion.div)`
  display: flex;
  margin-left: 15px;
`;

const ReviewTextContainer = styled.div``;

const ReviewText = styled.div`
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 48px;
  color: #ffffff;
  margin-top: 50px;
  margin-bottom: 30px;
  @media (max-width: 1000px) {
    font-size: 22px;
    line-height: 32px;
  }
`;

const Reviewee = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: #aaaaaa;
`;

const LogoImg = styled.img`
  width: 180px;

  @media (max-width: 768px) {
    width: 130px;
  }
`;

const StarContainer = styled(motion.div)``;
const container = {
  hidden: { opacity: 0, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.125,
    },
  },
};

const item = {
  hidden: { x: -20, opacity: 0, scale: 7 },
  visible: {
    x: 0,
    opacity: 1,
    scale: 1,
  },
};

export class Review extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };
  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <ReviewContainer color={this.props.block_background_color}>
          <ReviewContainerInner color={this.props.block_background_color}>
            <ReviewHeader>
              <ReviewScore>
                <LogoImg src={this.props.review_logo} />
                <ScoreContainer
                  variants={container}
                  initial="hidden"
                  animate={this.state.visible ? 'visible' : 'hidden'}
                >
                  {range(0, this.props.review_score).map(index => (
                    <StarContainer key={index} variants={item}>
                      <Star fill={this.props.review_color_theme} />
                    </StarContainer>
                  ))}
                </ScoreContainer>
              </ReviewScore>
              <ButtonNotUnderlined
                buttonText={this.props.review_cta_text}
                arrowColor={this.props.review_color_theme}
                link={linkStripper(this.props.cta_link)}
                target={this.props.cta_link_target}
                fontSizeMobile={'16px'}
              />
            </ReviewHeader>
            <ReviewTextContainer>
              <ReviewText>{this.props.review}</ReviewText>
              <Reviewee>{this.props.review_author}</Reviewee>
            </ReviewTextContainer>
          </ReviewContainerInner>
        </ReviewContainer>
      </VisibilitySensor>
    );
  }
}

export default Review;

import React from 'react';
import { graphql } from 'gatsby';

import RectangleSplash from '../../blocks/rectangleSplash';
import urlParser from '../../utils/urlParser';

function PrismicRectangleSplash({
  title,
  cta_text,
  cta_link,
  color_theme,
  left_image,
  right_image,
}) {
  return (
    <RectangleSplash
      title={title.text}
      cta_text={cta_text.text}
      cta_link={cta_link.url}
      cta_link_target={cta_link.target}
      color_theme={color_theme}
      left_image={left_image}
      right_image={right_image}
    />
  );
}

export const query = graphql`
  fragment RectangleSplashBlock on PrismicPageBodyRectangleSplashBlock {
    id
    slice_type
    primary {
      title {
        text
      }
      cta_text {
        text
      }
      cta_link {
        url
        target
      }
      color_theme
      left_image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
      right_image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default PrismicRectangleSplash;

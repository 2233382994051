import React from 'react';
import { graphql } from 'gatsby';
import DynamicCaseStudyOne from '../../blocks/DynamicCaseStudyOne';

function DynamicCaseStudyOnePrismic({
  slice_type,
  label_title,
  label_colour,
  title_text,
  paragraph_text,
  right_aligned_image,
  right_aligned_video,
}) {
  return (
    <>
      <DynamicCaseStudyOne
        label_title={label_title.text}
        label_colour={label_colour}
        title_text={title_text.text}
        paragraph_text={paragraph_text.text}
        right_aligned_image={right_aligned_image}
        right_aligned_video={right_aligned_video}
      />
    </>
  );
}

export const query = graphql`
  fragment DynamicCaseStudyOne on PrismicPageBodyDynamicCaseStudyOne {
    id
    slice_type
    primary {
      label_colour
      label_title {
        text
      }
      title_text {
        text
      }
      paragraph_text {
        html
      }
      right_aligned_image {
        url
      }
      right_aligned_video {
        url
      }
      cta_text {
        text
      }
      cta_link {
        url
      }
      cta_color
      cta_arrow_color
    }
  }
`;

export default DynamicCaseStudyOne;

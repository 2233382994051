import React from 'react';
import { graphql } from 'gatsby';

import OurProcess from '../../blocks/ourProcess';

function OurProcessPrismic({ slice_type }) {
  return (
    <>
      <OurProcess />
    </>
  );
}

export const query = graphql`
  fragment OurProcess on PrismicPageBodyProcess {
    id
    slice_type
    primary {
      main_text {
        html
      }
      main_title {
        text
      }
    }
    items {
      text {
        html
      }
      title {
        text
      }
    }
  }
`;

export default OurProcess;

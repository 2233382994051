import React from 'react';
import { graphql } from 'gatsby';

import LeftSubtitleRightText from '../../blocks/leftSubtitleRightText';

function LeftSubtitleRightTextPrismic({
  subtitle_title,
  subtitle_text,
  label_text,
  label_color,
  paragraph_title,
  paragraph_text,
  background_color,
}) {
  return (
    <LeftSubtitleRightText
      subtitle_title={subtitle_title.text}
      subtitle_text={subtitle_text.text}
      label_text={label_text.text}
      label_color={label_color}
      paragraph_title={paragraph_title.text}
      paragraph_text={paragraph_text.text}
      background_color={background_color}
    />
  );
}

export const query = graphql`
  fragment LeftSubtitleRightText on PrismicPageBodyLeftSubtitleRightText {
    id
    slice_type
    primary {
      background_color
      subtitle_title {
        text
      }
      subtitle_text {
        text
      }
      label_text {
        text
      }
      label_color
      paragraph_title {
        text
      }
      paragraph_text {
        text
      }
    }
  }
`;

export default LeftSubtitleRightTextPrismic;

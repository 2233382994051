import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import ButtonNotUnderlined from '../components/ButtonNotUnderlined';

const ProjectBriefContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
`;

const ProjectBriefContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: center;
  padding-left: 50px;
  padding-right: 50px;
  @media (max-width: 900px) {
    flex-wrap: wrap;
    padding-left: 0px;
    padding-right: 0px;
    width: 90%;
  }
`;

const ParagraphOneContainer = styled.div`
  max-width: 350px;
  margin-right: 135px;
  @media (max-width: 1350px) {
    max-width: 500px;
    margin-right: 75px;
  }
  @media (max-width: 900px) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 25px;
    margin-top: 0px;
    margin-right: 0;
    margin-left: 0;
  }
  @media (max-width: 735px) {
    margin-top: 0px;
  }
`;

const P1Title = styled.h2`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-bottom: 10px;
`;

const P1Content = styled.div`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  color: #ffffff;
`;

const ParagraphTwoContainer = styled.div`
  max-width: 700px;
  @media (max-width: 900px) {
    width: 100%;
    max-width: 100%;
  }
`;

const P2Title = styled.h2`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
  margin-bottom: 25px;
  max-width: 600px;
  @media (max-width: 900px) {
    font-size: 18px;
    line-height: 26px;
  }
`;

const P2Content = styled.div`
  font-family: Montserrat;
  font-weight: 500;
  font-size: 18px;
  line-height: 36px;
  color: #ffffff;
  margin-bottom: 40px;
  @media (max-width: 900px) {
    font-size: 16px;
    line-height: 28px;
  }
`;

export class ProjectBrief extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };
  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <ProjectBriefContainerOuter>
          <ProjectBriefContainer>
            <ParagraphOneContainer>
              <P1Title>{this.props.paragraph_one_title.text}</P1Title>
              <P1Content>{this.props.paragraph_one.text}</P1Content>
            </ParagraphOneContainer>
            <ParagraphTwoContainer>
              <P2Title>{this.props.paragraph_two_title.text}</P2Title>
              <P2Content>{this.props.paragraph_two.text}</P2Content>
              <ButtonNotUnderlined
                buttonText={this.props.cta_title.text}
                link={this.props.cta_link.url}
                color={this.props.cta_color}
                arrowColor={this.props.cta_arrow_color}
                target={this.props.cta_link.target}
              />
            </ParagraphTwoContainer>
          </ProjectBriefContainer>
        </ProjectBriefContainerOuter>
      </VisibilitySensor>
    );
  }
}

export default ProjectBrief;

import React from 'react';
import styled from '@emotion/styled';
import Label from '../components/Label';
import BlankSpace from '../components/blankSpace';
import convertHtml from '../utils/convertHtml';
import LazyImage from '../components/lazy-image';

const RightTextContainerOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Montserrat;
  overflow: hidden;
`;

const RightTextContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1440px;
  justify-content: flex-end;
  @media (max-width: 500px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  @media (max-width: 500px) {
    display: none;
  }
`;

const RightImageContainer = styled.div`
  height: 475px;
  width: 180px;
  @media (min-width: 1440px) {
    width: 475px;
  }
`;

const MainContentContainerRight = styled.div`
  margin-right: 75px;
  height: 475px;
  display: flex;
  align-items: center;
  @media (max-width: 500px) {
    margin-right: 0px;
    height: unset;
  }
`;

const MainContentContainerRightInner = styled.div`
  height: fit-content;
`;

const Title = styled.h2`
  font-weight: 800;
  font-size: 40px;
  color: #ffffff;
  line-height: 48px;
  max-width: 500px;
  text-align: right;
  font-style: normal;
  font-family: Montserrat;
`;

const Paragraph = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 36px;
  letter-spacing: 0.01em;
  color: #ffffff;
  max-width: 500px;
  text-align: right;
  @media (max-width: 960px) {
    top: 0;
    max-width: 380px;
  }

  @media (max-width: 500px) {
    max-width: 100%;
  }
`;

const LabelContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const RightText = ({
  props,
  label_text,
  label_color,
  title_text,
  text,
  image,
}) => (
  <RightTextContainerOuter>
    <RightTextContainer>
      <MainContentContainerRight>
        <MainContentContainerRightInner>
          <LabelContainer>
            <Label color={label_color} text={label_text.text} />
          </LabelContainer>

          <BlankSpace height="30px" />
          <Title>{title_text.text}</Title>
          <BlankSpace height="30px" />
          <Paragraph dangerouslySetInnerHTML={convertHtml(text.html)} />
        </MainContentContainerRightInner>
      </MainContentContainerRight>
    </RightTextContainer>
    <ImageContainer>
      <RightImageContainer>
        {image && <LazyImage {...image} />}
      </RightImageContainer>
    </ImageContainer>
  </RightTextContainerOuter>
);

export default RightText;

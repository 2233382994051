import React from 'react';
import { graphql } from 'gatsby';

import WhatHappensNext from '../../blocks/whatHappensNext/index';

function WhatHappensNextPrismic({ items, block_color_theme, title, subtitle }) {
  return (
    <WhatHappensNext
      items={items}
      block_color_theme={block_color_theme}
      title={title.text}
      subtitle={subtitle.text}
    />
  );
}

export const query = graphql`
  fragment WhatHappensNext on PrismicPageBodyWhatHappensNext {
    id
    slice_type
    primary {
      block_color_theme
      title {
        text
      }
      subtitle {
        text
      }
    }
    items {
      card_title {
        text
      }
      card_text {
        text
      }
    }
  }
`;

export default WhatHappensNext;

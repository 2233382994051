import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';

//Component Import
import ButtonNotUnderlined from '../components/ButtonNotUnderlined';
import linkStripper from '../utils/link-stripper';

//Asset Imports
import RightArrow from '../images/assets/rightArrow.svg';

const RightLinksLeftSubtitleContainer = styled.div`
  display: flex;
  overflow: hidden;
  @media (max-width: 1050px) {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }

  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
`;

const LeftContent = styled.div`
  width: 50%;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const LeftContentInner = styled.div`
  width: fit-content;
  margin-right: 150px;
  @media (max-width: 800px) {
    width: 100%;
    margin-right: 0px;
  }
`;

const Subtitle = styled.div`
  font-size: 24px;
  line-height: 36px;
  font-weight: 800;
  text-align: right;
  max-width: 250px;
  font-family: Montserrat;
  font-style: normal;
  color: #ffffff;
  @media (max-width: 800px) {
    text-align: left;
    max-width: 100%;
  }
`;

const SubtitleText = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  text-align: right;
  max-width: 250px;
  color: #ffffff;
  @media (max-width: 800px) {
    text-align: left;
    max-width: 100%;
    margin-bottom: 25px;
  }
  @media (max-width: 655px) {
    margin-bottom: 0px;
  }
`;

const Title = styled.h2`
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  font-family: Montserrat;
  font-style: normal;
  color: #ffffff;
  max-width: 450px;
  margin-bottom: 55px;
  @media (max-width: 800px) {
    font-size: 20px;
  }
  @media (max-width: 655px) {
    display: none;
  }
`;

const RightContent = styled.div`
  width: 50%;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const LinkItem = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: ${props => props.color};
  margin-right: 15px;
`;

const LinkItemContainer = styled.div`
  display: flex;
  margin-bottom: 25px;
  align-items: center;
`;

const LinkItemContainerOuter = styled(motion.div)``;

const LinkItemContainerInner = styled(motion.div)``;

const container = {
  hidden: { opacity: 1, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.1,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export class RightLinksLeftSubtitle extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
      buttonActive: false,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <RightLinksLeftSubtitleContainer>
          <LeftContent>
            <LeftContentInner>
              <Subtitle>{this.props.subtitle_heading}</Subtitle>
              <SubtitleText>{this.props.subtitle_text}</SubtitleText>
            </LeftContentInner>
          </LeftContent>
          <RightContent>
            <Title>{this.props.title}</Title>
            <LinkItemContainerOuter
              variants={container}
              initial="hidden"
              animate={this.state.visible ? 'visible' : 'hidden'}
            >
              {this.props.items.map((link, index) => (
                <LinkItemContainerInner key={index} variants={item}>
                  <ButtonNotUnderlined
                    color={this.props.link_theme}
                    link={linkStripper(link.link.url)}
                    arrowColor={this.props.link_theme}
                    buttonText={link.link_text.text}
                    margin={'20px 0px 20px 0px'}
                    marginMobile={'20px 0px 20px 0px'}
                    target={link.link.target}
                    fontSize={'16px'}
                  />
                </LinkItemContainerInner>
              ))}
            </LinkItemContainerOuter>
          </RightContent>
        </RightLinksLeftSubtitleContainer>
      </VisibilitySensor>
    );
  }
}

export default RightLinksLeftSubtitle;

import React from 'react';
import { graphql } from 'gatsby';

import ImageGallery from '../../blocks/imageGallery';

function ImageGalleryPrismic({
  name_of_the_gallery,
  backgraund_color,
  title_color,
  items,
}) {
  return (
    <ImageGallery
      name_of_the_gallery={name_of_the_gallery.text}
      backgraund_color={backgraund_color}
      title_color={title_color}
      items={items}
    />
  );
}

export const query = graphql`
  fragment ImageGallery on PrismicPageBodyImageGallery {
    id
    slice_type
    primary {
      name_of_the_gallery {
        text
      }
      backgraund_color
      title_color
    }
    items {
      gallery_image {
        alt
        url
      }
    }
  }
`;

export default ImageGallery;

import React from 'react';
import { graphql } from 'gatsby';

import RightText from '../../blocks/rightText';

function RightTextPrismic({
  label_text,
  label_color,
  title_text,
  text,
  image,
}) {
  return (
    <RightText
      label_text={label_text.text}
      label_color={label_color}
      title_text={title_text}
      text={text.html}
      image={image}
    />
  );
}

export const query = graphql`
  fragment RightText on PrismicPageBodyRightText {
    id
    slice_type
    primary {
      label_text {
        text
      }
      label_color
      title_text {
        text
      }
      text {
        html
      }
      image {
        alt
        url
localFile {
          id
          childImageSharp {
            fluid(maxWidth: 700) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default RightText;

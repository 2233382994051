import React, { Component } from 'react';
import styled from '@emotion/styled';
import VisibilitySensor from 'react-visibility-sensor';
import range from 'lodash/range';
import Label from '../../components/Label';
import TitleUnderlined from '../../components/TitleUnderlined';
import {
  StarDivWithBackground,
  SharedStarDiv,
  SharedStarText,
  SliderSlickDiv,
  LabelContainer,
  ScoreContainer,
  StarContainer,
} from '../../utils/global-styles';
import { LargeReviewSliderContainer, GlassDoorDiv } from './style';
import { CenterModelSlickSlider } from '../../components/CenterModeSlickSlider';
import Star from '../../images/assets/star.svg';
import Squiggle from '../../components/squiggle';

const item = {
  hidden: { x: -20, opacity: 0, scale: 7 },
  visible: {
    x: 0,
    opacity: 1,
    scale: 1,
  },
};
const container = {
  hidden: { opacity: 0, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.075,
    },
  },
};

const LeftSquiggle = styled.div`
  position: absolute;
  left: -10%;
  @media (max-width: 1100px) {
    display: none;
  }
`;

const RightSquiggle = styled.div`
  position: absolute;
  right: -10%;
  margin-top: 100px;
  transform: rotate(-10deg);
  @media (max-width: 1100px) {
    display: none;
  }
`;

export class LargeReviewSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };
  render() {
    const {
      star_count,
      block_color_theme,
      label_text,
      items,
      review_source_logo,
      title,
      subtitle,
    } = this.props;
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <LargeReviewSliderContainer>
          <SharedStarDiv>
            <LabelContainer>
              <Label color={block_color_theme} text={label_text.text} />
            </LabelContainer>
            <TitleUnderlined
              titleText={`<span>${title.text}</span>`}
              fontSize="58px"
              tabletFontSize="26px"
              tableLineHeight="28px"
              mobileFontSize="24px"
              mobileLineHeight="22px"
              lineHeight="60px"
              margin="0 auto"
              underlineColor={block_color_theme}
              h2Mode={true}
            />
            <SharedStarText>{subtitle.text}</SharedStarText>
            <StarDivWithBackground>
              <ScoreContainer
                variants={container}
                initial="hidden"
                animate={this.state.visible ? 'visible' : 'hidden'}
              >
                {range(0, parseInt(star_count)).map(index => (
                  <StarContainer key={index} variants={item}>
                    <Star
                      fill={block_color_theme}
                      style={{
                        marginLeft: '1.5px',
                        marginRight: '1.5px',
                      }}
                    />
                  </StarContainer>
                ))}
              </ScoreContainer>
            </StarDivWithBackground>
            <GlassDoorDiv src={review_source_logo.url} />
          </SharedStarDiv>
          <SliderSlickDiv>
            <CenterModelSlickSlider items={items} />
          </SliderSlickDiv>
        </LargeReviewSliderContainer>
      </VisibilitySensor>
    );
  }
}

export default LargeReviewSlider;

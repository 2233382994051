import React from 'react';
import { graphql } from 'gatsby';

import BlogQuote from '../../../blocks/blog/blogQuote';

function BlogQuotePrismic({ quote_text, ...rest }) {
  return (
    <div>
      <BlogQuote quote_text={quote_text} {...rest} />
    </div>
  );
}

export const query = graphql`
  fragment BlogQuote on PrismicBlogBodyQuote {
    id
    slice_type
    primary {
      quote_text {
        html
        raw
        text
      }
    }
  }
`;

export default BlogQuotePrismic;

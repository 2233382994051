import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';

import dashedLine from '../../images/assets/leftToRightDash.png';
import LazyImage from '../../components/lazy-image';

const LeftImageRightContentContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
`;

const ImageContainer = styled(motion.div)`
  width: 310px;
  height: 400px;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  @media (max-width: 800px) {
    width: 100%;
    justify-content: center;
    margin-top: 50px;
  }
`;

const TextContainer = styled(motion.div)`
  width: 65%;
  padding-right: 60px;
  display: flex;
  align-items: center;
  @media (max-width: 1325px) {
    padding-right: 120px;
  }

  @media (max-width: 950px) {
    padding-right: 60px;
    width: 80%;
  }
  @media (max-width: 800px) {
    width: 100%;
    padding-right: 0px;
  }
`;

const TextContainerInner = styled.div``;

const ImageItem = styled.img``;

const NumberContainer = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: ${props => props.color};
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  letter-spacing: 0.15em;
`;

const NumberLine = styled.div`
  width: 20px;
  height: 2px;
  margin-left: 10px;
  background: ${props => props.color};
`;

const Title = styled.h2`
  font-weight: 800;
  font-size: 28px;
  line-height: 48px;
  color: #ffffff;
  margin-bottom: 15px;
  font-style: normal;
  font-family: Montserrat;
`;

const Paragraph = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.01em;
  color: #ffffff;
  @media (max-width: 800px) {
    font-size: 18px;
    line-height: 36px;
  }
`;

const DashedLineContainer = styled(motion.div)`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    display: none;
  }
`;

const DashedLineContainerOuter = styled(motion.div)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 800px) {
    display: none;
  }
`;

const DashedLine = styled.img`
  width: 65%;
  height: 200px;
`;

const MobileSpacer = styled.div`
  @media (max-width: 800px) {
    height: 50px;
  }
`;

const textAnim = {
  hidden: { opacity: 0, x: -20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.15,
    },
  },
};

const imageAnim = {
  hidden: { opacity: 0, x: -20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.3,
    },
  },
};

const lineAnim = {
  hidden: { opacity: 0, x: 0 },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      delay: 0.5,
    },
  },
};

export class rightImageLeftContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.props.displayLine ? (
          <React.Fragment>
            <DashedLineContainerOuter
              variants={lineAnim}
              initial="hidden"
              animate={this.state.visible ? 'visible' : 'hidden'}
            >
              <DashedLineContainer>
                <DashedLine src={dashedLine} />
              </DashedLineContainer>
            </DashedLineContainerOuter>
            <MobileSpacer />
          </React.Fragment>
        ) : null}
        <VisibilitySensor
          active={this.state.visibilitySensorActive}
          onChange={this.handleVisibilityChange}
          partialVisibility
          minTopValue={250}
        >
          <LeftImageRightContentContainer>
            <TextContainer
              variants={textAnim}
              initial="hidden"
              animate={this.state.visible ? 'visible' : 'hidden'}
            >
              <TextContainerInner>
                <NumberContainer color={this.props.color_theme}>
                  {this.props.number > 9 ? (
                    <React.Fragment>{this.props.number}</React.Fragment>
                  ) : (
                    <React.Fragment>0{this.props.number}</React.Fragment>
                  )}
                  /
                  {this.props.length > 9 ? (
                    <React.Fragment>{this.props.length}</React.Fragment>
                  ) : (
                    <React.Fragment>0{this.props.length}</React.Fragment>
                  )}
                  <NumberLine color={this.props.color_theme} />
                </NumberContainer>
                <Title>{this.props.title}</Title>
                <Paragraph>{this.props.paragraph}</Paragraph>
              </TextContainerInner>
            </TextContainer>
            <ImageContainer
              variants={imageAnim}
              initial="hidden"
              animate={this.state.visible ? 'visible' : 'hidden'}
            >
              <LazyImage src={this.props.image} />
            </ImageContainer>
          </LeftImageRightContentContainer>
        </VisibilitySensor>
      </React.Fragment>
    );
  }
}

export default rightImageLeftContent;

import React from 'react';
import { graphql } from 'gatsby';

import ServicesBlock from '../../../blocks/blog/blogServicesBlock';

function ServicesBlockPrismic({
  label_text,
  title,
  subtitle,
  paragraph_one_title,
  paragraph_one,
  paragraph_one_cta_text,
  paragraph_one_cta_link,
  paragraph_two_title,
  paragraph_two,
  paragraph_two_cta_text,
  paragraph_two_cta_link,
  ...rest
}) {
  return (
    <ServicesBlock
      label_text={label_text.text}
      title={title.text}
      subtitle={subtitle.text}
      paragraph_one_title={paragraph_one_title.text}
      paragraph_one={paragraph_one.text}
      paragraph_one_cta_text={paragraph_one_cta_text.text}
      paragraph_one_cta_link={paragraph_one_cta_link.url}
      paragraph_one_cta_link_target={paragraph_one_cta_link.target}
      paragraph_two_title={paragraph_two_title.text}
      paragraph_two={paragraph_two.text}
      paragraph_two_cta_text={paragraph_two_cta_text.text}
      paragraph_two_cta_link={paragraph_two_cta_link.url}
      paragraph_two_cta_link_target={paragraph_two_cta_link.target}
      {...rest}
    />
  );
}

export const query = graphql`
  fragment ServicesBlock on PrismicBlogBodyServicesBlock {
    id
    slice_type
    primary {
      label_text {
        text
      }
      title {
        text
      }
      subtitle {
        text
      }
      paragraph_one_title {
        text
      }
      paragraph_one {
        text
      }
      paragraph_one_cta_text {
        text
      }
      paragraph_one_cta_link {
        url
        target
      }
      paragraph_two_title {
        text
      }
      paragraph_two {
        text
      }
      paragraph_two_cta_text {
        text
      }
      paragraph_two_cta_link {
        url
        target
      }
    }
  }
`;

export default ServicesBlockPrismic;

import React, { Component } from 'react';
import styled from '@emotion/styled';
import LazyImage from '../../components/lazy-image';
import ImageCustomSize from './imageCustomSize';

const BlogSingleImageContainerOuter = styled.div`
  max-width: 100%;
  display: flex;
  justify-content: flex-start;
  max-width: 1080px;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
`;

const BlogSingleImageContainer = styled.div`
  width: 70%;
  max-width: 1080px;
  margin-top: 50px;
  margin-bottom: 50px;

  @media (max-width: 1150px) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  @media (max-width: 500px) {
    height: auto;
  }
`;

const ImageItem = styled.img`
  // object-fit: scale-down;
  // max-height: 60vh;
  height: 100%;
  width: 100%;
  @media (max-width: 500px) {
    height: auto;
    max-height: 80vh;
    max-width: 70vw;
  }
`;

export class BlogSingleImage extends Component {
  render() {
    return (
      <BlogSingleImageContainerOuter>
        <BlogSingleImageContainer>
          <ImageContainer
            height={this.props.single_image.dimensions.height}
            width={this.props.single_image.dimensions.width}
          >
            <ImageItem src={this.props.single_image.url} />
          </ImageContainer>
        </BlogSingleImageContainer>
      </BlogSingleImageContainerOuter>
    );
  }
}

export default BlogSingleImage;

import React, { Component } from 'react';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import VisibilitySensor from 'react-visibility-sensor';
import Helmet from 'react-helmet';
import range from 'lodash/range';
import {
  WhyFeedbackDiv,
  FeedBackTopDiv,
  FeedBackStarDiv,
  FeedBackQuoteDiv,
  GreenstarWrap,
  FeedBackName,
} from './style';
import GoogleLogo2 from '../../images/assets/googlelogo2.svg';
import WhnBGLeft from './whnBGLeft';
import Star from '../../images/assets/star.svg';

const LogoContainer = styled.div``;

const Logo = styled.img`
  width: 75px;
  height: 25px;
`;

const ReviewTextOuter = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ReviewText = styled.div`
  max-width: 1440px;
  width: 100%;
`;

const StarContainer = styled(motion.div)``;

const ScoreContainer = styled(motion.div)`
  display: flex;
`;

const container = {
  hidden: { opacity: 0, scale: 1 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delay: 0,
      when: 'beforeChildren',
      staggerChildren: 0.075,
    },
  },
};

const item = {
  hidden: { x: -20, opacity: 0, scale: 7 },
  visible: {
    x: 0,
    opacity: 1,
    scale: 1,
  },
};

export class ReviewTwo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      visible: false,
      visibilitySensorActive: true,
    };
  }

  handleVisibilityChange = visible => {
    if (this.state.visible !== visible) {
      this.setState({ visible, visibilitySensorActive: !visible });
    }
  };
  render() {
    return (
      <VisibilitySensor
        active={this.state.visibilitySensorActive}
        onChange={this.handleVisibilityChange}
        partialVisibility
        minTopValue={250}
      >
        <WhyFeedbackDiv>
          <Helmet>
            <script type="application/ld+json">{`
                {
                  "@context": "https://schema.org/",
                  "@type": "Organization",
                  "name": "Reload",
                  "review": {
                    "@type": "Review",
                    "reviewRating": {
                      "@type": "Rating",
                      "ratingValue": "${this.props.star_count}"
                    },
                    "author": {
                      "@type": "Person",
                      "name": "${this.props.review_author.text}"
                    },
                    "reviewBody": "${this.props.review_text.text}"
                  }
                }
              `}</script>
          </Helmet>
          <WhnBGLeft color={this.props.block_color_theme} />
          <FeedBackTopDiv>
            <FeedBackStarDiv>
              <LogoContainer>
                <Logo src={this.props.review_provider_logo.url} />
              </LogoContainer>
              <GreenstarWrap>
                <ScoreContainer
                  variants={container}
                  initial="hidden"
                  animate={this.state.visible ? 'visible' : 'hidden'}
                >
                  {range(0, parseInt(this.props.star_count)).map(index => (
                    <StarContainer key={index} variants={item}>
                      <Star
                        fill={this.props.block_color_theme}
                        style={{
                          marginLeft: '1.5px',
                          marginRight: '1.5px',
                        }}
                      />
                    </StarContainer>
                  ))}
                </ScoreContainer>
              </GreenstarWrap>
            </FeedBackStarDiv>
            <FeedBackQuoteDiv>
              <ReviewTextOuter>
                <ReviewText>{this.props.review_text.text}</ReviewText>
              </ReviewTextOuter>
              <FeedBackName>{this.props.review_author.text}</FeedBackName>
            </FeedBackQuoteDiv>
          </FeedBackTopDiv>
        </WhyFeedbackDiv>
      </VisibilitySensor>
    );
  }
}

export default ReviewTwo;

import React from 'react';
import { graphql } from 'gatsby';

import CaseStudyHeaderOne from '../../blocks/caseStudyHeaderOne';
import urlParser from '../../utils/urlParser';
import linkStripper from '../../utils/link-stripper';

function CaseStudyHeaderOnePrismic({
  block_background_color,
  case_study_tags,
  label_text,
  label_color,
  title_text,
  title_text_color,
  subtitle_text,
  subtitle_text_color,
  cta_text,
  cta_link,
  cta_text_color,
  cta_arrow_outline_color,
  cta_arrow_color,
  image,
}) {
  return (
    <CaseStudyHeaderOne
      block_background_color={block_background_color}
      case_study_tags={case_study_tags.text}
      label_color={label_color}
      label_text={label_text.text}
      label_color={label_color}
      title_text={title_text.text}
      title_text_color={title_text_color}
      subtitle_text={subtitle_text.text}
      subtitle_text_color={subtitle_text_color}
      cta_text={cta_text.text}
      cta_link={urlParser(cta_link.url)}
      cta_link_target={cta_link.target}
      cta_text_color={cta_text_color}
      cta_arrow_outline_color={cta_arrow_outline_color}
      cta_arrow_color={cta_arrow_color}
      image={image.url}
    />
  );
}

export const query = graphql`
  fragment CaseStudyHeaderOne on PrismicPageBodyCaseStudyHeader1 {
    id
    slice_type
    primary {
      block_background_color
      case_study_tags {
        text
      }
      label_text {
        text
      }
      label_color
      title_text {
        text
      }
      title_text_color
      subtitle_text {
        text
      }
      subtitle_text_color
      cta_text {
        text
      }
      cta_link {
        url
        slug
        target
      }
      cta_text_color
      cta_arrow_outline_color
      cta_arrow_color
      image {
        alt
        url
        localFile {
          id
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
              presentationWidth
            }
          }
        }
      }
    }
  }
`;

export default CaseStudyHeaderOne;

import React from 'react';
import { graphql } from 'gatsby';

import BlogHtml from '../../../blocks/blog/blogHtml.jsx';

function BlogHtmlPrismic({ custom_html }) {
  return (
    <div>
      <BlogHtml custom_html={custom_html.text} />
    </div>
  );
}

export const query = graphql`
  fragment BlogHtml on PrismicBlogBodyCustomHtml {
    id
    slice_type
    primary {
      custom_html {
        text
      }
    }
  }
`;

export default BlogHtmlPrismic;
